<template>
  <router-view name="sidebar"></router-view>
</template>

<script>
// import InfoSidebarLoadSearch from "./info-sidebar/info-sidebar-load-search";

export default {
  name: "sidebar-menu",
};
</script>

<!--<style src="@/scss/main/sidebar/index.scss" lang="scss"></style>-->
