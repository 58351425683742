import axios from "axios";
import toastMessage from "../toastMessage";
import utils from "../../utils";
import store from "../index";
import router from "../../routes";
import qs from 'qs'
import {Form} from 'vform';

const actions = {
    getListTarrifUsers({commit, getters}) {
        return axios.get(process.env.VUE_APP_API_URL + 'tariff/users/panel?' + qs.stringify(getters.getFilterParams))
            .then((response) => {
                response.data.data.current_page = getters.getPage

                commit('updateTariffUsersPanelAll', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },

    createUserTariffs({commit}, vForm = new Form()) {
        let promise = vForm.post(process.env.VUE_APP_API_URL + 'tariff/users/panel');

        promise.then((response) => {
            commit('prependUserTariffs', response.data.data);
            store.dispatch('users/getUser');
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    putUserTariff({commit}, vForm = new Form()) {
        let promise = vForm.put(process.env.VUE_APP_API_URL + 'tariff/users/panel/' + vForm.id);
        promise.then((response) => {
            if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
            commit('findReplaceUserTariffs', response.data.data);

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    deleteUserTariff({commit}, id) {
        let promise = new Promise(function (resolve, reject) {
            return axios.delete(process.env.VUE_APP_API_URL + 'tariff/users/panel/' + id).then((response) => {
                if (response.data.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            });
        });
        promise.then((response) => {
            commit('findAndDeleteUserTariffs', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },

    searchOrders({commit}, textSearch) {
         axios.get(process.env.VUE_APP_API_URL + 'tariff/search/orders', {params: {queryString: textSearch}})
            .then(res => {
                commit('updateOrdersOptions', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    searchTariffs({commit}, textSearch) {
        axios.get(process.env.VUE_APP_API_URL + 'tariff/search/tariffs', {params: {queryString: textSearch}})
            .then(res => {
                commit('updateTariffOptions', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    setStatesFromQuery({commit}, params) {
        let  filters = params.filters;
        let  checkbox = params.checkbox;
        let  search = params.search;
        let  listNameId = params.list_name_id;
        let  typeDisplayContacts = params.type_display_contacts;
        let  page = params.page;
        let  total = params.total;

        if(page){
            commit('updatePage', page)
        }
        if(total){
            commit('updateTotal', total)
        }

        if(checkbox){
            store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
        }else{
            store.dispatch('filters/setClearCheckboxesValues', checkbox);
        }

        if(filters){
            store.dispatch('filters/setUpdateFiltersValues', filters);
        }else{
            store.dispatch('filters/setClearFiltersValues');
        }

        if(search){
            store.dispatch('filters/setUpdateSearchesValues', search);
        }else{
            store.dispatch('filters/setClearSearchesValues', search);
        }

        if(listNameId){
            store.dispatch('listName/setListNameIdSelected', listNameId);
        }else{
            store.dispatch('listName/clearListNameIdSelected', listNameId)
        }

        if(typeDisplayContacts){
            commit('updateTypeDisplayContacts', typeDisplayContacts);
        }

    },

    setCurrentPage({commit, getters}, page){
        if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1){
            commit('updatePage', page);
            //  commit('updateTotal', getters.get);
            commit('historyPush', getters.getFilterParams);
        }
    },
    setHistoryForListName({commit, getters} ){
        commit('historyPush', getters.getFilterParams);
    },
    setEventFilter({commit, getters}){
        commit('updatePage', 1);
        //  commit('updateTotal', getters.getTotal);
        commit('historyPush', getters.getFilterParams);
    },

    // --------------------------------------------------------------
};

const state = {
    ordersOptions: {
        data: []
    },
    tariffOptions: {
        data: []
    },
    userTariff: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
    }
};

const mutations = {
    historyPush(state, params) {
        clearTimeout(store.state.timers.historyPush);
        store.state.timers.historyPush = setTimeout(() => {
            router.push({query: qs.parse(params)}, () => {});
        }, 10); //ms
    },
    updateTotal(state, data) {
        state.userTariff.total = data
    },
    updatePage(state, data) {
        state.userTariff.current_page = data;
    },
    updateOrdersOptions(state, data) {
        state.ordersOptions = data;
    },
    updateTariffOptions(state, data) {
        state.tariffOptions = data;
    },
    updateTariffUsersPanelAll(state, data) {
        state.userTariff = data;
    },
    prependUserTariffs(state, data) {
        state.userTariff.data.unshift(data);
    },
    findReplaceUserTariffs(state, data) {
        const item = state.userTariff.data.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    findAndDeleteUserTariffs(state, data) {
        const index = state.userTariff.data.findIndex(item => item.id === data.id);
        state.userTariff.data.splice(index, 1);
        state.list.idsOptions = utils.pluck(state.userTariff.data, 'id')
    },
};

const getters = {
    getTariffUsersPanel(state) {
        return state.userTariff;
    },
    getOrderOptions(state) {
        return state.ordersOptions;
    },
    getTariffOptions(state) {
        return state.tariffOptions;
    },
    getPage() {
        return state.userTariff.current_page;
    },
    getTotal(state) {
        return state.userTariff.total;
    },
    getFilterParams(state, getters){
        return {
            total: getters.getTotal,
            page: getters.getPage,
            filters: store.getters['filters/filtersValues'],
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
