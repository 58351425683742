/**
 *  compare arrays
 * @param a
 * @param b
 * @return {boolean|*}
 */
const notEquals = (a, b) =>
    !(a === b && a.length === b.length &&
    a.every((v, i) => v === b[i]));
export default notEquals
