<template>
  <div>
    <router-view name="pagination"></router-view>
    <router-view name="general_pagination"></router-view>
  </div>
</template>

<script>
export default {
  name: "footer-template",
}
</script>

<style scoped>
</style>
