import { render, staticRenderFns } from "./DefaultLayout.vue?vue&type=template&id=428827ac&"
var script = {}
import style0 from "@/assets/css/global.scss?vue&type=style&index=0&prod&lang=scss&"
import style1 from "@/scss/main/index.scss?vue&type=style&index=1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports