import axios from 'axios'
import store from "../index";
import {Form} from 'vform';
import router from "@/routes";
import qs from "qs";
import toastMessage from "@/store/toastMessage";
// import toastMessage from "@/store/toastMessage";

// actions
const actions = {
    async loadUser () {
      return await axios.get(process.env.VUE_APP_API_URL + 'user')
      .then(function (response) {
          store.dispatch('setOnceUser', response.data);
      })
      .catch(function () {
          router.onReady(()=>{
              if(router && router.currentRoute &&  (
                  router.currentRoute.name === 'EmailVerify'
                  || router.currentRoute.name === 'EmailVerifyBackendUrl')){
                  return true;
              }
              localStorage.removeItem('authToken')
          }, ()=>{
              localStorage.removeItem('authToken')
          })
      })
  },
  loginUser (_, vForm = new Form()) {
      let promise = vForm.post(process.env.VUE_APP_API_URL + 'login');

      promise.then((response) => {
          store.dispatch('setOnceUser', response.data.user);
          localStorage.setItem('authToken', response.data.token)

          // reinit
          const authToken = response.data.token;
          const instance = axios.create({
              headers: {'Authorization': `Bearer ${authToken}`}
              // baseURL: 'http://dev.salesar.io/'
          });

          Form.axios = instance;

      }).catch((response) => {
          console.log(response,'catch')
          // toastMessage.fire(response); //todo:check
      });
      return promise;
  },
  registerUser (_, vForm = new Form()) {
      return vForm.post(process.env.VUE_APP_API_URL + 'register')
      .then(function (response) {
          store.dispatch('setOnceUser', response.data.data.user);
          localStorage.setItem('authToken', response.data.data.token);
      })
  },
  verifyEmail () {
      if(!router.currentRoute.params || !router.currentRoute.params.id || !router.currentRoute.params.hash){
          // no params
          toastMessage.fire('Something wrong no one params code:5001.1');
          return false;
      }
      let promise = axios.get(process.env.VUE_APP_API_URL + 'email/verify/' + router.currentRoute.params.id + '/' + router.currentRoute.params.hash + '?' + qs.stringify(router.currentRoute.query))
      promise.then(() => {
         // success if 204
      }).catch((response) => {
          toastMessage.fire(response);
      });

      return promise;
  },
  verifyEmailBackendUrl () {
      if(!router.currentRoute.query.verify_url){
          // no params
          toastMessage.fire('Something wrong no one params code:5002.2');
          return false;
      }
      let promise = axios.get(router.currentRoute.query.verify_url)
      promise.then(() => {
         // success if 204
      }).catch((response) => {
          toastMessage.fire(response);
      });

      return promise;
  },
  verifyEmailResend () {
      return axios.post(process.env.VUE_APP_API_URL + 'email/resend')
      .then(function (response) {
          console.log(response)
          //todo: clean
          // store.dispatch('setOnceUser', response.data.data.user);
          // localStorage.setItem('authToken', response.data.data.token);
      })
  },
  passwordReset ({commit}, vForm = new Form()) {
      return vForm.post(process.env.VUE_APP_API_URL + 'password/email')
      .then(function () {
          commit('updateResetEmail',vForm.email)
      })
  },
    changePassword(_, vForm = new Form()) {
        let promise = vForm.post(process.env.VUE_APP_API_URL + 'password/reset');
            promise.then(function (response) {
                console.log(response)
            }).catch((response) => {
                console.log(response, 'catch')
            })
        return promise
    },
  logout () {
      let promise = axios.post(process.env.VUE_APP_API_URL + 'logout');
      promise.then(function () {
          store.dispatch('setUser', {
              can:{},
              email: null,
              email_verified_at: null,
              role_name: null,
              timezone: null,
              tariff_row: 0,
              phone_number: null,
              name: null,
              created_at: null,
              job_title: null,
              seniority: null,
              company_name: null,
              company_website: null
          });
          localStorage.removeItem('authToken')
      }).catch(() => {
          localStorage.removeItem('authToken')

      })

      return promise;
  }
}

// mutations
const mutations = {
    updateResetEmail(state, data) {
        state.reset_email = data
    }
}

const state = {
    // user: null
    reset_email: null,
}

// getters
const getters = {
    getUser: (state) => {
        return state.user
    },
    getResetEmail: (state) => {
        return state.reset_email
    }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
