import axios from "axios";
import toastMessage from "../toastMessage";
import utils from "../../utils";
import store from "../index";
import router from "../../routes";
import {Form} from 'vform';
import qs from 'qs';


const actions = {
    getTechnologiesList({commit, getters}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + "admin/technologies/list?" + qs.stringify(getters.getFilterParams))
        promise.then((response) => {
            response.data.data.current_page = getters.getPage // skip if already next page // fixed infinity reload event by changed  current_page

            commit('updateTechnologiesLists', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    putTechnologies({commit}, vForm = new Form()) {
        let promise = vForm.put(process.env.VUE_APP_API_URL + 'admin/technologies/list/' + vForm.id);
        promise.then((response) => {
            commit('findReplaceTechnologies', response.data.data);

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    deleteTechnologies({commit}, id) {
        let promise = new Promise(function (resolve, reject) {
            return axios.delete(process.env.VUE_APP_API_URL + 'admin/technologies/list/' + id).then((response) => {
                if (response.data.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            });
        });
        promise.then((response) => {
            commit('findAndDeleteTechnologies', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    createTechnologies({commit}, vForm = new Form()) {
        let promise = vForm.post(process.env.VUE_APP_API_URL + 'admin/technologies/list');

        promise.then((response) => {
            commit('prependTechnologies', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    setCurrentPage({commit, getters}, page){
        if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1){
            commit('updatePage', page);
            //  commit('updateTotal', getters.get);
            commit('historyPush', getters.getFilterParams);
        }
    },
    setTotal({commit, getters}, total){
        commit('updateTotal', total);

        if((router.currentRoute.query.total !== undefined && Number(router.currentRoute.query.total) !== Number(total))
            || (router.currentRoute.query.total === undefined && Number(total) > 0)
        ){
            commit('historyPush', getters.getFilterParams);
        }
    },
    setEventFilter({commit, getters}){
        commit('updatePage', 1);
        //  commit('updateTotal', getters.getTotal);
        commit('historyPush', getters.getFilterParams);
    },
    setStatesFromQuery({commit}, params) {
        let  filters = params.filters;
        let  checkbox = params.checkbox;
        let  checkbox3 = params.checkbox3;
        let  search = params.search;
        let  listNameId = params.list_name_id;
        let  typeDisplayContacts = params.type_display_contacts;
        let  page = params.page;
        let  total = params.total;

        if(page){
            commit('updatePage', page)
        }
        if(total){
            commit('updateTotal', total)
        }

        if(checkbox){
            store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
        }else{
            store.dispatch('filters/setClearCheckboxesValues', checkbox);
        }

        if(checkbox3){
            store.dispatch('filters/setUpdateCheckboxes3Values', checkbox3);
        }else{
            store.dispatch('filters/setClearCheckboxes3Values', checkbox3);
        }

        if(filters){
            store.dispatch('filters/setUpdateFiltersValues', filters);
        }else{
            store.dispatch('filters/setClearFiltersValues');
        }

        if(search){
            store.dispatch('filters/setUpdateSearchesValues', search);
        }else{
            store.dispatch('filters/setClearSearchesValues', search);
        }

        if(listNameId){
            store.dispatch('listName/setListNameIdSelected', listNameId);
        }else{
            store.dispatch('listName/clearListNameIdSelected', listNameId)
        }

        if(typeDisplayContacts){
            commit('updateTypeDisplayContacts', typeDisplayContacts);
        }
    },
};

const mutations = {
    updatePage(state, data) {
        state.technologies.current_page = data;
    },
    updateTotal(state, data) {
        state.technologies.total = data
    },
    historyPush(state, params) {
        clearTimeout(store.state.timers.historyPush);
        store.state.timers.historyPush = setTimeout(() => {
            router.push({query: qs.parse(params)}, () => {});
        }, 10); //ms
    },
    updateTechnologiesLists(state, data) {
        state.technologies = data;
        state.list.idsOptions = utils.pluck(state.technologies.data, 'id')
    },
    findReplaceTechnologies(state, data) {
        const item = state.technologies.data.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    prependTechnologies(state, data) {
        state.technologies.data.unshift(data);
    },
    findAndDeleteTechnologies(state, data) {
        const index = state.technologies.data.findIndex(item => item.id === data.id);
        state.technologies.data.splice(index, 1);
        state.list.idsOptions = utils.pluck(state.technologies.data, 'id')
    },
};

const state = {
    technologies: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
    },
    type_display_contacts: 'all',

    list:{
        idTempList: null,
        countTempList: 0,
        idsOptions: [],
        checkedContactsIds: [],
        isIndeterminate: false,
        checkAllOnPage: false,

        check_query: {
            all: false,
            max: false,
            per_company: false
        },
        maxRow: 25,// default
        perCompanyRow: 3,// default
        query: '',
    }
};

const getters = {
    getTechnologiesList(state) {
        return state.technologies;
    },
    getFilterParams(state, getters){
        return {
            total: getters.getTotal,
            page: getters.getPage,
            filters: store.getters['filters/filtersValues'],
            checkbox: store.getters['filters/checkboxValues'],
            checkbox3: store.getters['filters/checkboxValues3'],
        }
    },
    getPage() {
        return state.technologies.current_page;
    },
    getTotal() {
        return state.technologies.total;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
