import axios from "axios";
import toastMessage from "../toastMessage";
import router from "../../routes";
import utils from "../../utils";
import store from "../index";
import "../../utils/prototype/index";
import {Form} from 'vform';
import qs from 'qs'

    const actions =  {
        getPeople({commit, getters}, ) {
            let promise = axios.get(process.env.VUE_APP_API_URL + "contacts?" + qs.stringify(getters.getFilterParams));

            promise.then((response) => {
                response.data.data.current_page = getters.getPage // skip if already next page // fixed infinity reload event by changed current_page

                commit('updatePeople', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        peopleMask({commit, getters}) {qs.stringify(getters.getFilterParams)
            let promise = axios.get(process.env.VUE_APP_API_URL + "contacts/mask-locked-contacts?" + qs.stringify({contacts_ids: getters.getIdsOptions}));

            promise.then((response) => {
                response.data.data.current_page = getters.getPage // skip if already next page // fixed infinity reload event by changed current_page
                commit('updatePeopleMask', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        peopleUnlocked({commit, getters}, contacts_ids) {qs.stringify(getters.getFilterParams)
            let promise = axios.get(process.env.VUE_APP_API_URL + "contacts/mask-locked-contacts?" + qs.stringify({contacts_ids: contacts_ids}));

            promise.then((response) => {
                response.data.data.current_page = getters.getPage // skip if already next page // fixed infinity reload event by changed current_page
                commit('updatePeopleMaskUnlocked', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        createPeople({commit}, vForm = new Form()) {
            let promise = vForm.post(process.env.VUE_APP_API_URL + 'contact');

            promise.then((response) => {
                commit('prependPeople', response.data.data);
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        putPeople({commit}, vForm = new Form()) {
            let promise = vForm.put(process.env.VUE_APP_API_URL + 'contact/' + vForm.id);
            promise.then((response) => {
                if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
                if(vForm.option_edit_from_company_page){
                    store.dispatch('company/setFindReplacePeople', response.data.data);
                }else{
                    commit('findReplacePeople', response.data.data);
                }

                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        replaceContacts({commit}, vForm = new Form()) {
            vForm.ids_replace = store.getters['people/getCheckedContactsIds'];
            let promise = vForm.put(process.env.VUE_APP_API_URL + 'contact/search-company-geo/replace');
            promise.then((response) => {
                if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
                if(vForm.option_edit_from_company_page){
                    store.dispatch('company/setFindReplacePeople', response.data.data);
                    commit('findReplacePeople', response.data.data);
                }else{
                    commit('findReplacePeople', response.data.data);
                }

                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        deleteInvalidContacts({commit}, vForm = new Form()) {
            vForm.ids_replace = store.getters['people/getCheckedContactsIds'];
            let promise = vForm.put(process.env.VUE_APP_API_URL + 'contact/search-company-geo/delete/geo-id');
            promise.then((response) => {
                if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
                if(vForm.option_edit_from_company_page){
                    store.dispatch('company/setFindReplacePeople', response.data.data);
                    commit('findReplacePeople', response.data.data);
                }else{
                    commit('findReplacePeople', response.data.data);
                }

                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        setContacts({commit}, data) {
            commit('updatePeople', data);
        },
        getCompanyTotal({commit}, data) {
            commit('updateTotal', data);
        },
        deletePeople({commit}, id) {
            let promise = new Promise(function (resolve, reject) {
                return axios.delete(process.env.VUE_APP_API_URL + 'contact/' + id).then((response) => {
                    if (response.data.success === true) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }).catch((error) => {
                    reject(error);
                });
            });
            promise.then((response) => {
                commit('findAndDeletePeople', response.data.data);
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        setStatesFromQuery({commit}, params) {
            let  filters = params.filters;
            let  checkbox = params.checkbox;
            let  search = params.search;
            let  listNameId = params.list_name_id;
            let  typeDisplayContacts = params.type_display_contacts;
            let  page = params.page;
            let  total = params.total;
            let  checkbox3 = params.checkbox3;

            if(page){
                commit('updatePage', page)
            }
            if(total){
                commit('updateTotal', total)
            }

            if(checkbox){
                store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
            }else{
                store.dispatch('filters/setClearCheckboxesValues', checkbox);
            }

            if(checkbox3){
                store.dispatch('filters/setUpdateCheckboxes3Values', checkbox3);
            }else{
                store.dispatch('filters/setClearCheckboxes3Values', checkbox3);
            }


            if(filters){
                store.dispatch('filters/setUpdateFiltersValues', filters);
            }else{
                store.dispatch('filters/setClearFiltersValues');
            }

            if(search){
                store.dispatch('filters/setUpdateSearchesValues', search);
            }else{
                store.dispatch('filters/setClearSearchesValues', search);
            }

            if(listNameId){
                store.dispatch('listName/setListNameIdSelected', listNameId);
            }else{
                store.dispatch('listName/clearListNameIdSelected', listNameId)
            }

            if(typeDisplayContacts){
                commit('updateTypeDisplayContacts', typeDisplayContacts);
            }

        },
        setTotal({commit, getters}, total){
            commit('updateTotal', total);

            if((router.currentRoute.query.total !== undefined && Number(router.currentRoute.query.total) !== Number(total))
            || (router.currentRoute.query.total === undefined && Number(total) > 0)
            ){
                commit('historyPush', getters.getFilterParams);
            }
        },
        setCurrentPage({commit, getters}, page){
            if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1){
                commit('updatePage', page);
              //  commit('updateTotal', getters.get);
                commit('historyPush', getters.getFilterParams);
            }
        },
    // - list
        setHistoryForListName({commit, getters} ){
            commit('historyPush', getters.getFilterParams);
        },
        setEventFilter({commit, getters}){
            commit('updatePage', 1);
          //  commit('updateTotal', getters.getTotal);
            commit('historyPush', getters.getFilterParams);
        },


        // - list
        setCheckedContactsIdsClear({commit}){
            commit('updateCheckedContactsIds', []);
        },
        setCheckedContactsIdsOnce({commit}, data){
            commit('updateCheckedContactsIds', utils.mergeAndUniq(data.bulk_ids, data.manual_ids));
        },
        setCheckedContactsIds({commit,getters}, data){
            let newChecked = [];
            let newUnChecked = [];
            let isUncheckOnCurrentPage = false;
            if(data.length === 0){
                isUncheckOnCurrentPage = true;
            }

            if((getters.getCheckedContactsIds.length  === getters.getIdsOptions.length && getters.getCheckAllOnPage && !getters.getIsIndeterminate)){
                //if all checked
                newUnChecked = getters.getIdsOptions.diff(data);
                newChecked = data.diff(getters.getCheckedContactsIds);
            }else if(isUncheckOnCurrentPage){
                data =  getters.getIdsOptions.diff(data)
                newUnChecked = getters.getIdsOptions;
            }else{
                //concat / merge if allCheck (with current)
                if(getters.getCheckAllOnPage){
                    data = utils.mergeAndUniq(data, getters.getCheckedContactsIds)
                }
                newChecked = data.diff(getters.getCheckedContactsIds);
                newUnChecked = getters.getCheckedContactsIds.diff(data)
            }

            if(newChecked.length){
                commit('updateCheckedContactsIds',  utils.mergeAndUniq(getters.getCheckedContactsIds,newChecked));
            }
            if(newUnChecked.length){
                commit('updateCheckedContactsIds', getters.getCheckedContactsIds.diff(newUnChecked));
            }

            if(newChecked.length || newUnChecked.length){
                store.dispatch('listContacts/checkByOne', {checked:newChecked, unchecked: newUnChecked }); //send to backend // diff only new
            }

        },
        setUpdateCountTempList({commit}, data){
            commit('updateCountTempList', data);
        },
        setUpdateIdTempList({commit}, data){
            commit('updateIdTempList', data);
        },
        unshiftCheckedContactsIds({commit,getters}){
            commit('findAndHideSavedPeople', getters.getCheckedContactsIds);
            commit('updateCheckedContactsIds', []);
            commit('updateIsIndeterminate', false);
            commit('updateCheckAllOnPage', false);
        },
        setCheckAllOnPage({commit}, data){
            commit('updateCheckAllOnPage', data);
        },
        setCheckByQuery({commit} , params = {key:'', data: {}}){
            commit('updateCheckByQuery', {key: params.key, data: params.data});
        },
        setIsIndeterminate({commit}, data){
            commit('updateIsIndeterminate', data);
        },
        setListQuery({commit}, data){
            commit('updateListQuery', data);
        },
        setListMaxRow({commit}, data){
            commit('updateListMaxRow', data);
        },
        setListPerCompanyRow({commit}, data){
            commit('updateListPerCompanyRow', data);
        },

        setTypeDisplayContacts({commit,getters}, data){
            commit('updateTypeDisplayContacts', data);

            clearTimeout(store.state.timers.typeDisplay);
            store.state.timers.typeDisplay = setTimeout(() => {
                if((router.currentRoute.query.type_display_contacts !== undefined && router.currentRoute.query.type_display_contacts !== data)
                    || (router.currentRoute.query.type_display_contacts === undefined  &&  data !== undefined)){
                    commit('updatePage', 1);
                   // commit('updateTotal', getters.getTotal);
                    commit('historyPush', getters.getFilterParams);
                }
            }, 800); //ms
        },
        setContactsPage({commit, getters}, page) {
            if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
                || router.currentRoute.query.page === undefined && Number(page) > 1) {
                commit('updatePage', page);
                commit('historyPush', getters.getFilterParams);
            }
        },
    };

    const mutations = {

        // - People
        updatePeople(state, data) {
            state.people = data;
            state.list.idsOptions = utils.pluck(state.people.data, 'id')
        },
        updatePeopleMask(state, data) {
            state.people_mask.data = data;
        },
        updatePeopleMaskUnlocked(state, data) {
            for (const [,itemNew] of Object.entries(data)) {
                const item = state.people_mask.data.find(item => item.id === itemNew.id);
                Object.assign(item, itemNew);
            }
        },
        prependPeople(state, data) {
            state.people.data.unshift(data);
        },
        findReplacePeople(state, data) {
            const item = state.people.data.find(item => item.id === data.id);
            Object.assign(item, data);
        },
        findAndDeletePeople(state, data) {
            const index = state.people.data.findIndex(item => item.id === data.id);
            state.people.data.splice(index, 1);
            state.list.idsOptions = utils.pluck(state.people.data, 'id')
        },

        findAndHideSavedPeople(state, ids) {
            state.people.data = state.people.data.filter(item => {
                return ids.indexOf(item.id ) < 0
            });
        },

        // - router
        historyPush(state, params) {
            clearTimeout(store.state.timers.historyPush);
            store.state.timers.historyPush = setTimeout(() => {
                router.push({query: qs.parse(params)}, () => {});
            }, 10); //ms
        },

        // - page
        updateTotal(state, data) {
            state.total = data
        },
        updatePage(state, data) {
            state.people.current_page = data
        },

        // - list
        updateIdsOptions(state){
            state.list.idsOptions = utils.pluck(state.people.data, 'id')
        },
        updateCheckedContactsIds(state,data){
            state.list.checkedContactsIds = data
        },
        updateCheckAllOnPage(state,data){
            state.list.checkAllOnPage = data
        },
        updateCheckByQuery(state, params = {key:'', data: {}}) {
            //switch all clear max
            if(params.key === 'all'){
                state.list.check_query['max'] = [];
            }
            //switch all clear all
            if(params.key === 'max'){
                state.list.check_query['all'] = [];
            }

            let currentVal = state.list.check_query[params.key];
            state.list.check_query[params.key] = utils.equals(currentVal, params.data) ? [] : params.data
        },
        updateIsIndeterminate(state,data){
            state.list.isIndeterminate = data
        },
        updateListQuery(state,data){
            state.list.query = data
        },
        updateListMaxRow(state,data){
            state.list.maxRow = data
        },
        updateListPerCompanyRow(state,data){
            state.list.perCompanyRow = data
        },
        updateIdTempList(state,data){
            state.list.idTempList = data
        },
        updateCountTempList(state,data){
            state.list.countTempList = data
        },
        updateTypeDisplayContacts(state,data){
            // state.total= 0;
            // state.people.current_page = 1; // need !!!
            state.type_display_contacts = data
        },
        updateAciveColumn(state,data) {
            state.active_column = data
        }
    };

    const state = {
        total: 0,
        eventsMutations : [],
        people: {
            current_page: 1,
            data: [],
            from: 0,
            to: 0,
            total: 0,
            last_page: 0,
            per_page: 0,
        },
        type_display_contacts: 'all',
        people_mask: {
            data: []
        },
        // list
        list:{
            idTempList: null,
            countTempList: 0,
            idsOptions: [],
            checkedContactsIds: [],
            isIndeterminate: false,
            checkAllOnPage: false,

            check_query: {
                all: false,
                max: false,
                per_company: false
            },
            maxRow: 25,// default
            perCompanyRow: 3,// default
            query: '',
        },

        active_column: {
            full_name: true,
            company: true,
            title: true,
            industry: true,
            website: true,
            company_country: true,
            company_state_region: true,
            company_city: true,
            company_address: false,
            employees: true,
            corporate_email: true,
            personal_email: true,
            personal_phone_number: false,
            company_phone: true,
            technologies: true,
            annual_revenue: true,
            specialties: true,
            contacts_location_country: true,
            contacts_location_state: true,
            contacts_location_city: true,
            ecommerce_scheme: true,
            total_funding: true,
            last_funding_type: true,
            last_funding_round_name: true,
            last_funding_round_date: true,
            last_funding_round_value: true,
            created_at: false,
            updated_at: false,
            data_updated_at: false,
            id: false,
        }
    };

    const getters = {
        getTotal(state) {
            return state.total;
        },
        getPage(state) {
            return state.people.current_page;
        },
        allPeople(state) {
            return state.people;
        },
        getPeopleMask(state) {
            return state.people_mask;
        },
        getPeopleMaskByIdField: (state) => (id, field) => {
            const item =  state.people_mask.data.find(item => item.id === id)
            if(item && item[field]){
                return item[field];
            }
            return  null;
        },
        getPeopleMaskById: (state) => (id) => {
            return  state.people_mask.data.find(item => item.id === id)
        },
        getPeopleMaskByIdIsUnlocked: (state) => (id) => {
            const item = state.people_mask.data.find(item => item.id === id);
            if(item){
                return !!item.is_unlocked;
            }
            return false;
        },
        getFilterParams(state, getters){
            return {
                total: getters.getTotal,
                page: getters.getPage,
                filters: store.getters['filters/filtersValues'],
                checkbox: store.getters['filters/checkboxValues'],
                checkbox3: store.getters['filters/checkboxValues3'],
                search: store.getters['filters/searchValues'],
                list_name_id: store.getters['listName/listNameIdSelected'],
                checked_bulk: store.getters['formCheckFilterMax/getCheckedBulk'],
                order_checked_all: store.getters['formCheckFilterMax/getOrderCheckedAll'],
                type_display_contacts: getters.getTypeDisplayContacts,
            }
        },
        getFilterParamsString(state, getters){
            return  qs.stringify(getters.getFilterParams); // warning send with tab ".../t" if parsing on backend
        },
        getIdsOptions(state){
            return state.list.idsOptions;
        },
        getCheckedContactsIds(state){
            return state.list.checkedContactsIds;
        },
        getCheckAllOnPage(state){
            return state.list.checkAllOnPage;
        },
        getCheckAllByQuery(state){
            return state.list.check_query['all'];
        },
        getCheckMaxByQuery(state){
            return state.list.check_query['max'];
        },
        getCheckQuery(state){
            return state.list.check_query;
        },
        getIsIndeterminate(state){
            return state.list.isIndeterminate;
        },
        getListQuery(state){
            return state.list.query;
        },
        getListMaxRow(state){
            return state.list.maxRow;
        },
        getListPerCompanyRow(state){
            return state.list.perCompanyRow;
        },
        getTypeDisplayContacts(state){
            return state.type_display_contacts;
        },
        getActiveColumn(state) {
            return state.active_column;
        }
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
