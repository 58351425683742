<template>
  <div>
    <div class="top-main-list-page">
      <div class="top-main-list-page_prev">
        <router-link to="/list-saved-companies">
          <inline-svg :src="require('@/assets/images/list-prev.svg')"/>
          <p>Back to lists</p>
       </router-link>
      </div>
      <div class="top-main-list-page_tittle">
        <inline-svg :src="require('@/assets/images/header/list-icon.svg')"/>
        <p>{{ getTitle() }}</p>
      </div>
      <div class="top-main-list-page_button-group">
        <button>Move to list</button>
        <button>
          <inline-svg :src="require('@/assets/images/save.svg')"/>
          Export
        </button>
      </div>
    </div>
    <div class="center-main-list-page">
      <div class="border">
        <div class="table-responsive">
          <table class="list-saved-style">
            <thead>
            <tr>
              <th class="checkbox list">
                <!--                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllOnPage"-->
                <!--                                 @change="handleCheckAllPageChange" size="small">-->
                <!--                    </el-checkbox>-->
              </th>
              <th v-if="user.can['companies_saved.field_name']">Company</th>
              <th v-if="user.can['companies_saved.field_website']">Website</th>
              <th v-if="user.can['companies_saved.field_company_country']">Company Country</th>
              <th v-if="user.can['companies_saved.field_company_state_region']">Company State or Region</th>
              <th v-if="user.can['companies_saved.field_company_city']">Company City</th>
              <th v-if="user.can['companies_saved.field_company_address']">Company Address</th>
              <th v-if="user.can['companies_saved.field_industry']">Industry</th>
              <th v-if="user.can['companies_saved.field_employees']">Number of employees</th>
              <th v-if="user.can['companies_saved.field_first_name']">First name</th>
              <th v-if="user.can['companies_saved.field_last_name']">Last name</th>
              <th v-if="user.can['companies_saved.field_title']">Title</th>
              <th v-if="user.can['companies_saved.field_corporate_email']">Corporate email</th>
              <th v-if="user.can['companies_saved.field_personal_email']">Personal email</th>
              <th v-if="user.can['companies_saved.field_personal_phone_number']">Personal phone number</th>
              <th v-if="user.can['companies_saved.field_company_phone']">Company Phone</th>
              <th v-if="user.can['companies_saved.field_technologies']">Technologies</th>
              <th v-if="user.can['companies_saved.field_annual_revenue']">Annual Revenue</th>
              <th v-if="user.can['companies_saved.field_specialties']">Specialties</th>
              <th v-if="user.can['companies_saved.field_contacts_location_country']">Contact's location/Country</th>
              <th v-if="user.can['companies_saved.field_contacts_location_state']">Contact's location/State or Region</th>
              <th v-if="user.can['companies_saved.field_contacts_location_city']">Contact's location/City</th>
              <th v-if="user.can['companies_saved.field_contacts_facebook']">Contact's Facebook</th>
              <th v-if="user.can['companies_saved.field_contacts_twitter']">Contact's Twitter</th>
              <th v-if="user.can['companies_saved.field_ecommerce_scheme']">B2B/B2C</th>
              <th v-if="user.can['companies_saved.field_total_funding']">Total funding</th>
              <th v-if="user.can['companies_saved.field_last_funding_type']">Last funding type</th>
              <th v-if="user.can['companies_saved.field_last_funding_round_name']">Last funding round name</th>
              <th v-if="user.can['companies_saved.field_last_funding_round_date']">Last funding round date</th>
              <th v-if="user.can['companies_saved.field_last_funding_round_value']">Last funding round value</th>
              <th v-if="user.can['companies_saved.field_company_sales_navigator_id']">Company sales navigator id</th>
              <th v-if="user.can['companies_saved.field_lead_sales_navigator_id']">Lead sales navigator id</th>


              <th v-if="user.can['companies_saved.field_created_at']">Created</th>
              <th v-if="user.can['companies_saved.field_updated_at']">Updated</th>
              <th v-if="user.can['companies_saved.field_data_updated_at']">Data updated</th>
              <th v-if="user.can['companies_saved.field_id']">ID Salesar</th>
              <!--                <th v-if="user.can['contacts.edit'] ">Edit</th>-->
              <!--                <th v-if="user.can['contacts.delete']">Delete</th>-->
            </tr>
            </thead>
            <tbody>
            <tr v-for="company in allCompanies.data" :key="company.id">
              <td class="checkbox list">
                <!--                    <el-checkbox-group v-model="checkedContactsIds" @change="handleCheckedContactChange">-->
                <!--                        <el-checkbox  :label="contact.id" :key="contact.id" size="small" class="hidden"></el-checkbox>-->
                <!--                    </el-checkbox-group>-->
              </td>
              <td v-if="user.can['companies_saved.field_name']" class="pos-rel">
                <router-link
                    v-if="company.id"
                    :to="{ path: '/company/' + company.id}"
                >
                                                    <span class="d-flex flex-row">
                                                        <span v-if="company.logo_company" class="text-nowrap logo d-flex">
                                                                <el-image
                                                                    :src="'/storage/logo_company/mini/'+company.id+'_logo_mini.png'"
                                                                    fit="contain">
                                                                    <div slot="error" class="image-slot">
                                                                        <i class="el-icon-picture-outline"></i>
                                                                    </div>
                                                        </el-image>
                                                        </span>
                                                        <span class="text-nowrap d-flex">
                                                                   <span class="d-flex flex-column">
                                                                       <span class="d-flex">{{company.name}}</span>
                                                                    <span v-if="company.founded" class="text-nowrap founded d-flex">{{company.founded}}</span>
                                                                        <span class="d-flex">
                       <a v-if="user.can['companies_saved.field_website']" class="social" :href="'https://' + company.website" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fa fa-chain"/>
                       </a>
                        <a v-if="user.can['companies_saved.field_linkedin_company']" class="social" :href="company.linkedin_company" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fab fa-linkedin" aria-hidden="true"/>
                       </a>
                    </span>
                                                                </span>
                                                        </span>
                                                    </span>
                </router-link>
                <span v-else   class="flex-column">
                                                             <span class="d-flex flex-row">
                                                       <span v-if="company.logo_company" class="text-nowrap logo d-flex">
                                                                <el-image
                                                                    :src="'/storage/logo_company/mini/'+company.id+'_logo_mini.png'"
                                                                    fit="contain">
                                                                    <div slot="error" class="image-slot">
                                                                        <i class="el-icon-picture-outline"></i>
                                                                    </div>
                                                                </el-image>
                                                        </span>
                                                        <span class="text-nowrap d-flex">
                                                                   <span class="d-flex flex-column">
                                                                       <span class="d-flex">{{company.company}}</span>
                                                                    <span v-if="company.founded" class="text-nowrap founded d-flex">{{company.founded}}</span>
                                                                        <span class="d-flex">
                       <a v-if="user.can['companies_saved.field_website']" class="social" :href="'https://' + company.website" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fa fa-chain"/>
                       </a>
                        <a v-if="user.can['companies_saved.field_linkedin_company']" class="social" :href="company.linkedin_company" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fab fa-linkedin" aria-hidden="true"/>
                       </a>
                    </span>
                                                                </span>
                                                        </span>
                                                    </span>
                                                </span>
              </td>
              <td v-if="user.can['companies_saved.field_website']">{{company.website}}</td>
              <td v-if="user.can['companies_saved.field_company_country']"><span class="text-nowrap">{{company.company_country}}</span></td>
              <td v-if="user.can['companies_saved.field_company_state_region']">{{company.company_state_region}}</td>
              <td v-if="user.can['companies_saved.field_company_city']">{{company.company_city}}</td>
              <td v-if="user.can['companies_saved.field_company_address']">{{company.company_address}}</td>
              <td v-if="user.can['companies_saved.field_industry']"><span class="text-nowrap">{{company.industry}}</span></td>
              <td v-if="user.can['companies_saved.field_employees']">{{company.employees}}</td>
              <td v-if="user.can['companies_saved.field_first_name']">{{company.first_name}}</td>
              <td v-if="user.can['companies_saved.field_last_name']">{{company.last_name}}</td>
              <td v-if="user.can['companies_saved.field_title']"><span class="text-nowrap">{{company.title}}</span></td>
              <td v-if="user.can['companies_saved.field_corporate_email']"><i v-if="user.can['contacts_saved.field_email_status']" class="fa fa-envelope" :class="company.email_status === 'valid'? 'text-success': company.email_status === 'accept_all' ? 'text-warning' : 'text-danger' "/> {{company.corporate_email}}</td>
              <td v-if="user.can['companies_saved.field_personal_email']">{{company.personal_email}}</td>
              <td v-if="user.can['companies_saved.field_personal_phone_number']"><span class="text-nowrap">{{company.personal_phone_number}}</span></td>
              <td v-if="user.can['companies_saved.field_company_phone']"><span class="text-nowrap">{{company.company_phone}}</span></td>
              <td v-if="user.can['companies_saved.field_technologies']">{{company.technologies}}</td>
              <td v-if="user.can['companies_saved.field_annual_revenue']">{{company.annual_revenue}}</td>
              <td v-if="user.can['companies_saved.field_specialties']"> <span class="d-inline-block text-truncate" style="max-width: 400px;">{{company.specialties}}</span></td>
              <td v-if="user.can['companies_saved.field_contacts_location_country']">{{company.contacts_location_country}}</td>
              <td v-if="user.can['companies_saved.field_contacts_location_state']">{{company.contacts_location_state}}</td>
              <td v-if="user.can['companies_saved.field_contacts_location_city']">{{company.contacts_location_city}}</td>
              <td v-if="user.can['companies_saved.field_contacts_facebook']"><a :href="company.contacts_facebook" rel="noreferrer" target="_blank">{{company.contacts_facebook}}</a></td>
              <td v-if="user.can['companies_saved.field_contacts_twitter']"><a :href="company.contacts_twitter" rel="noreferrer" target="_blank">{{company.contacts_twitter}}</a></td>
              <td v-if="user.can['companies_saved.field_ecommerce_scheme']">{{company.ecommerce_scheme}}</td>
              <td v-if="user.can['companies_saved.field_total_funding']"><span class="d-inline-block text-truncate total_funding" style="max-width: 400px;">{{company.total_funding}}</span></td>
              <td v-if="user.can['companies_saved.field_last_funding_type']"><span class="d-inline-block text-truncate last_funding_type" style="max-width: 400px;">{{company.last_funding_type}}</span></td>
              <td v-if="user.can['companies_saved.field_last_funding_round_name']"><span class="d-inline-block text-truncate last_funding_round_name" style="max-width: 400px;">{{company.last_funding_round_name}}</span></td>
              <td v-if="user.can['companies_saved.field_last_funding_round_date']"><span class="d-inline-block text-truncate last_funding_round_date" style="max-width: 400px;">{{company.last_funding_round_date}}</span></td>
              <td v-if="user.can['companies_saved.field_last_funding_round_value']"><span class="d-inline-block text-truncate last_funding_round_value" style="max-width: 400px;">{{company.last_funding_round_value}}</span></td>
              <td v-if="user.can['companies_saved.field_company_sales_navigator_id']"><span class="d-inline-block text-truncate company_sales_navigator_id" style="max-width: 400px;">{{company.company_sales_navigator_id}}</span></td>

              <td v-if="user.can['companies_saved.field_created_at']">{{company.created_at}}</td>
              <td v-if="user.can['companies_saved.field_updated_at']">{{company.updated_at}}</td>
              <td v-if="user.can['companies_saved.field_data_updated_at']">{{company.data_updated_at}}</td>
              <td v-if="user.can['companies_saved.field_id']">{{company.id}}</td>
                              <td v-if="user.can['contacts.edit']">
                                  <div class="dt-buttons btn-group flex-wrap">
                                      <button v-if="user.can['contacts.edit']" type="button"
                                              class="btn  btn-outline-primary btn-sm btn-flat"
                                              @click.prevent="editModal(contact)">
                              <font-awesome-icon icon="fa-edit"/>
                                          Edit

                                      </button>
                                  </div>

                              </td>
                              <td v-if="user.can['contacts.delete']">
                                  <div class="dt-buttons btn-group flex-wrap">
                                      <button v-if="user.can['contacts.delete']" type="button"
                                              class="btn btn-outline-danger btn-sm btn-flat"
                                              @click.prevent="deleteContacts(contact.id)">
                              <font-awesome-icon icon="fa-trash"/>
                                          Delete
                                      </button>
                                  </div>

                              </td>
            </tr>
            </tbody>
          </table>
<!--       modal-filter-max-company &&   modal-edit-company-by-list  todo:check need??-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import {Form} from "vform";

export default {
  name: "ListSavedCompanyPage",
  components: {
    // DropdownListName,
    // SwitchDisplayContacts
  },
  mounted() {
    if (this.$route.params.id === 'all') {
      this.$store.dispatch('listName/setListNameIdSelected');
    } else {
      this.$store.dispatch('listName/setListNameIdSelected', this.$route.params.id);
    }  },

  computed: {
    ...mapGetters('listNameCompanies',
        [
          'allCompanies',
          'getFilterParams',
          'getIdsOptions',
          'getCheckedContactsIds',
          'getCheckAllOnPage',
          'getIsIndeterminate',
        ]),
  },
  watch: {
    '$route.query': {
      handler(newVal) {
        newVal.page = this.$route.query.page ?? 1;
        this.$Progress.start();
        this.$store.dispatch('listNameCompanies/setStatesFromQuery',newVal);
        if (this.$route.params.id === 'all') {
          this.$store.dispatch('listName/setListNameIdSelected').then(() => {
            this.$store.dispatch('listCompanies/counterByList'); // not use if only pagination
            this.$store.dispatch('listNameCompanies/getListNameCompanies').then(() => {
              this.$Progress.finish();
            }).catch(() => {
              this.$Progress.fail();
            });
          });
        } else {
          this.$store.dispatch('listName/setListNameIdSelected', this.$route.params.id).then(() => {
            this.$store.dispatch('listCompanies/counterByList'); // not use if only pagination
            this.$store.dispatch('listNameCompanies/getListNameCompanies').then(() => {
              this.$Progress.finish();
            }).catch(() => {
              this.$Progress.fail();
            });
          });
        }
      },
      immediate: true
    }
  },

  // mounted() {
  //   this.$store.dispatch("formCheckFilterMaxCompany/getCheckedIds");
  // },
  data() {
    return {
      user: this.$store.getters.user,
      editmode: false,
      confirmedHide: false,
      form: new Form({
        id: null,
        name: null, // company
        website: null,
        company_country: null,
        company_state_region: null,
        company_city: null,
        company_address: null,
        industry: null,
        employees: null,

        linkedin_company: null,
        company_phone: null,
        technologies: null,
        annual_revenue: null,
        specialties: null,

        ecommerce_scheme: null,
        created_at: null,
        updated_at: null,

        email_status: null,
        founded: null,
        logo_company: null,

        total_funding: null,
        last_funding_type: null,
        last_funding_round_name: null,
        last_funding_round_date: null,
        last_funding_round_value: null,
        // optional condition
        update_without_null: null,
        update_by_website: null,
        update_all_contacts: null,
      }),
      drawerCompanyDetails: {visible: false, company_id: null},
    };
  },
  methods:{
    getTitle(){
       return this.$route.params.list_name ?? this.$route.meta.list_name_title
    }
  }
};
</script>
<style src="@/scss/list-saved-page/index.scss" lang="scss"></style>