import Vue from 'vue';
import Vuex from 'vuex';

import auth from './modules/auth'
import people from './modules/people'
import company from './modules/company'
import importFails from './modules/importFails'
import importContacts from './modules/importContacts'
import listName from './modules/listName'
import listContacts from './modules/listContacts'
import formCheckFilterMax from './modules/formCheckFilterMax'
import listNameContacts from './modules/listNameContacts'
import filters from './modules/filters'
import filterData from './modules/filterData'
import searchData from './modules/searchData'
import users from './modules/users';
import paginate from './modules/paginate';
import companyList from './modules/companyList';
import formCheckFilterMaxCompany from "./modules/formCheckFilterMaxCompany";
import listCompanies from "./modules/listCompanies";
import listNameCompanies from "./modules/listNameCompanies";
import leads from './modules/leads'
import listLeads from './modules/listLeads'
import tariffs from "./modules/tariffs";
import tariffOrders from "./modules/tariffOrders";
import userTariffs from "./modules/userTariffs";
import creditLogs from "./modules/creditLogs";
import userTariffsControl from "./modules/userTariffsControl";
import companyGeoList from "./modules/companyGeoList";
import contactsGeoList from "./modules/contactsGeoList";
import titleList from "./modules/titleList";
import industryList from './modules/industryList'
import technologiesList from './modules/technologiesList'
import profile from './modules/profile'
import employeesList from './modules/employeesList'
import ecommerceSchemeList from './modules/ecommerceSchemeList';
import savedSearch from './modules/sevedSearch'
import loadSearch from "./modules/loadSearch";

Vue.use(Vuex);
const defaultLang = 'en';

const initLang = (() => {
    let lang = window.localStorage.getItem('language') || window.navigator.language;
    try {
        require("../i18n/langs/"+ lang);
        return lang;
    }
    catch (e) {
        if (e instanceof Error && e.code === "MODULE_NOT_FOUND"){
            // console.log("Can't load module'"+lang+"' lang! Set default ");
        }else {
            throw e;
        }
    }

    return defaultLang;
})();

const store = new Vuex.Store({
    actions: {
        changeLanguage ({ commit }, payload) {
            commit('onLangChanged', payload)
        },
        setOnceUser ({ commit }, payload) {
            commit('setOnceUser', payload)
        },
        setUser ({ commit }, payload) {
            commit('setUser', payload)
        },
    },
    mutations: {
        setOnceAppName(state, data){
            if(state.appName === '' &&  data.appName.length ){
                state.appName = data.appName;
            }
        },
        setOnceUser(state, data){
            if(!state.user.email && data.can){
                state.user.id = data.id;
                state.user.name = data.name;
                state.user.email = data.email;
                state.user.role_name = data.role_name;
                state.user.timezone = data.timezone;
                state.user.phone_number = data.phone_number;
                state.user.email_verified_at = data.email_verified_at;
                state.user.created_at = data.created_at;
                state.user.job_title = data.job_title;
                state.user.seniority = data.seniority;
                state.user.company_name = data.company_name;
                state.user.company_website = data.company_website;
                state.user.tariff_row = parseInt(data.tariff_row);
                for (const [key, value] of Object.entries(data.can)) {
                    state.user.can[key] = value;
                }
            }
        },
        setUser(state, data) {
            state.user = data
        },
        onLangChanged (state, payload) {
            window.localStorage.setItem('language', payload.lang);
            payload.i18n.locale = payload.lang;
            state.lang = payload.lang
        },
        setLayout(state, payload) {
            state.layout = payload
        }
    },
    state: {
        appName: '',
        user: {
            id:null,
            can:{},
            email: null,
            email_verified_at: null,
            role_name: null,
            timezone: null,
            tariff_row: 0,
            phone_number: null,
            name: null,
            created_at: null,
            job_title: null,
            seniority: null,
            company_name: null,
            company_website: null
            },
        lang: initLang,
        timers: {
            filterValues: 0,
            historyPush: 0,
            checkbox: 0,
            search: 0,
            typeDisplay: 0,
        },
        layout: 'default-layout'
    },
    getters: {
        appName(state){
            return state.appName
        },
        user(state){
            return state.user
        },
        can(state){
            return state.user.can
        },
        lang(state){
            return state.lang
        },
        getUserTariffRow(state){
            return state.user.tariff_row
        },
        layout(state) {
            return state.layout
        },
    },
    modules: {
        auth,
        company,
        people,
        importContacts,
        importFails,
        listName,
        listContacts,
        listLeads,
        formCheckFilterMax,
        listNameContacts,
        filters,
        filterData,
        searchData,
        listNameCompanies,
        users,
        paginate,
        companyList,
        formCheckFilterMaxCompany,
        listCompanies,
        leads,
        tariffs,
        tariffOrders,
        userTariffs,
        creditLogs,
        userTariffsControl,
        companyGeoList,
        contactsGeoList,
        titleList,
        industryList,
        technologiesList,
        profile,
        employeesList,
        ecommerceSchemeList,
        savedSearch,
        loadSearch
    },
});
export default store;
