import axios from "axios";
import toastMessage from "../toastMessage";
import router from "../../routes";
import store from "../index";
import qs from "qs";

const actions = {
    getCreditLogs({commit, getters}) {
        return axios.get(process.env.VUE_APP_API_URL + 'credit/logs?' + qs.stringify(getters.getFilterParams))
            .then((response) => {
                commit('updateCredits', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },

    setCurrentPage({commit, getters}, page){
        if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1){
            commit('updatePage', page);
            commit('historyPush', getters.getFilterParams);
        }
    },

    setTotal({commit, getters}, total){
        commit('updateTotal', total);

        if((router.currentRoute.query.total !== undefined && Number(router.currentRoute.query.total) !== Number(total))
            || (router.currentRoute.query.total === undefined && Number(total) > 0)
        ){
            commit('historyPush', getters.getFilterParams);
        }
    },

    setEventFilter({commit, getters}){
        commit('updatePage', 1);
        commit('historyPush', getters.getFilterParams);
    },

    setStatesFromQuery({commit}, params) {
        let  filters = params.filters;
        let  checkbox = params.checkbox;
        let  search = params.search;
        let  listNameId = params.list_name_id;
        let  typeDisplayContacts = params.type_display_contacts;
        let  page = params.page;
        let  total = params.total;

        if(page){
            commit('updatePage', page)
        }
        if(total){
            commit('updateTotal', total)
        }

        if(checkbox){
            store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
        }else{
            store.dispatch('filters/setClearCheckboxesValues', checkbox);
        }

        if(filters){
            store.dispatch('filters/setUpdateFiltersValues', filters);
        }else{
            store.dispatch('filters/setClearFiltersValues');
        }

        if(search){
            store.dispatch('filters/setUpdateSearchesValues', search);
        }else{
            store.dispatch('filters/setClearSearchesValues', search);
        }

        if(listNameId){
            store.dispatch('listName/setListNameIdSelected', listNameId);
        }else{
            store.dispatch('listName/clearListNameIdSelected', listNameId)
        }

        if(typeDisplayContacts){
            commit('updateTypeDisplayContacts', typeDisplayContacts);
        }

    },
};

const state = {
    logs: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
    },
};

const mutations = {
    historyPush(state, params) {
        clearTimeout(store.state.timers.historyPush);
        store.state.timers.historyPush = setTimeout(() => {
            router.push({query: qs.parse(params)}, () => {});
        }, 10); //ms
    },
    updateTotal(state, data) {
        state.logs.total = data
    },
    updateCredits(state, data) {
        state.logs = data;
    },
    updatePage(state, data) {
        state.logs.current_page = data;
    },
};

const getters = {
    getCreditLogs(state) {
        return state.logs;
    },
    getPage() {
        return state.logs.current_page;
    },
    getFilterParams(state, getters){
        return {
            total: getters.getTotal,
            page: getters.getPage,
            filters: store.getters['filters/filtersValues'],
        }
    },
    getTotal() {
        return state.logs.total;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
