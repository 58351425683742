import moment from "moment-timezone";
import store from '../../store/index';

const formatDateAgo = (value, hours) => {
    if(!value){
        return value;
    }
    if (hours) {
        if(store.getters.user.timezone){
            return moment(value).parseZone().tz(store.getters.user.timezone).fromNow();
        }else{
            return moment(value).parseZone().fromNow();
        }

    }
    if(store.getters.user.timezone){
        return moment(value).parseZone().tz(store.getters.user.timezone).fromNow();
    }else{
        return moment(value).parseZone().fromNow();
    }

}

export default formatDateAgo;
