<template>
  <aside class="aside-company">
    <div class="card-header">
      <h3 class="card-title">Company details</h3>
    </div>
    <div class="container-company-info">
        <div class="info-body ">
            <template v-if="getCompany">
              <div class="company-info">
                <div class="avatar-name">
                  <span v-if="getCompany.logo_company" class="logo d-flex" >
                            <el-image :src="'/storage/logo_company/mini/' + getCompany.company_id + '_logo_mini.png'" fit="contain" class="logo-sidebar">
                              <div slot="error">
                                <inline-svg :src="require('@/assets/images/table/d_img.svg')"/>
                              </div>
                            </el-image>
                          </span>

                  <span v-else class="logo d-flex" >
                              <el-image :src="require('@/assets/images/table/d_img.svg')" fit="contain" class="logo-sidebar"></el-image>
                           </span>
                  <div class="info">
                    <p class="name"><strong>{{ getCompany.name }}</strong></p>
                    <p class="job-title">{{ getCompany.industry }}</p>
                  </div>
                </div>
              </div>

              <div class="info-item">
                <p class="title">Company data</p>
                <a v-if="getCompany.linkedin_company !== null" :href="getCompany.linkedin_company"  rel="noreferrer" target="_blank" class="link"><i class="soc-icon linkedin company-link"></i><span class="linkedin-text">{{ getCompany.linkedin_company }}</span></a>
                <a v-if="getCompany.website !== null" :href="'//' + getCompany.website" target="_blank" rel="noreferrer" class="link"><i class="soc-icon link" style="margin-top: 0"></i>{{ getCompany.website }}</a>
                <a v-if="getCompany.company_phone !== null" href="#" class="primary-text"><i class="contact-icon phone"></i>{{ getCompany.company_phone }}</a>
                <a v-if="getCompany.email_status !== null" href="#" class="primary-text"><i class="contact-icon mail"></i>{{getCompany.email_status}}</a>
              </div>

              <div class="info-item">
                <p class="title">Company location</p>
                <p class="primary-text"><span v-if="getCompany.company_address !== null">{{getCompany.company_address}},&nbsp; </span> <span v-if="getCompany.company_city !== null">{{getCompany.company_city}},&nbsp; </span> {{getCompany.company_country}}</p>
              </div>
              <div v-if="getCompany.technologies !== null" class="info-item">
                <p class="title">Technologies</p>
                <p class="txt">{{ getCompany.technologies }}</p>
              </div>
              <div v-if="getCompany.employees !== null" class="info-item">
                <p class="title">Number of employees</p>
                <p class="txt">{{ getCompany.employees }}</p>
              </div>
              <div v-if="getCompany.annual_revenue !== null" class="info-item">
                <p class="title">Annual revenue</p>
                <p class="txt">{{ getCompany.annual_revenue }}</p>
              </div>
              <div v-if="getCompany.last_funding_round_value !== null" class="info-item">
                <p class="title">Funding</p>
                <p class="txt">{{ getCompany.last_funding_round_value }}</p>
              </div>
              <div v-if="getCompany.ecommerce_scheme !== null" class="info-item">
                <p class="title">Ecommerce sheme </p>
                <p class="txt">{{ getCompany.ecommerce_scheme }}</p>
              </div>
              <div v-if="getCompany.founded !== null" class="info-item">
                <p class="title">Year founded</p>
                <p class="txt">{{ getCompany.founded }}</p>
              </div>
            </template>
        </div>
    </div>
  </aside>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "CompanyCardInfo",
        computed: {
            ...mapGetters('company',
                [ 'getCompany']
            )
        },
        data() {
            return {
                user: this.$store.getters.user
            }
        }
    }
</script>

<style src="@/scss/company-cards/company-card-info.scss" lang="scss" ></style>
