import toastMessage from "../toastMessage";
import store from "../index";
import utils from "../../utils";
import axios from 'axios';
import router from "@/routes";
import qs from "qs";

const actions = {
    sendData({commit, getters}, data) {
        let countFilters = 0;
        Object.values(getters.getFilterParams.filters).forEach(item => {
            if (item.length > 0) {
                countFilters += item.length;
            }
        });

        let filterParams = getters.getFilterParams;
        let allData = {
            name: data.name,
            filters: filterParams,
            filters_data: store.getters['filterData/filter'],
            count_filters: countFilters
        };

        let promise = axios.post(process.env.VUE_APP_API_URL + 'saved-search', allData);

        promise.then((response) => {
            if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
            commit('getResultSavedSearch', response.data.data);

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },

    getSavedSearch({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + "saved-search?" + qs.stringify({page: getters.getPage}));

        promise.then((response) => {
            response.data.data.current_page = getters.getPage // skip if already next page // fixed infinity reload event by changed current_page

            console.log(response.data.data);
            commit('updateSavedSearch', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },

    setStatesFromQuery({commit}, page) {
        if(page){
            commit('updatePage', page)
        }
    },

    setCurrentPage({commit, getters}, page){
        commit('updatePage', page);
        let queryParams = {
            page: getters.getPage,
        };

        commit('historyPush', queryParams);
    }
};

const state = {
    response: {},
    savedSearch: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
    },
    list:{
        idTempList: null,
        countTempList: 0,
        idsOptions: [],
        checkedContactsIds: [],
        isIndeterminate: false,
        checkAllOnPage: false,

        check_query: {
            all: false,
            max: false,
            per_company: false
        },
        maxRow: 25,// default
        perCompanyRow: 3,// default
        query: '',
    }
};

const mutations = {
    updatePage(state, data) {
        state.savedSearch.current_page = data;
    },
    updateSavedSearch(state, data) {
        state.savedSearch = data;
    },
    getResultSavedSearch(state, data) {
        state.response = data;
    },
    historyPush(state, params) {
        router.push({name:'SavedSearch' ,query: params}, () => {});
    },
};

const getters = {
    getFilterParams(state, getters){
        return {
            total: getters.getTotal,
            page: getters.getPage,
            filters: store.getters['filters/filtersValues'],
            checkbox: store.getters['filters/checkboxValues'],
            search: store.getters['filters/searchValues'],
            list_name_id: store.getters['listName/listNameIdSelected'],
            checked_bulk: store.getters['formCheckFilterMaxCompany/getCheckedBulk'],
            order_checked_all: store.getters['formCheckFilterMaxCompany/getOrderCheckedAll'],
            type_display_contacts: getters.getTypeDisplayContacts,
        }
    },
    getSavedSearchList(state) {
        return state.savedSearch;
    },
    getPage() {
        return state.savedSearch.current_page;
    },
    getTotal() {
        return state.savedSearch.total;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
