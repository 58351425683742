<template>
  <div class="default-layout">
    <navbar></navbar>
    <main>
      <sidebar-menu></sidebar-menu>
      <div class="main-container">
        <list-saved-contacts-page></list-saved-contacts-page>
        <pagination-contacts></pagination-contacts>
      </div>
    </main>
  </div>
</template>
<script>
import ListSavedContactsPage from "@/components/pages/list-saved-page/ListSavedContactsPage.vue";
import PaginationContacts from "@/components/pagination/pagination-list-name-contact.vue";
export default {
  components: {
    ListSavedContactsPage,
    PaginationContacts,
  },
  data() {
    return {

    }
  }
}
</script>
<style>
sidebar-profile {
  height: 100%;
}
</style>
