/**
 * The pluck method retrieves all of the values for a given key:
 * @param array
 * @param key
 * @return {*}
 */
const pluck = (array, key) =>
    array.map(o => o[key]);

export default pluck
