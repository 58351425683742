import axios from "axios";
import toastMessage from "../toastMessage";
import router from "../../routes";
import store from "../index";
import qs from "qs";
import {Form} from 'vform';

    const actions = {
        getList({commit, getters}) {
            let promise = axios.get(process.env.VUE_APP_API_URL + "import-fails?" + qs.stringify(getters.getFilterParams));

            promise.then((response) => {
                commit('updateImport', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });

            return promise;
        },
        createImport({commit}, vForm = new Form()) {
            let promise = vForm.post(process.env.VUE_APP_API_URL + 'import-fail');

            promise.then((response) => {
                commit('prependImport', response.data.data);
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;

        },
        putImport({commit}, vForm = new Form()) {
            let promise = vForm.put(process.env.VUE_APP_API_URL + 'import-fail/' + vForm.id);
            promise.then((response) => {
                commit('findReplaceImport', response.data.data);
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        deleteImport({commit}, id) {
            let promise = new Promise(function (resolve, reject) {
                return axios.delete(process.env.VUE_APP_API_URL + 'import-fail/' + id).then((response) => {
                    if (response.data.success === true) {
                        resolve(response);
                    } else {
                        reject(response);
                    }
                }).catch((error) => {
                    reject(error);
                });
            });
            promise.then((response) => {
                commit('findDeleteImport', response.data.data);
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },

        moveToImport({getters}) {
            let promise = axios.post(process.env.VUE_APP_API_URL + 'import-fail/move-to-import', getters.getFilterParams);
            promise.then((response) => {
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
        },
        setCurrentPage({commit, getters}, page){
            if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
                || router.currentRoute.query.page === undefined && Number(page) > 1) {
                commit('updatePage', page);
                commit('historyPush', getters.getFilterParams);
            }
        },
        setEventFilter({commit, getters}){
            commit('updatePage', 1);
            commit('historyPush', getters.getFilterParams);
        },
        setStatesFromQuery({commit}, params) {

            let  filters = params.filters;
            let  checkbox = params.checkbox;
            let  search = params.search;
            let  page = params.page;

            if(page){
                commit('updatePage', page)
            }

            if(checkbox){
                store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
            }else{
                store.dispatch('filters/setClearCheckboxesValues', checkbox);
            }

            if(filters){
                store.dispatch('filters/setUpdateFiltersValues', filters);
            }else{
                store.dispatch('filters/setClearFiltersValues');
            }

            if(search){
                store.dispatch('filters/setUpdateSearchesValues', search);
            }else{
                store.dispatch('filters/setClearSearchesValues', search);
            }
        },
    };

    const mutations = {
        // - Import
        updateImport(state, data) {
            state.import_fails = data
        },
        prependImport(state, data) {
            state.import_fails.data.unshift(data);
        },
        findReplaceImport(state, data) {
            const item = state.import_fails.data.find(item => item.id === data.id);
            Object.assign(item, data);
        },
        findDeleteImport(state, data) {
            const index = state.import_fails.data.findIndex(item => item.id === data.id);
            state.import_fails.data.splice(index, 1)
        },

        // - router
        historyPush(state, params) {
            clearTimeout(store.state.timers.historyPush);
            store.state.timers.historyPush = setTimeout(() => {
                router.push({name:'ImportFails', query: qs.parse(params)}, () => {});
            }, 10); //ms
        },

        // - page
        updatePage(state, data) {
            state.import_fails.current_page = data
        },

    };

    const state = {
        import_fails: {
            current_page: 0,
            data: [],
            from: 0,
            to: 0,
            total: 0,
            last_page: 0,
            per_page: 0,
        },
    };

    const getters = {
        getPage(state) {
            return state.import_fails.current_page;
        },
        allImport(state) {
            return state.import_fails;
        },
        filtersValues(state) {
            return state.filters;
        },
        getFilterParams(state, getters){
            return {
                page: getters.getPage,
                filters: store.getters['filters/filtersValues'],
                checkbox: store.getters['filters/checkboxValues'],
                search: store.getters['filters/searchValues'],
            }
        },
    };

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
