<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card">
                    <div class="card-header"> Not Found 404</div>

                    <div class="card-body">
                        PAGE 404
                        <div>
                            <small> Vue-Route not found</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('NotFound Component mounted.')
        }
    }
</script>
