import axios from "axios";
import toastMessage from "../toastMessage";
import store from "../index";
import qs from "qs";


const actions = {
    createList(_, data) {
        let promise = axios.post(process.env.VUE_APP_API_URL + "list-company", {checked_company_ids: data.checked_company_ids, list_name_id: data.list_name_id, company: data.company });
        promise.then((response) => {
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    countSaved({commit,getters}){
        let promise = axios.get(process.env.VUE_APP_API_URL + 'list-company/count-saved?'+ qs.stringify(store.getters['companyList/getFilterParams']));
        promise.then((response) => {
            commit('updateInfoSavedCount', response.data.data);
            if(getters('infoTotalCount')){
                commit('updateInfoNotSavedCount', getters('infoTotalCount') - response.data.data);
            }

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    counter({commit}){
        let promise = axios.get(process.env.VUE_APP_API_URL + 'list-company/counter?'+ qs.stringify(store.getters['companyList/getFilterParams']));
        promise.then((response) => {
            commit('updateInfoSavedCount', response.data.data.count_saved);
            commit('updateInfoNotSavedCount', response.data.data.count_not_saved);
            commit('updateInfoTotalCount', response.data.data.count_total);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    counterByList({commit}){
        let query = store.getters['listNameCompanies/getFilterParams'];
        let promise = axios.get(process.env.VUE_APP_API_URL + 'list-company/count-by-list-saved-only?'+ qs.stringify(query));
        promise.then((response) => {
            commit('updateInfoSavedCount', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    checkByOne(_, data) {
        let promise = axios.post(process.env.VUE_APP_API_URL + "company/check-by-one", {checked:data.checked, unchecked: data.unchecked});
        promise.then((response) => {
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
};

const mutations = {
    updateInfoTotalCount(state, data) {
        state.info.total.count = data;
    },
    updateInfoNotSavedCount(state, data) {
        state.info.not_saved.count = data;
    },
    updateInfoSavedCount(state, data) {
        state.info.saved.count = data;
    },

};

const state = {
    info: {
        total: {
            count: 0,
            loading: 0,
        },
        not_saved: {
            count: 0,
            loading: 0,
        },
        saved: {
            count: 0,
            loading: 0,
        },
    }


};

const getters = {
    infoTotalCount(state) {
        return state.info.total.count;
    },
    infoNotSavedCount(state) {
        return state.info.not_saved.count;
    },
    infoSavedCount(state) {
        return state.info.saved.count;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
