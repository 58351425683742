<template>
  <div>
    <el-dropdown-menu class="dropdown-filter-max" v-model="visible">
      <div class="header-dropdown">
        <p class="title">Bulk select</p>
        <span @click="visible = false" class="icon icon--close"></span>
      </div>
      <form @submit.prevent="formSubmitCheckByFilterMax">
        <div class="container">
          <div class="item">
            <div class="checkbox">
              <el-checkbox-group v-model="checkAll">
                <div @change="event => checkboxCheckedChange(event, 'all')">
                  <el-checkbox label="all"
                               :key="true"
                               name="check_all"
                               class="form-control-sm"
                               :class="{ 'is-invalid': getForm.errors.has('check_all') }">
                    All row tariff
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <span :class="{ 'is-invalid': getForm.errors.has('check_all') }"></span>
              <has-error :form="getForm" field="check_all"></has-error>
            </div>
            <p class="num">{{ getTariffName() }}</p>
          </div>

          <div class="item">
            <div class="checkbox">
              <el-checkbox-group v-model="checkMax">
                <div @change="event => checkboxCheckedChange(event, 'max')">
                  <el-checkbox label="max"
                               :key="true"
                               name="check_max"
                               class="form-control-sm"
                               :class="{ 'is-invalid': getForm.errors.has('check_max') }">
                    Max row
                  </el-checkbox>
                </div>
              </el-checkbox-group>
              <span :class="{ 'is-invalid': getForm.errors.has('check_max') }"></span>
              <has-error :form="getForm" field="check_max"></has-error>
            </div>
            <el-input name="list_max_row"
                      v-model="listMaxRow"
                      id="list_max_row"
                      type="number"
                      :class="{ 'is-invalid': getForm.errors.has('list_max_row') }"/>
            <has-error :form="getForm" field="list_max_row"/>
          </div>

          <div class="item">
            <div class="checkbox">
              <el-checkbox-group v-model="checkPerCompany">
                <div @change="event => checkboxCheckedChange(event, 'per_company')">
                  <el-checkbox label="per_company" :key="true" name="check_per_company"
                               class="form-control-sm"
                               :class="{ 'is-invalid': getForm.errors.has('check_per_company') }"
                  >
                    Contacts per company
                  </el-checkbox>
                </div>
              </el-checkbox-group>

              <span :class="{ 'is-invalid': getForm.errors.has('check_per_company') }"></span>
              <has-error :form="getForm" field="check_per_company"/>
            </div>
            <el-input v-model="listPerCompanyRow"
                      id="list_per_company"
                      type="number"
                      name="list_per_company"
                      :class="{ 'is-invalid': getForm.errors.has('list_per_company') }"/>
            <has-error :form="getForm" field="list_per_company"/>
          </div>

          <input v-model="formQuery"
                 id="query"
                 type="hidden"
                 name="query"
                 :class="{ 'is-invalid': getForm.errors.has('query') }">

          <input v-model="getForm.type_display_contacts"
                 type="hidden"
                 name="type_display_contacts"
                 id="type_display_contacts"/>
          <div class="el-message-box__errormsg">{{ warning_message }}</div>
          <has-error :form="getForm" field="type_display_contacts"></has-error>

        </div>
        <div class="buttons">
          <el-button @click="visible = false" type="default" class="button button--cancel small">Close
          </el-button>
          <el-button type="primary"
                     size="mini"
                     nativeType="submit"
                     :loading="submitted"
                     :disabled="!allowSubmit"
                     class="button button--blue small"
                     :class="allowSubmit ? '': 'disabled'">{{ submitted ? 'Loading' : 'Select' }}
          </el-button>
        </div>
      </form>
    </el-dropdown-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DropDownFilterMax",
  computed: {
    ...mapGetters('people',
        [
          'getListMaxRow',
          'getListPerCompanyRow',
          'getCheckQuery',
          'getListQuery',
          'getFilterParamsString',
          'getTypeDisplayContacts',
        ]),
    ...mapGetters('formCheckFilterMax', ['getForm']),
    formQuery: { //todo:check
      set() {
        this.getForm.query = this.getFilterParamsString
      },
      get() {
        return this.getFilterParamsString
      }
    },
    checkAll: {
      set() {
        //  see more:  set method by this.checkboxCheckedChange...
        return true;
      },
      get() {
        return this.getCheckQuery['all'];
      }
    },
    checkMax: {
      set() {
        //  see more:  set method by this.checkboxCheckedChange...
        return true;
      },
      get() {
        return this.getCheckQuery['max'];
      }
    },
    checkPerCompany: {
      set() {
        //  see more:  set method by this.checkboxCheckedChange...
        return true;
      },
      get() {
        return this.getCheckQuery['per_company'];
      }
    },
    listMaxRow: {
      set(value) {
        this.$store.dispatch('people/setListMaxRow', value);
      },
      get() {
        return this.getListMaxRow;
      }
    },
    listPerCompanyRow: {
      set(value) {
        this.$store.dispatch('people/setListPerCompanyRow', value);
      },
      get() {
        return this.getListPerCompanyRow;
      }
    },

    allowSubmit() {
      if (this.submitted) {
        return false;
      }
      if (this.getCheckQuery['all'].length > 0
          || this.getCheckQuery['max'].length > 0
          || this.getCheckQuery['per_company'].length > 0
      ) {
        if (this.getCheckQuery['max'].length > 0) {
          if (this.user.tariff_row > 0) {
            return this.user.tariff_row >= this.getListMaxRow;
          }
        }
        return true
      }
      return false;
    }
  },
  data() {
    return {
      visible: false,
      user: this.$store.getters.user,
      submitted: false,
      warning_message: ''
    }
  },
  methods: {
    formSubmitCheckByFilterMax() {
      this.submitted = true;
      this.$Progress.start();
      this.warning_message = '';
      this.$store.dispatch('formCheckFilterMax/submit')
          .then((response) => {
            this.$Progress.finish();
            this.submitted = false;
            this.$store.dispatch('people/setCurrentPage', 1)
            if (response.data.data.ids.length === 0) {
              // show message
              if (this.getForm.check_per_company && this.getForm.list_per_company) {
                this.warning_message = 'No one contacts found. Try change "contacts per company"';
              } else {
                this.warning_message = 'No one contacts found. Try change condition';
              }
              return false; // don't close modal
            }

            //  Fire.$emit('AfterCreate');
          })
          .catch(() => {
            this.submitted = false;
            this.$Progress.fail();
          });
    },
    checkboxCheckedChange(event, key) {
      let value = '';
      if (event.target.checked) {
        value = event.target.defaultValue
      }
      this.$store.dispatch('people/setCheckByQuery', {key: key, data: [value]});
      return event;
    },
    getTariffName() {
      return this.user.tariff_row;
    }
  }
}
</script>