/**
 *  get diff array from array
 * @param arr1
 * @param arr2
 * @return {*}
 */
const difference = (arr1, arr2) => arr1
    .filter(x => !arr2.includes(x))
    .concat(arr2.filter(x => !arr1.includes(x)));

export default difference
