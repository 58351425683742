import axios from 'axios'
import qs from 'qs'
import Toast from '../plugin/ToastPlugin'

class DownloadFilesService {
    download(filterParams) {
       return   window.location = process.env.VUE_APP_API_URL + 'file-export?' + qs.stringify(filterParams);
    }
    downloadByAxios(filterParams) {
       return axios({
            url: process.env.VUE_APP_API_URL + 'file-export?' + qs.stringify(filterParams),
            method: 'GET',
            responseType: 'blob',
        })
           .then((response) => {
               var fileURL = window.URL.createObjectURL(new Blob([response.data]));
               var fileLink = document.createElement('a');

               fileLink.href = fileURL;
               fileLink.setAttribute('download', 'file.csv');
               document.body.appendChild(fileLink);

               fileLink.click();

               Toast.fire({
                   icon: 'success',
                   title: 'Start download'
               });

           })
           .catch(() => {
               Toast.fire({
                   icon: 'error',
                   title: 'Could not download the file!'
               });

           });
    }
}

export default new DownloadFilesService();
