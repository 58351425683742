import { render, staticRenderFns } from "./ListSavedContactsPage.vue?vue&type=template&id=623c151b&scoped=true&"
import script from "./ListSavedContactsPage.vue?vue&type=script&lang=js&"
export * from "./ListSavedContactsPage.vue?vue&type=script&lang=js&"
import style0 from "./ListSavedContactsPage.vue?vue&type=style&index=0&id=623c151b&prod&scoped=true&lang=css&"
import style1 from "./ListSavedContactsPage.vue?vue&type=style&index=1&id=623c151b&prod&lang=css&"
import style2 from "@/scss/list-saved-page/index.scss?vue&type=style&index=2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "623c151b",
  null
  
)

export default component.exports