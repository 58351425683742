import axios from "axios";
import toastMessage from "../toastMessage";
import store from "../index";
import router from "../../routes";
import qs from "qs";

const actions = {
    createListContacts(_, data) { // save to list
        let promise = axios.post(process.env.VUE_APP_API_URL + "list-contacts", {checked_contacts_ids: data.checked_contacts_ids, list_name_id: data.list_name_id });

        promise.then((response) => {
            store.dispatch('users/getUser');
            toastMessage.fire(response);
        }).catch((response) => {
            store.dispatch('users/getUser');
            toastMessage.fire(response);
        });

        return promise;
    },
    unlockContact(_, data) { // buy/save to list_contacts  can be without list_id
        let promise = axios.post(process.env.VUE_APP_API_URL + "list-contacts/save", {checked_contacts_ids: data.checked_contacts_ids, list_name_id: data.list_name_id });
        promise.then((response) => {
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    countSaved({commit,getters}){

        let promise = axios.get(process.env.VUE_APP_API_URL + 'list-contacts/count-saved?'+ qs.stringify(store.getters['people/getFilterParams']));

        promise.then((response) => {
            commit('updateInfoSavedCount', response.data.data);
            if(getters('infoTotalCount')){
                commit('updateInfoNotSavedCount', getters('infoTotalCount') - response.data.data);
            }

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    counter({commit}){
        let companyId = router.currentRoute.params.companyId

        if (companyId) {
            let promise = axios.get(process.env.VUE_APP_API_URL + 'list-contacts/company/'+ companyId +'/counter?'+ qs.stringify(store.getters['people/getFilterParams']));
            promise.then((response) => {
                commit('updateInfoSavedCount', response.data.data.count_saved);
                commit('updateInfoNotSavedCount', response.data.data.count_not_saved);
                commit('updateInfoTotalCount', response.data.data.count_total);

                if (store.getters["people/getTypeDisplayContacts"] === 'saved') {
                    store.dispatch('people/getCompanyTotal', response.data.data.count_saved)
                }
                if (store.getters["people/getTypeDisplayContacts"] === 'without_saved') {
                    store.dispatch('people/getCompanyTotal', response.data.data.count_not_saved)
                }
                if (store.getters["people/getTypeDisplayContacts"] === 'all') {
                    store.dispatch('people/getCompanyTotal', response.data.data.count_total)
                }
                toastMessage.fire(response);
            }).catch((response) => {
                toastMessage.fire(response);
            });

            return promise;
        }
        let promise = axios.get(process.env.VUE_APP_API_URL + 'list-contacts/counter?'+ qs.stringify(store.getters['people/getFilterParams']));
        promise.then((response) => {
            commit('updateInfoSavedCount', response.data.data.count_saved);
            commit('updateInfoNotSavedCount', response.data.data.count_not_saved);
            commit('updateInfoTotalCount', response.data.data.count_total);

            if (store.getters["people/getTypeDisplayContacts"] === 'saved') {
                store.dispatch('people/getCompanyTotal', response.data.data.count_saved)
            }
            if (store.getters["people/getTypeDisplayContacts"] === 'without_saved') {
                store.dispatch('people/getCompanyTotal', response.data.data.count_not_saved)
            }
            if (store.getters["people/getTypeDisplayContacts"] === 'all') {
                store.dispatch('people/getCompanyTotal', response.data.data.count_total)
            }
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    },
    counterByList({commit}){
        let query = store.getters['listNameContacts/getFilterParams'];
        let promise = axios.get(process.env.VUE_APP_API_URL + 'list-contacts/count-by-list-saved-only?'+ qs.stringify(query));
        promise.then((response) => {
            commit('updateInfoSavedCount', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    checkByOne(_, data) {
        let promise = axios.post(process.env.VUE_APP_API_URL + "contacts/check-by-one", {checked:data.checked, unchecked: data.unchecked });
        promise.then((response) => {
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });

        return promise;
    }
};

const mutations = {
    updateInfoTotalCount(state, data) {
        state.info.total.count = data;
    },
    updateInfoNotSavedCount(state, data) {
        state.info.not_saved.count = data;
    },
    updateInfoSavedCount(state, data) {
        state.info.saved.count = data;
    },

};

const state = {
    info: {
        total: {
            count: 0,
            loading: 0,
        },
        not_saved: {
            count: 0,
            loading: 0,
        },
        saved: {
            count: 0,
            loading: 0,
        },
    }


};

const getters = {
    infoTotalCount(state) {
        return state.info.total.count;
    },
    infoNotSavedCount(state) {
        return state.info.not_saved.count;
    },
    infoSavedCount(state) {
        return state.info.saved.count;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
