import Vue from 'vue';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'admin-lte'
import 'admin-lte/dist/css/adminlte.css'
import lodash from 'lodash' // require('lodash');
import App from './App.vue'

import Vuex from 'vuex';
import router from './routes';
import store from './store';
import i18n from "./i18n";
import 'element-ui/lib/theme-chalk/index.css';
import 'element-ui/lib/index.js';
import Toast from './plugin/ToastPlugin'
import toastMessage from "./store/toastMessage";

import {
  Table,
  TableColumn,
  Select,
  Option,
  OptionGroup,
  Checkbox,
  CheckboxGroup,
  Pagination,
  Popover,
  Dropdown,DropdownItem,DropdownMenu,
  Badge,
  Button,
  Input,
  InputNumber,
  Radio,
  RadioButton,
  RadioGroup,
  Tabs,
  TabPane,
  Image,
  DatePicker,
  Switch,
  Autocomplete,
  Tag,
  Scrollbar,
  Tooltip,
  Drawer,
  Dialog,
  Menu,
  Submenu,
  Slider,
  Collapse,
  CollapseItem,
  Popconfirm,
  Notification,
  Upload,
} from 'element-ui'

// fortawesome https://github.com/FortAwesome/vue-fontawesome
// https://fontawesome.com/search
import { library }  from '@fortawesome/fontawesome-svg-core'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {InlineSvgPlugin} from 'vue-inline-svg'

library.add(far, fas, fab)

import VueProgressBar from 'vue-progressbar'
import InlineSvg from 'vue-inline-svg';
import moment from 'moment';
import {Form, HasError, AlertError} from 'vform';
import Swal from 'sweetalert2';

import axios from "axios";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// import qs from "qs";

Vue.config.productionTip = false;
window._ = lodash
window.Vue = Vue;

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
// require('./bootstrap');

axios.interceptors.request.use(function (config) {
  config.headers.common = {
    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }

  return config
})

const instance = axios.create({
  headers: {'Authorization': `Bearer ${localStorage.getItem('authToken')}`}
  // baseURL: 'http://dev.salesar.io/'
});

Form.axios = instance;

// const Toast = Swal.mixin({
//   toast: true,
//   position: 'top-end',
//   showConfirmButton: false,
//   timer: 3000,
//   timerProgressBar: true,
//   didOpen: (toast) => {
//     toast.addEventListener('mouseenter', Swal.stopTimer);
//     toast.addEventListener('mouseleave', Swal.resumeTimer);
//   }
// });


// window.Swal = Swal;
window.Toast = Toast;
// window.Form = Form;

// Vue.use(Toast);
// Vue.use(Swal);
// Vue.use(Form);
Vue.use(Vuex);
Vue.use(InlineSvgPlugin);
Vue.use(VueProgressBar, {
  color: 'rgb(143, 255, 199)',
  failedColor: 'red',
  height: '3px'
});

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));
Vue.component(Table.name, Table);
Vue.component(TableColumn.name, TableColumn);
Vue.component(Select.name, Select);
Vue.component(Option.name, Option);
Vue.component(OptionGroup.name, OptionGroup);
Vue.component(Checkbox.name, Checkbox);
Vue.component(CheckboxGroup.name, CheckboxGroup);
Vue.component(Pagination.name, Pagination);
Vue.component(Button.name, Button);
Vue.component(Badge.name, Badge);
Vue.component(Radio.name, Radio);
Vue.component(RadioButton.name, RadioButton);
Vue.component(RadioGroup.name, RadioGroup);
Vue.component(InputNumber.name, InputNumber);
Vue.component(Input.name, Input);
Vue.component(Popover.name, Popover);
Vue.component(Dropdown.name, Dropdown);
Vue.component(DropdownItem.name, DropdownItem);
Vue.component(DropdownMenu.name, DropdownMenu);
Vue.component(Tabs.name, Tabs);
Vue.component(TabPane.name, TabPane);
Vue.component(Image.name, Image);
Vue.component(DatePicker.name, DatePicker);
Vue.component(Switch.name, Switch);
Vue.component(Autocomplete.name, Autocomplete);
Vue.component(Tag.name, Tag);
Vue.component(Tooltip.name, Tooltip);
Vue.component(Drawer.name, Drawer);
Vue.component(Dialog.name, Dialog);
Vue.component(Menu.name, Menu);
Vue.component(Submenu.name, Submenu);
Vue.component(Scrollbar.name, Scrollbar);
Vue.component(HasError.name, HasError);
Vue.component(AlertError.name, AlertError);
Vue.component(Slider.name, Slider);
Vue.component(Collapse.name, Collapse);
Vue.component(CollapseItem.name, CollapseItem);
Vue.component(Popconfirm.name, Popconfirm);
Vue.component(Notification.name, Notification);
Vue.component(Upload.name, Upload);

Vue.prototype.$notify = Notification;
// Font awesome
Vue.component('font-awesome-icon', FontAwesomeIcon)

// Components
Vue.component('pagination', require('laravel-vue-pagination'));

Vue.component('inline-svg', InlineSvg);

// Filter Section
Vue.filter('myDate', function (created) {
  return moment(created).format('MMMM Do YYYY');
});

Vue.filter('yesno', value => (value ? '<font-awesome-icon icon="fas fa-check" class="green"/>' : '<font-awesome-icon icon="fas fa-times" class="red"/>'));
// end Filter

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');




// Add a 401 response interceptor
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    //todo: handle error: inform user, go to login, etc
    Swal.fire({
      title: 'Unauthorized',
      text: 'Your session expired please log in.',
      showCancelButton: false,
      confirmButtonColor: '#2778c4',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'OK'
    }).then(() => {
      window.location = '/login';
    });
    // this.$router.replace({ name: "login" })

  } else if (500 === error.response.status) {
    Toast.fire({
      icon: 'error',
      title: toastMessage.fire(error)
    });
  } else if (404 === error.response.status) {
    Toast.fire({
      icon: 'error',
      title: 'Page not found 404 "' + error.response.config.url + '" Ask administrator'
    });
  } else if (403 === error.response.status) {
    Toast.fire({
      icon: 'error',
      title: 'Forbidden "' + error.response.config.url + '" Ask administrator'
    });
  } else {
    return Promise.reject(error);
  }
});
