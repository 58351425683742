import uaLocale from 'element-ui/lib/locale/lang/ua'

const ua ={
    ...uaLocale,
    simple:{
        test: 'Привіт Світ'
    },
    checkbox: {
        default: 'за замовчуванням',
    },
    filters:{
        label:{
            control_panel_user: 'Користувач',
            control_panel_tariff: 'Тариф',
            control_panel_tariff_price: 'Ціна',
            control_panel_tariff_visible: 'Видимість',
            control_panel_tariff_active: 'Активність',
            control_panel_tariff_started_at: 'Стартова дата',
            control_panel_date: 'Дата створення',
            control_panel_expired_at: 'Дата закінчення',
            control_panel_started_at: 'Стартова дата',
            control_panel_status: 'Стату',
            control_panel_amount: 'Ціна',
            credit_logs_user_tariff: ' Тариф Користувача',
            credit_logs_list_name_id: 'Ліст нейм',
            company_country: 'Країна компанії',
            company_state_region: 'Компанія державний штат/регіон',
            company_city: 'Місто компанії',
            company_geo: 'Місцезнаходження компанії',
            industry: 'Промисловість',
            employees: 'Співробітники',
            title: 'Назви роботи',
            contacts_location_country: 'Країна розташування контактів',
            contacts_location_state: 'Штат/регіон розташування контактів',
            contacts_location_city: 'Місто місцезнаходження контактів',
            contacts_geo: 'Місцезнаходження контактів',
            ecommerce_scheme: 'Схема електронної комерції',
            technologies: 'Технології',
            annual_revenue: 'Річний дохід',
            email_status: 'Статус електронної пошти',
            year_founded: 'Рік засновання',
            reason: 'Відповідь/reason',
            created_at: 'Дата створення',
            fail_attributes: 'Поле з помилкою',
            alias: 'Псевдонім',
            company: 'Компанія'
        },
        checkbox: {
            options: {
                by_first: 'спочатку',
                strict: 'строгий',
                any: 'будь-який',
                Null: 'Відсутній',
                NotNull: 'Не нульовий',
                valid: 'Валідний',
                in_valid: 'Не Валідний',
                name_by_id: 'Назва через ід',
                invalid_name_by_id: 'Невалідна назва через ід',
                not_empty_filter_id: 'не пустий ід',
                empty_filter_id : 'пустий ід'
            }
        },
        search:{
            label: {
                search: 'Пошук',
                id: 'id',
                company: 'Компанія',
                website: 'Веб-сайт',
                corporate_email: 'Корпоративна електронна пошта',
                first_name: "Ім'я",
                last_name: 'Прізвище',
                personal_phone_number: 'Особистий номер телефону',
                company_phone: 'Телефон компанії',
            }
        }
    },
    page: {
        contacts:{
            title: 'Список контактів'
        },
        company: {
            title: 'Інформація по контактах'
        }
    },
    nav:{
        contacts: 'Люди',
        list: 'Лист',
        setting: 'Налаштування',
        services: 'Сервіси',
        import: 'Імпорт',
        import_saved: 'Збережено',
        import_fails: 'Помилкові',
        users: 'Користувачі',
        leads: 'Ліди',
        role: 'Роль',
        permission: 'Дозвіл',
        sign_out: 'Вийти з аккаунта',
        profile: 'Профіль',

    }
};

export default ua;

