import axios from "axios";
import toastMessage from "../toastMessage";
import router from "../../routes";
import qs from "qs";
import {Form} from 'vform';

const actions = {
    getList({commit, getters}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + "file/import/files?" + qs.stringify({page: getters.getPage}));

        promise.then((response) => {
            commit('updateImportFiles', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    upload({commit}, file) {
        let formData = new Form();
        formData.append("file", file);

        return axios.post(process.env.VUE_APP_API_URL + "file/upload", formData, {
            headers: {
                "Content-Type": "multipart/form-data"
            },
            onUploadProgress: function (progressEvent) {
                commit('updateUploadProgress',
                    parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100)));
            }
        });
    },
    acceptImport({commit, getters}) {
        let params = {
            import_file_id: getters.getPreview.import_file_id,
            file_name: getters.getPreview.file_name,
            delimiter: getters.getPreview.delimiter,
            extension: getters.getPreview.extension,
        };

        let promise = axios.post(process.env.VUE_APP_API_URL + "file/import", params);

        promise.then(() => {
            commit('updateStatus', 'import_process');
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    preview({commit}, id) {

        let promise = axios.post(process.env.VUE_APP_API_URL + "file/import/preview/" + id);

        promise.then((response) => {
            commit('updatePreview', response.data.data)
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    setFiles({commit}, data) {
        commit('updateFiles', data)
    },
    setPreviewEmpty({commit}) {
        commit('updatePreviewEmpty')
    },
    setStatus({commit}, data) {
        commit('updateStatus', data)
    },
    setUploadProgress({commit}, data) {
        commit('updateUploadProgress', data)
    },
    setMessage({commit}, data) {
        commit('updateMessage', data)
    },
    setStatesFromQuery({commit}, page) {
        if(page){
            commit('updatePage', page)
        }
    },
    setCurrentPage({commit, getters}, page){
        commit('updatePage', page);
        let queryParams = {
            page: getters.getPage,
        };

        commit('historyPush', queryParams);
    }
};

const mutations = {
    updateImportFiles(state, data) {
        state.import_files = data;
    },
    historyPush(state, params) {
        router.push({name:'ImportSaved' ,query: params}, () => {});
    },
    updateFiles(state, data) {
        state.files = data;
    },
    updatePreview(state, data) {
        state.preview = data;
    },
    updatePreviewEmpty(state) {
        state.preview = state.previewEmpty;
    },
    updateStatus(state, data) {
        state.status = data;
    },
    updateUploadProgress(state, data) {
        state.upload_progress = data;
    },
    updateMessage(state, data) {
        state.message = data;
    },
    updatePage(state, data) {
        state.import_files.current_page = data;
    }
};

const state = {
    files: null,
    status: 'new',
    message: '',
    upload_progress: 0,
    preview: {
        columns: [],
        count_preview: 0,
        count_total: 0,
        delimiter: '',
        extension: '',
        file_name: '',
        rows: [],
        rows_invalid: [],
        rows_valid: [],
        valid_count_columns: 0,
        import_file_id: '',
    },
    previewEmpty: {
        columns: [],
        count_preview: 0,
        count_total: 0,
        delimiter: '',
        extension: '',
        file_name: '',
        rows: [],
        rows_invalid: [],
        rows_valid: [],
        valid_count_columns: 0,
        import_file_id: '',
    },
    import_files: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
    },

};

const getters = {
    getImportFiles(state) {
        return state.import_files;
    },
    getPage() {
        return state.import_files.current_page;
    },
    getFiles(state) {
        return state.files;
    },
    getPreview(state) {
        return state.preview;
    },
    getStatus(state) {
        return state.status;
    },
    getUploadProgress(state) {
        return state.upload_progress;
    },
    getMessage(state) {
        return state.message;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
