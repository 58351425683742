<template>
    <div>
<!--        {{from}} - {{to}} of {{total}}-->
        <div class="block">
            <el-pagination
                :current-page.sync="page"
                :page-sizes="[20, 50, 100]"
                :page-size="page_size"
                layout="sizes, prev, pager, next, jumper"
                :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";

    export default {
        name: "pagination-list-name-contact",
        computed: {
            ...mapGetters('listNameContacts', ['getPage']),
            ...mapGetters('listContacts', [ 'infoSavedCount']),
            ...mapGetters('listName', ['getControlTabsSelected']),
            total: {
                get() {
                    return this.infoSavedCount;
                }
            },
            from: {
                get() {
                    if (this.getPage > 1) {
                        return this.getPage * this.page_size - this.page_size
                    } else {
                        return 1 > this.total ? this.total : 1;
                    }
                },
            },
            to: {
                get() {
                    if (this.getPage > 1) {
                        return this.getPage * this.page_size <= this.total ? this.getPage * this.page_size : this.total
                    } else {
                        return this.page_size > this.total ? this.total : this.page_size;
                    }
                },
            },
            page: {
                get() {
                    return Number.parseInt(this.getPage)
                },
                set(value) {
                    this.$store.dispatch('listNameContacts/setCurrentPage', Number.parseInt(value));
                }
            },
        },
        data() {
            return {
                page_size: 20
            }
        }
    }
</script>

<style scoped>

</style>
