import axios from "axios";
import toastMessage from "../toastMessage";
import router from "../../routes";
import utils from "../../utils";
import store from "../index";
import qs from "qs";
import {Form} from 'vform';

const actions = {
    getCompanyCard({commit, getters}) {
        return axios.get(process.env.VUE_APP_API_URL + 'company/' + getters.getId)
            .then((response) => {
                commit('updateCompany', response.data.data.company);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },
    getContacts({ getters}) {
        return axios.get(process.env.VUE_APP_API_URL + 'company/' + getters.getId + '/contacts?' + qs.stringify(getters.getFilterParams))
            .then((response) => {
                store.dispatch('people/setContacts', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },
    putCompany({commit}, vForm = new Form()) {
        let promise = vForm.put(process.env.VUE_APP_API_URL + 'company/' + vForm.id);
        promise.then((response) => {
            commit('updateCompany', response.data.data);
            // commit('findReplacePeople', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    setStatesFromParams({commit}, id) {
        if (id) {
            commit('setId', id);
        }
    },
    setModalContactsEdit({commit}, val) {
        commit('updateModalContactsEdit', val);
    },
    setModalContactsData({commit}, data) {
        commit('updateModalContactsData', data);
    },
    setFindReplacePeople({commit}, data) {
        commit('findReplacePeople', data);
    },
    setPage({commit}, data) {
        commit('updatePage', data);
    },
    setTotal({commit}, data) {
        commit('updateTotal', data);
    },
    setContactsPage({commit, getters}, page) {
        if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1) {
            commit('updateContactsPage', page);
            commit('historyPush', getters.getFilterParams);
        }
    },

    setStatesContactsFromQuery({commit}, params) {
        let  filters = params.filters;
        let  checkbox = params.checkbox;
        let  search = params.search;
        let  listNameId = params.list_name_id;
        let  typeDisplayContacts = params.type_display_contacts;
        let  page = params.page;
        let  total = params.total;

        if(page){
            commit('updatePage', page)
        }
        if(total){
            commit('updateTotal', total)
        }

        if(checkbox){
            store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
        }else{
            store.dispatch('filters/setClearCheckboxesValues', checkbox);
        }

        if(filters){
            store.dispatch('filters/setUpdateFiltersValues', filters);
        }else{
            store.dispatch('filters/setClearFiltersValues');
        }

        if(search){
            store.dispatch('filters/setUpdateSearchesValues', search);
        }else{
            store.dispatch('filters/setClearSearchesValues', search);
        }

        if(listNameId){
            store.dispatch('listName/setListNameIdSelected', listNameId);
        }else{
            store.dispatch('listName/clearListNameIdSelected', listNameId)
        }

        if(typeDisplayContacts){
            commit('updateTypeDisplayContacts', typeDisplayContacts);
        }

    },

    setCheckedContactsIdsClear({commit}){
        commit('updateCheckedContactsIds', []);
    },
    setCheckedContactsIdsOnce({commit}, data){
        commit('updateCheckedContactsIds', utils.mergeAndUniq(data.bulk_ids, data.manual_ids));
    },
    setCheckedContactsIds({commit,getters}, data){
        let newChecked = [];
        let newUnChecked = [];
        let isUncheckOnCurrentPage = false;
        if(data.length === 0){
            isUncheckOnCurrentPage = true;
        }

        if((getters.getCheckedContactsIds.length  === getters.getIdsOptions.length && getters.getCheckAllOnPage && !getters.getIsIndeterminate)){
            //if all checked
            newUnChecked = getters.getIdsOptions.diff(data);
            newChecked = data.diff(getters.getCheckedContactsIds);
        }else if(isUncheckOnCurrentPage){
            data =  getters.getIdsOptions.diff(data)
            newUnChecked = getters.getIdsOptions;
        }else{
            //concat / merge if allCheck (with current)
            if(getters.getCheckAllOnPage){
                data = utils.mergeAndUniq(data, getters.getCheckedContactsIds)
            }
            newChecked = data.diff(getters.getCheckedContactsIds);
            newUnChecked = getters.getCheckedContactsIds.diff(data)
        }

        if(newChecked.length){
            commit('updateCheckedContactsIds',  utils.mergeAndUniq(getters.getCheckedContactsIds,newChecked));
        }
        if(newUnChecked.length){
            commit('updateCheckedContactsIds', getters.getCheckedContactsIds.diff(newUnChecked));
        }

        if(newChecked.length || newUnChecked.length){
            store.dispatch('listContacts/checkByOne', {checked:newChecked, unchecked: newUnChecked }); //send to backend // diff only new
        }

    },
    setUpdateCountTempList({commit}, data){
        commit('updateCountTempList', data);
    },
    setUpdateIdTempList({commit}, data){
        commit('updateIdTempList', data);
    },
    unshiftCheckedContactsIds({commit,getters}){
        commit('findAndHideSavedPeople', getters.getCheckedContactsIds);
        commit('updateCheckedContactsIds', []);
        commit('updateIsIndeterminate', false);
        commit('updateCheckAllOnPage', false);
    },
    setCheckAllOnPage({commit}, data){
        commit('updateCheckAllOnPage', data);
    },
    setCheckByQuery({commit} , params = {key:'', data: {}}){
        commit('updateCheckByQuery', {key: params.key, data: params.data});
    },
    setIsIndeterminate({commit}, data){
        commit('updateIsIndeterminate', data);
    },
    setListQuery({commit}, data){
        commit('updateListQuery', data);
    },
    setListMaxRow({commit}, data){
        commit('updateListMaxRow', data);
    },
    setListPerCompanyRow({commit}, data){
        commit('updateListPerCompanyRow', data);
    },

    setTypeDisplayContacts({commit,getters}, data){
        commit('updateTypeDisplayContacts', data);

        clearTimeout(store.state.timers.typeDisplay);
        store.state.timers.typeDisplay = setTimeout(() => {
            if((router.currentRoute.query.type_display_contacts !== undefined && router.currentRoute.query.type_display_contacts !== data)
                || (router.currentRoute.query.type_display_contacts === undefined  &&  data !== undefined)){
                commit('updatePage', 1);
                commit('updateTotal', 0);
                commit('historyPush', getters.getFilterParams);
            }
        }, 800); //ms
    },
};

const state = {
    id: null,
    company: null,
    page: 1,
    type_display_contacts: 'all',

    contacts: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        per_page: 0,
    },
    total: 0,
    modal_contacts_edit: false,
    modal_contacts_data: null,

    // list
    list:{
        idTempList: null,
        countTempList: 0,
        idsOptions: [],
        checkedContactsIds: [],
        isIndeterminate: false,
        checkAllOnPage: false,

        check_query: {
            all: false,
            max: false,
            per_company: false
        },
        maxRow: 25,// default
        perCompanyRow: 3,// default
        query: '',
    }
};

const mutations = {
    setId(state, data) {
        state.id = data
    },
    updatePage(state, data) {
        state.page = data;
    },
    updateTotal(state, data) {
        state.total = data
    },
    updateContactsPage(state, data) {
        state.contacts.current_page = data;
    },
    updateCompany(state, data) {
        state.company = data;
    },
    updateContacts(state, data) {
        state.contacts = data;
    },
    findAndHideSavedPeople(state, ids) {
        state.contacts.data = state.contacts.data.filter(item => {
            return ids.indexOf(item.id ) < 0
        });
    },
    findReplacePeople(state, data) {
        const item = state.contacts.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    updateModalContactsEdit(state, data){
        state.modal_contacts_edit = data;
    },
    updateModalContactsData(state, data){
        state.modal_contacts_data = data;
    },
    // - list
    updateIdsOptions(state){
        state.list.idsOptions = utils.pluck(state.contacts.data, 'id')
    },
    updateCheckedContactsIds(state,data){
        state.list.checkedContactsIds = data
    },
    updateCheckAllOnPage(state,data){
        state.list.checkAllOnPage = data
    },
    updateCheckByQuery(state, params = {key:'', data: {}}) {
        //switch all clear max
        if(params.key === 'all'){
            state.list.check_query['max'] = [];
        }
        //switch all clear all
        if(params.key === 'max'){
            state.list.check_query['all'] = [];
        }

        let currentVal = state.list.check_query[params.key];
        state.list.check_query[params.key] = utils.equals(currentVal, params.data) ? [] : params.data
    },
    updateIsIndeterminate(state,data){
        state.list.isIndeterminate = data
    },
    updateListQuery(state,data){
        state.list.query = data
    },
    updateListMaxRow(state,data){
        state.list.maxRow = data
    },
    updateListPerCompanyRow(state,data){
        state.list.perCompanyRow = data
    },
    updateIdTempList(state,data){
        state.list.idTempList = data
    },
    updateCountTempList(state,data){
        state.list.countTempList = data
    },
    updateTypeDisplayContacts(state,data){
        // state.total= 0;
        // state.page = 1; // need !!!
        state.type_display_contacts = data
    },
    historyPush(state, params) {
        clearTimeout(store.state.timers.historyPush);
        store.state.timers.historyPush = setTimeout(() => {
            router.push({query: qs.parse(params)}, () => {
            });
        }, 10); //ms
    }
};

const getters = {
    getId(state) {
        return state.id;
    },
    getCompany(state) {
        return state.company;
    },
    getContacts(state) {
        return state.contacts;
    },
    getContactsTotal(state) {
        return state.total;
    },
    getModalContactsEdit(state) {
        return state.modal_contacts_edit;
    },
    getModalContactsData(state) {
        return state.modal_contacts_data;
    },
    getPage(state) {
        return state.page;
    },
    getContactsPage(state) {
        return state.contacts.current_page;
    },

    //Check system
    allPeople(state) {
        return state.contacts;
    },
    getFilterParams(){
        return store.getters["people/getFilterParams"]
    },
    getFilterParamsString(state, getters){
        return  qs.stringify(getters.getFilterParams); // warning send with tab ".../t" if parsing on backend
    },
    getIdsOptions(state){
        return state.list.idsOptions;
    },
    getCheckedContactsIds(state){
        return state.list.checkedContactsIds;
    },
    getCheckAllOnPage(state){
        return state.list.checkAllOnPage;
    },
    getCheckAllByQuery(state){
        return state.list.check_query['all'];
    },
    getCheckMaxByQuery(state){
        return state.list.check_query['max'];
    },
    getCheckQuery(state){
        return state.list.check_query;
    },
    getIsIndeterminate(state){
        return state.list.isIndeterminate;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
