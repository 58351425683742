

const actions = {
    setSearch(commit, data) {
        commit('setSearch', data);
    }
};

const mutations = {
    updateSearch(state, data) {
        // state.people = data
        state.search.id.list = data.id;
        state.search.id.options = data.id.slice(0, 300);

        state.search.company.list = data.company;
        state.search.company.options = data.company.slice(0, 300);

        state.search.website.list = data.website;
        state.search.website.options = data.website.slice(0, 300);

        state.search.website.list = data.website;
        state.search.website.options = data.website.slice(0, 300);

        state.search.corporate_email.list = data.corporate_email;
        state.search.corporate_email.options = data.corporate_email.slice(0, 300);

        state.search.first_name.list = data.first_name;
        state.search.first_name.options = data.first_name.slice(0, 300);

        state.search.last_name.list = data.last_name;
        state.search.last_name.options = data.last_name.slice(0, 300);

        state.search.personal_phone_number.list = data.personal_phone_number;
        state.search.personal_phone_number.options = data.personal_phone_number.slice(0, 300);

        state.search.company_phone.list = data.company_phone;
        state.search.company_phone.options = data.company_phone.slice(0, 300);

    },
};

const state = {
    search: {
        id:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        company:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        website:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        corporate_email:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        first_name:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        last_name:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        personal_phone_number:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        company_phone:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
    },
};

const getters = {
    search(state) {
        return state.search;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
