<template>
    <el-radio-group v-model="type_display_contacts" size="small">
        <el-radio-button label="all">All <span class="medium">{{formatedMethod(infoTotalCount)}}</span></el-radio-button>
        <el-radio-button label="without_saved">New <span class="medium">{{formatedMethod(infoNotSavedCount)}}</span></el-radio-button>
        <el-radio-button label="saved">Saved <span class="medium">{{infoSavedCount}}</span></el-radio-button>
    </el-radio-group>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name: "SwitchDisplayCompanies",
        computed: {
            ...mapGetters('companyList', ['getTypeDisplayContacts']),
            ...mapGetters('listCompanies', ['infoTotalCount', 'infoNotSavedCount' , 'infoSavedCount']),
            type_display_contacts: {
                get() {
                    return this.getTypeDisplayContacts;
                },
                set(value) {
                    this.$store.dispatch('companyList/setTypeDisplayContacts', value);
                },
            },
        },
    methods: {
      formatedMethod(num) {
        if (num >= 1000000) {
          return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        }
        if (num >= 100000) {
          return (num / 100000).toFixed(1).replace(/\.0$/, '') + 'K';
        }
      }
    }
    }
</script>

<style scoped>

</style>
