import axios from "axios";
import toastMessage from "../toastMessage";
import store from "../index";
import router from "../../routes";
import qs from "qs";


const actions = {
    getListNameContacts({commit, getters}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + "list-name-contacts?" + qs.stringify(getters.getFilterParams));

        promise.then((response) => {
            commit('updatePeople', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    setCurrentPage({commit, getters}, page){
        if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1){
            commit('updatePage', page);
            commit('historyPush', getters.getFilterParams);
        }
    },
    setListNameIdSelected({commit, getters}, value) {
        if(router.currentRoute.query.list_name_id === undefined
            || router.currentRoute.query.list_name_id !== undefined && Number(router.currentRoute.query.list_name_id) !== Number(value)){
            store.dispatch('listName/setListNameIdSelected', value);
            commit('updatePage', 1);
            commit('historyPush', getters.getFilterParams);
        }
    },
    setListNameIdInContactsPage({commit, getters}, value) {
        if(router.currentRoute.query.list_name_id === undefined
            || router.currentRoute.query.list_name_id !== undefined && Number(router.currentRoute.query.list_name_id) !== Number(value)){
            store.dispatch('listName/setListNameIdSelected', value);
            commit('updatePage', 1);
            commit('historyPush', getters.getFilterParams);
        }
    },
    setStatesFromQuery({commit}, params) {
        let  filters = params.filters;
        let  checkbox = params.checkbox;
        let  search = params.search;
        let  listNameId = params.list_name_id;
        // let  typeDisplayContacts = params.type_display_contacts;
        let  page = params.page;
        let  total = params.total;

        if(page){
            commit('updatePage', page)
        }
        if(total){
            // commit('updateTotal', total)
        }

        if(checkbox){
            store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
        }else{
            store.dispatch('filters/setClearCheckboxesValues', checkbox);
        }

        if(filters){
            store.dispatch('filters/setUpdateFiltersValues', filters);
        }else{
            store.dispatch('filters/setClearFiltersValues');
        }

        if(search){
            store.dispatch('filters/setUpdateSearchesValues', search);
        }else{
            store.dispatch('filters/setClearSearchesValues', search);
        }

        if(listNameId){
            store.dispatch('listName/setListNameIdSelected', listNameId);
        }else{
            store.dispatch('listName/clearListNameIdSelected', listNameId);
        }

        // if(typeDisplayContacts){
        //     commit('updateTypeDisplayContacts', typeDisplayContacts);
        // }

    },
    setEventFilter({commit, getters}){
        commit('updatePage', 1);
        // commit('updateTotal', 0);
        commit('historyPush', getters.getFilterParams);
    }
};

const mutations = {
    // - router
    historyPush(state, params) {
        clearTimeout(store.state.timers.historyPush);
        store.state.timers.historyPush = setTimeout(() => {
            router.push({name:'ListSavedContactsPage', query: qs.parse(params)}, () => {});
        }, 10); //ms
    },
    updatePage(state, data) {
        state.page = data
    },
    // - List by Name of People
    updatePeople(state, data) {
        state.people = data;
    },

};

const state = {
    page: 1,
    people: {data: []},
    type_display_contacts: 'saved',
    list:{
        idsOptions: [],
        checkedContactsIds: [],
        isIndeterminate: false,
        checkAllOnPage: false,
    }
};

const getters = {
    allPeople(state) {
        return state.people;
    },
    getPage(state) {
        return state.page;
    },
    getFilterParams(state, getters){
        return {
            // total: getters.getTotal,
            page: getters.getPage,
            filters: store.getters['filters/filtersValues'],
            checkbox: store.getters['filters/checkboxValues'],
            search: store.getters['filters/searchValues'],
            list_name_id: store.getters['listName/listNameIdSelected'],
            // type_display_contacts: getters.getTypeDisplayContacts,
        }
    },
    getIdsOptions(state){
        return state.list.idsOptions;
    },
    getCheckedContactsIds(state){
        return state.list.checkedContactsIds;
    },
    getCheckAllOnPage(state){
        return state.list.checkAllOnPage;
    },
    getIsIndeterminate(state){
        return state.list.isIndeterminate;
    },
    getTypeDisplayContacts(state){
        return state.type_display_contacts;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
