import axios from "axios";
import toastMessage from "../toastMessage";
import store from "../index";

const actions = {
    usersTimeZones({commit}) {
        return axios.get(process.env.VUE_APP_API_URL + 'user/timezones')
            .then((response) => {
                commit('updateUsersTimeZones', response.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },
    async searchUsers({commit}, textSearch) {
        await axios.get(process.env.VUE_APP_API_URL + 'tariff/search/user', {params: {queryString: textSearch}})
            .then(res => {
                commit('updateSearchUsersOptions', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    existSearchUser({commit}, user) {
        commit('updateSearchUsersOptions', [user]);
    },

    getUser(){
        let promise = axios.get(process.env.VUE_APP_API_URL + "user"); // current
        promise.then((response) => {
            store.commit('setUser', response.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
};

const state = {
    timezones: null,
    searchUsers: {
        options: [],
        value: [],
        list: [],
        loading: false,
    },
};

const mutations = {
    updateSearchUsersOptions(state, data) {
        state.searchUsers.options = data;
        state.searchUsers.list = data;
    },
    updateUsersTimeZones(state, data) {
        state.timezones = data;
    }
};

const getters = {
    getUsersTimeZones(state) {
        return state.timezones;
    },
    getSearchUsers(state) {
        return state.searchUsers;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
