<template>
  <section>
    
  </section>
</template>

<script>
export default {
  name: "content-header",
};
</script>

<style scoped></style>
