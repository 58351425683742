import toastMessage from "../toastMessage";
import store from "../index";
import {Form} from 'vform';

const actions = {
    saveUser(_, vForm = new Form()){
        let promise =  vForm.put(process.env.VUE_APP_API_URL + "profile/user")
        promise.then((response) => {
            store.dispatch('setUser', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    changeUserPassword(_, vForm = new Form()){
        let promise =  vForm.put(process.env.VUE_APP_API_URL + "profile/password/reset")
        promise.then((response) => {
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
};
const state = {

};
const mutations = {

};
const getters = {

};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}