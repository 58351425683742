import { render, staticRenderFns } from "./pagination-company.vue?vue&type=template&id=8b222720&scoped=true&"
import script from "./pagination-company.vue?vue&type=script&lang=js&"
export * from "./pagination-company.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8b222720",
  null
  
)

export default component.exports