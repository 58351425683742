import vue from "vue"
import locale from "element-ui/lib/locale"
import vuei18n from "vue-i18n"
import messages from "./langs"
import store from "../store"


vue.use (vuei18n);
const i18n=new vuei18n ({
    locale: store.getters.lang,
    messages,
});
locale.i18n ((key, value) =>i18n.t (key, value));

export default i18n
