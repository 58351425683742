import toastMessage from "../toastMessage";
import axios from 'axios';

const actions = {
    getLoadSearchList({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + "load-search?");

        promise.then((response) => {
            response.data.data.current_page = getters.getPage // skip if already next page // fixed infinity reload event by changed current_page

            commit('updateLoadSearch', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    }
};

const state = {
    loadSearch: null,
};

const mutations = {
    updateLoadSearch(state, data) {
        state.loadSearch = data;
    },
};

const getters = {
    getLoadSearch() {
        return state.loadSearch;
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
