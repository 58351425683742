import moment from "moment-timezone";
import store from '../../store/index';

const formatDate = (value, hours) => {
    if(!value){
        return value;
    }
    if (hours) {
        if(store.getters.user.timezone){
            return moment(value).parseZone().tz(store.getters.user.timezone).format(`YYYY-MM-DD`);
        }else{
            return moment(value).parseZone().format(`YYYY-MM-DD`);
        }

    }
    if(store.getters.user.timezone){
        return moment(value).parseZone().tz(store.getters.user.timezone).format(`YYYY-MM-DD HH:mm`);
    }else{
        return moment(value).parseZone().format(`YYYY-MM-DD HH:mm`);
    }

}

export default formatDate;
