import utils from "../../utils";
import { Notification } from 'element-ui';

class toastMessage {

    static defaultErrorMessage = 'Some error occured! Please try again';
    static fire(response) {
        if(typeof response === "undefined" || !response ||  typeof response.data === "string"){
            Notification({
                title: 'error', // 'Unlocked', todo title
                message: this.defaultErrorMessage,
                duration: 3000,
                type: 'error',
                customClass: 'notification error', // todo style
            });
        }
        if(response.data){
            response.response  = response.data
        }
        if(response.response && this.getMessage(response)){
            Notification({
                title: this.getStatus(response), // 'Unlocked', todo title
                message: this.getMessage(response),
                duration: 3000,
                type: this.getStatus(response),
                customClass: 'notification success', // todo style
            });
        }
    }

    static getMessage(response) {
        if ( Object.hasOwnProperty.call(response.response, 'message')) {
            return response.response.message;
        } else if (Object.hasOwnProperty.call(response.response, 'data') && Object.hasOwnProperty.call(response.response.data, 'message')) {
            return response.response.data.message;
        } else {
            return this.defaultErrorMessage;
        }
    }

    static getStatus(response) {
        if (utils.isSuccessResponse(response)) {
            return 'success';
        } else {
            return 'error';
        }
    }
}

export default toastMessage
