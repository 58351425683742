<template>
  <div>
    <div class="top-main-list-page">
      <div class="top-main-list-page_prev">
        <router-link to="/list-saved-contacts">
          <inline-svg :src="require('@/assets/images/list-prev.svg')"/>
          <p>Back to lists</p>
        </router-link>
      </div>
      <div class="top-main-list-page_tittle">
        <inline-svg :src="require('@/assets/images/header/list-icon.svg')"/>
        <p>{{ getTitle() }}</p>
      </div>
      <div class="top-main-list-page_button-group">
        <button>Move to list</button>
        <button>
          <inline-svg :src="require('@/assets/images/save.svg')"/>
          Export
        </button>
      </div>
    </div>
    <div class="center-main-list-page">
      <div class="border">

        <div class="table-responsive">
          <table>
            <thead>
            <tr>
              <th class="checkbox list overflow-visible checkbox-col">
                <el-checkbox-group v-model="checked">
                  <el-checkbox v-model="checked"></el-checkbox>
                </el-checkbox-group>
              </th>
              <th v-if="user.can['contacts_saved.field_company']">Company</th>
              <th v-if="user.can['contacts_saved.field_website']">Website</th>
              <th v-if="user.can['contacts_saved.field_company_country']">Company Country</th>
              <th v-if="user.can['contacts_saved.field_company_state_region']">Company State or Region</th>
              <th v-if="user.can['contacts_saved.field_company_city']">Company City</th>
              <th v-if="user.can['contacts_saved.field_company_address']">Company Address</th>
              <th v-if="user.can['contacts_saved.field_industry']">Industry</th>
              <th v-if="user.can['contacts_saved.field_employees']">Number of employees</th>
              <th v-if="user.can['contacts_saved.field_full_name']">Full name</th>
              <th v-if="user.can['contacts_saved.field_title']">Title</th>
              <th v-if="user.can['contacts_saved.field_corporate_email']">Corporate email</th>
              <th v-if="user.can['contacts_saved.field_personal_email']">Personal email</th>
              <th v-if="user.can['contacts_saved.field_personal_phone_number']">Personal phone number</th>
              <th v-if="user.can['contacts_saved.field_company_phone']">Company Phone</th>
              <th v-if="user.can['contacts_saved.field_technologies']">Technologies</th>
              <th v-if="user.can['contacts_saved.field_annual_revenue']">Annual Revenue</th>
              <th v-if="user.can['contacts_saved.field_specialties']">Specialties</th>
              <th v-if="user.can['contacts_saved.field_contacts_location_country']">Contact's location/Country</th>
              <th v-if="user.can['contacts_saved.field_contacts_location_state']">Contact's location/State or Region</th>
              <th v-if="user.can['contacts_saved.field_contacts_location_city']">Contact's location/City</th>
              <th v-if="user.can['contacts_saved.field_ecommerce_scheme']">B2B/B2C</th>
              <th v-if="user.can['contacts_saved.field_total_funding']">Total funding</th>
              <th v-if="user.can['contacts_saved.field_last_funding_type']">Last funding type</th>
              <th v-if="user.can['contacts_saved.field_last_funding_round_name']">Last funding round name</th>
              <th v-if="user.can['contacts_saved.field_last_funding_round_date']">Last funding round date</th>
              <th v-if="user.can['contacts_saved.field_last_funding_round_value']">Last funding round value</th>
              <th v-if="user.can['contacts_saved.field_company_sales_navigator_id']">Company sales navigator id</th>
              <th v-if="user.can['contacts_saved.field_lead_sales_navigator_id']">Lead sales navigator id</th>

              <th v-if="user.can['contacts_saved.field_created_at']">Created</th>
              <th v-if="user.can['contacts_saved.field_updated_at']">Updated</th>
              <th v-if="user.can['contacts_saved.field_data_updated_at']">Data updated</th>
              <th v-if="user.can['contacts_saved.field_id']">ID Salesar</th>
            </tr>
            </thead>
            <div class="thead-more">

            </div>
            <tbody>
            <tr v-for="contact in allPeople.data" :key="contact.id" class="list-style">
              <td class="checkbox list overflow-visible checkbox-col">
                <el-checkbox-group v-model="checked" class="btn btn-checkbox">
                  <el-checkbox v-model="checked"></el-checkbox>
                </el-checkbox-group>

              </td>
              <td v-if="user.can['contacts_saved.field_company']" class="position-relative">
                <router-link
                    v-if="contact.company_id"
                    :to="{ path: '/company/' + contact.company_id}">
                  <span class="d-flex flex-row">
                    <span v-if="contact.logo_company" class="logo d-flex">
                      <el-image class="el-image"
                          :src="'/storage/logo_company/mini/'+contact.company_id+'_logo_mini.png'"
                          fit="contain">
                        <div slot="error" class="el-image__inner">
                          <inline-svg :src="require('@/assets/images/table/d_img.svg')"/>
                        </div>
                      </el-image>
                    </span>
                    <span class="text-nowrap d-flex company-info">
                      <span class="d-flex flex-column">
                        <span class="shortened medium-font">{{contact.company}}</span>
                        <span v-if="contact.founded" class="founded d-flex">{{contact.founded}}</span>
                        <span class="d-flex">
                          <a v-if="user.can['contacts.field_website']" class="social" :href="'https://' + contact.website" rel="noreferrer" target="_blank">
                            <inline-svg :src="require('@/assets/images/table/link.svg')" aria-hidden="true"/>
                          </a>
                          <a v-if="user.can['contacts.field_linkedin_company']" class="social" :href="contact.linkedin_company" rel="noreferrer" target="_blank">
                           <inline-svg :src="require('@/assets/images/table/linkedin.svg')" aria-hidden="true"/>
                          </a>
                        </span>
                      </span>
                    </span>
                  </span>
                </router-link>
                <span v-else   class="flex-column">
                  <span class="d-flex flex-row">
                    <span v-if="contact.logo_company" class="text-nowrap logo d-flex">
                      <el-image
                          :src="'/storage/logo_company/mini/'+contact.company_id+'_logo_mini.png'"
                          fit="contain">
                        <div slot="error" class="image-slot">
                          <i class="el-icon-picture-outline"></i>
                        </div>
                      </el-image>
                    </span>
                    <span class="text-nowrap d-flex">
                      <span class="d-flex flex-column">
                        <span class="d-flex">{{contact.company}}</span>
                        <span v-if="contact.founded" class="text-nowrap founded d-flex">{{contact.founded}}</span>
                      </span>
                    </span>
                    <span class="d-flex">
                      <a v-if="user.can['contacts.field_website']" class="social" :href="'https://' + contact.website" rel="noreferrer" target="_blank">
                        <font-awesome-icon icon="fa fa-chain"/>
                      </a>
                      <a v-if="user.can['contacts.field_linkedin_company']" class="social" :href="contact.linkedin_company" rel="noreferrer" target="_blank">
                        <font-awesome-icon icon="fab fa-linkedin" aria-hidden="true"/>
                      </a>
                    </span>
                  </span>
                </span>
              </td>
              <td v-if="user.can['contacts_saved.field_website']">{{contact.website}}</td>
              <td v-if="user.can['contacts_saved.field_company_country']"><span class="text-nowrap">{{contact.company_country}}</span></td>
              <td v-if="user.can['contacts_saved.field_company_state_region']">{{contact.company_state_region}}</td>
              <td v-if="user.can['contacts_saved.field_company_city']">{{contact.company_city}}</td>
              <td v-if="user.can['contacts_saved.field_company_address']">{{contact.company_address}}</td>
              <td v-if="user.can['contacts_saved.field_industry']"><span>{{contact.industry}}</span></td>
              <td v-if="user.can['contacts_saved.field_employees']">{{contact.employees}}</td>
              <td v-if="user.can['contacts_saved.field_full_name']" class="medium-font full-name-style">
                {{contact.first_name}} {{contact.last_name}}
                <span class="current__position-name">
                       <a v-if="user.can['contacts.field_linkedin_person'] && contact.linkedin_person != null" class="social" style="color: #007bff" :href="contact.linkedin_person" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fab fa-linkedin" aria-hidden="true"/>
                       </a>
                      <a v-if="user.can['contacts.field_contacts_facebook'] && contact.contacts_facebook != null" :href="contact.contacts_facebook" class="social" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fab fa-facebook"/>
                       </a>
                       <a v-if="user.can['contacts.field_contacts_twitter'] && contact.contacts_twitter != null" class="social" :href="contact.contacts_twitter" rel="noreferrer" target="_blank">
                            <font-awesome-icon icon="fab fa-twitter"/>
                       </a>
                    </span>
              </td>
              <td v-if="user.can['contacts_saved.field_title']"><span>{{contact.title}}</span></td>
              <td v-if="user.can['contacts_saved.field_corporate_email']"><i v-if="user.can['contacts_saved.field_email_status']" class="fa fa-envelope" :class="contact.email_status === 'valid'? 'text-success': contact.email_status === 'accept_all' ? 'text-warning' : 'text-danger' "/> {{contact.corporate_email}}</td>
              <td v-if="user.can['contacts_saved.field_personal_email']">{{contact.personal_email}}</td>
              <td v-if="user.can['contacts_saved.field_personal_phone_number']"><span class="text-nowrap">{{contact.personal_phone_number}}</span></td>
              <td v-if="user.can['contacts_saved.field_company_phone']"><span class="text-nowrap">{{contact.company_phone}}</span></td>
              <td v-if="user.can['contacts_saved.field_technologies']">{{contact.technologies}}</td>
              <td v-if="user.can['contacts_saved.field_annual_revenue']">{{contact.annual_revenue}}</td>
              <td v-if="user.can['contacts_saved.field_specialties']"> <span class="d-inline-block text-truncate" style="max-width: 400px;">{{contact.specialties}}</span></td>
              <td v-if="user.can['contacts_saved.field_contacts_location_country']">{{contact.contacts_location_country}}</td>
              <td v-if="user.can['contacts_saved.field_contacts_location_state']">{{contact.contacts_location_state}}</td>
              <td v-if="user.can['contacts_saved.field_contacts_location_city']">{{contact.contacts_location_city}}</td>
              <td v-if="user.can['contacts_saved.field_ecommerce_scheme']">{{contact.ecommerce_scheme}}</td>
              <td v-if="user.can['contacts_saved.field_total_funding']"><span class="d-inline-block text-truncate total_funding" style="max-width: 400px;">{{contact.total_funding}}</span></td>
              <td v-if="user.can['contacts_saved.field_last_funding_type']"><span class="d-inline-block text-truncate last_funding_type" style="max-width: 400px;">{{contact.last_funding_type}}</span></td>
              <td v-if="user.can['contacts_saved.field_last_funding_round_name']"><span class="d-inline-block text-truncate last_funding_round_name" style="max-width: 400px;">{{contact.last_funding_round_name}}</span></td>
              <td v-if="user.can['contacts_saved.field_last_funding_round_date']"><span class="d-inline-block text-truncate last_funding_round_date" style="max-width: 400px;">{{contact.last_funding_round_date}}</span></td>
              <td v-if="user.can['contacts_saved.field_last_funding_round_value']"><span class="d-inline-block text-truncate last_funding_round_value" style="max-width: 400px;">{{contact.last_funding_round_value}}</span></td>
              <td v-if="user.can['contacts_saved.field_company_sales_navigator_id']"><span class="d-inline-block text-truncate company_sales_navigator_id" style="max-width: 400px;">{{contact.company_sales_navigator_id}}</span></td>
              <td v-if="user.can['contacts_saved.field_lead_sales_navigator_id']"><span class="d-inline-block text-truncate lead_sales_navigator_id" style="max-width: 400px;">{{contact.lead_sales_navigator_id}}</span></td>

              <td v-if="user.can['contacts_saved.field_created_at']">{{contact.created_at}}</td>
              <td v-if="user.can['contacts_saved.field_updated_at']">{{contact.updated_at}}</td>
              <td v-if="user.can['contacts_saved.field_data_updated_at']">{{contact.data_updated_at}}</td>
              <td v-if="user.can['contacts_saved.field_id']">{{contact.id}}</td>

            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <info-sidebar-contact-details :drawerParent="drawerContactDetails"></info-sidebar-contact-details>

    </div>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import {Form} from "vform";
import InfoSidebarContactDetails from "../../info-sidebar/info-sidebar-contact-details";

export default {
  name: 'ListSavedContactsPage',
  components: {
    InfoSidebarContactDetails,
  },
  mounted() {
    if (this.$route.params.id === 'all') {
      this.$store.dispatch('listName/setListNameIdSelected');
    } else {
      this.$store.dispatch('listName/setListNameIdSelected', this.$route.params.id);
    }
  },
  computed: {
    ...mapGetters('listNameContacts', ['allPeople']),
  },
  data() {
    return {
      checkedModalForm: 'AllFields',
      ChoiceOfForm: false,
      radio: 3,
      user: this.$store.getters.user,
      editmode: false,
      confirmedHide: false,
      emailCopyText: "Rozariroza@example.com",
      phoneCopyText: "+380 98 222 22 22",
      isUnlocked: false,
      checked: false,
      form: new Form({
        id: null,
        company: null,
        website: null,
        company_country: null,
        company_state_region: null,
        company_city: null,
        company_address: null,
        industry: null,
        employees: null,
        first_name: null,
        last_name: null,
        title: null,
        corporate_email: null,
        linkedin_person: null,
        linkedin_company: null,
        personal_email: null,
        personal_phone_number: null,
        company_phone: null,
        technologies: null,
        annual_revenue: null,
        specialties: null,
        contacts_location_country: null,
        contacts_location_state: null,
        contacts_location_city: null,
        contacts_facebook: null,
        contacts_twitter: null,
        ecommerce_scheme: null,
        created_at: null,
        updated_at: null,
        data_updated_at: null,
        email_status: null,
        founded: null,
        logo_company: null,

        total_funding: null,
        last_funding_type: null,
        last_funding_round_name: null,
        last_funding_round_date: null,
        last_funding_round_value: null,
      }),
      drawerContactDetails: {visible: false, contact: null},
      drawerCompanyDetails: {visible: false, company_id: null},
      ModalEditContactVisible: false,
      ModalEditContactSearchinput: ''
    };
  },
  watch: {
    '$route.query': {
      handler(newVal) {
        newVal.page = this.$route.query.page ?? 1;
        this.$Progress.start();
        this.$store.dispatch('listNameContacts/setStatesFromQuery',newVal);
        if (this.$route.params.id === 'all') {
        this.$store.dispatch('listName/setListNameIdSelected').then(() => {
          this.$store.dispatch('listContacts/counterByList'); // not use if only pagination
          this.$store.dispatch('listNameContacts/getListNameContacts').then(() => {
            this.$Progress.finish();
          }).catch(() => {
            this.$Progress.fail();
          });
        });
        } else {
          this.$store.dispatch('listName/setListNameIdSelected', this.$route.params.id).then(() => {
            this.$store.dispatch('listContacts/counterByList'); // not use if only pagination
            this.$store.dispatch('listNameContacts/getListNameContacts').then(() => {
              this.$Progress.finish();
            }).catch(() => {
              this.$Progress.fail();
            });
          });
        }
      },
      immediate: true
    }
  },
methods:{
  getTitle(){
    return this.$route.params.list_name ?? this.$route.meta.list_name_title
  }
}
};

</script>
<style scoped>
.checkbox.list {
  width: 30px;
}
.full-name-style > a{
  width: 100%;
}
.full-name-style .current__position-name a{
  color: transparent !important;
}
.full-name-style .current__position-name a svg path{
  fill: #007bff;
}
.unlocked{
  display: none !important;
}
.unlocked-btn {
  background: transparent;
  border: none;
  filter: grayscale(100%) opacity(25%);
  position: relative;
}
.unlocked-btn::after {
  content: "";
  background: url(~@/assets/images/unlocked-icon.svg);
  background-size: contain;
  width: 12px;
  height: 14px;
  filter: none;
  display: none;
  position: absolute;
  top: 2px;
  left: 0;
}
.current__position-name {
  width: max-content;
  cursor: pointer;
}
.current__position-name:hover .table-mail .unlocked-btn:after {
  content: "";
  display: block;
}
.current__position-name:hover .table-mail .unlocked-btn svg {
  opacity:0;
}
.current__position-name:hover .table-mail .unlocked-btn {
  filter: none;
}

.flex-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.social {
  color: #878787;
  transition: all 1s;
  padding-right: 3px;
}

.social:hover {
  color: #363636;
}

.current__position {
  position: absolute;
  top: 22px;
  left: 48px;
}

.current__position-name {
  display: flex;
  align-items: center;
}
.current__position-name a {
  display: flex;
  margin-right: 8px;
  padding: 0 !important;
}
.founded {
  color: grey;
  padding-right: 3px;
}
.logo {
  min-width: 41px;
  max-height: 41px;
  align-items: center;
  justify-content: center;
}

.logo .el-image {
  max-width: 50px;
  max-height: 50px;
  padding: 2px;
}
.container-email button svg {
  background: #040F24;
  margin-left: 8px;
}
</style>
<style>
.table-contacts .hidden.el-checkbox__label {
  display: none;
}
.tooltip-copy {
  padding: 12px 16px;
  background: #040F24 !important;
  border-radius: 8px;
}
.tooltip-copy .container-email {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  display: flex;
  align-items: center;
  color: #FFFFFF;
}
.tooltip-copy .container-email button {
  background: transparent;
  border:none;
}
.btn-checkbox {
  padding: 0;
  position: relative;
}

.btn-checkbox > .badge {
  font-size: 10px;
  font-weight: 400;
  position: absolute;
  right: -28px;
  top: -3px;
}

.table-contacts thead th.overflow-visible {
  overflow: visible;
}
.table-mail svg path {
  fill:#1B74E4;
}
.table-mail .el-tooltip{
  background: transparent;
  border:none;
}
/*.message-copy-style {*/
/*  background: #F2FAF7;*/
/*  border: 1px solid #20A576;*/
/*  box-shadow: 0px 8px 32px rgba(57, 60, 88, 0.15);*/
/*  border-radius: 12px;*/
/*  padding: 20px;*/
/*  width: 360px;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*}*/
/*.message-copy-style .el-notification__group {*/
/*  margin: 0;*/
/*  width: 80%;*/
/*  display: flex;*/
/*  align-items: flex-start;*/
/*  justify-content: center;*/
/*  flex-direction: column;*/
/*  text-align: left;*/
/*}*/
/*.el-notification__group .el-notification__closeBtn.el-icon-close:before {*/
/*  width: 11.7px;*/
/*  height: 11.7px;*/
/*  color: #485179;*/
/*  font-weight: 700;*/
/*}*/
/*.message-copy-style .el-notification__group .el-notification__title {*/
/*  font-weight: 700;*/
/*  font-size: 16px;*/
/*  line-height: 130%;*/
/*  color: #20A576;*/
/*}*/
/*.message-copy-style .el-notification__group .el-notification__content p {*/
/*  font-weight: 400;*/
/*  font-size: 14px;*/
/*  line-height: 130%;*/
/*  color: #4C5765;*/
/*  width: 80%;*/
/*}*/
/*.message-copy-style .el-notification__group .el-icon-close:before {*/
/*  font-weight: 400;*/
/*  font-size: 14px;*/
/*  line-height: 130%;*/
/*  color: #4C5765;*/
/*}*/
/*.message-copy-style .el-notification__icon.el-icon-success{*/
/*  width: 48px;*/
/*  height: 48px;*/
/*  background: #FFFFFF;*/
/*  color: #FFFFFF;*/
/*  border-radius: 12px;*/
/*  position: relative;*/
/*}*/
/*.message-copy-style .el-notification__icon.el-icon-success:before {*/
/*  content: "" !important;*/
/*  position: absolute;*/
/*  width: 23px;*/
/*  height:17px;*/
/*  background: url('~@/assets/images/icons/notifications/notification-success.svg');*/
/*  background-size: cover;*/
/*  left: 50%;*/
/*  top: 50%;*/
/*  transform: translate(-50%,-50%);*/
/*}*/
</style>
<style src="@/scss/list-saved-page/index.scss" lang="scss"></style>