<template>
  <el-drawer
      :visible.sync="drawer.visible"
      custom-class="info-sidebar-contact-details"
      :with-header="false"
      size="400px">
    <div class="top">
      <p class="title">Contact details</p>
      <div class="right">
        <span class="icon icon--close" @click="drawer.visible = false"></span>
      </div>
    </div>
    <div  v-if="drawer.contact"  class="drawer-container">
      <div class="contact-info">
        <div class="avatar-name">
          <img src="../../assets/images/icons/header-avatar.svg" class="avatar">
          <div class="info">
            <p class="name">{{ drawer.contact.first_name }} {{ drawer.contact.last_name }}</p>
            <p class="job-title">{{ drawer.contact.title }}</p>
          </div>
          <el-button
              type="text"
              class="edit"
              v-if="user.can['contacts.edit']"
              @click="editModal(contact)">
          </el-button>
        </div>
        <el-dialog
            :visible.sync="ModalEditContactVisible"
            class="edit-contact-modal"
            width="800px"
            v-if="user.can['contacts.add'] || user.can['contacts.edit']">
          <edit-form :editmode="editmode" :contact="contact" >

          </edit-form>
        </el-dialog>
        <div class="buttons">
          <button class="button button--border small">Add to list</button>
          <button class="button button--border small">View company</button>
        </div>
      </div>
      <div class="info-item">
        <p class="title">Personal data</p>
        <a href="#" class="link"><i class="soc-icon linkedin"></i>{{ drawer.contact.linkedin_person }}</a>
        <a href="#" class="link"><i class="soc-icon facebook"></i>{{ drawer.contact.contacts_facebook }}</a>
        <a href="#" class="primary-text"><i class="contact-icon phone"></i>{{ drawer.contact.personal_phone_number }}</a>
        <a href="#" class="link"><i class="contact-icon mail"></i>{{ getPeopleMaskByIdField(drawer.contact.id,'corporate_email')}}</a>
      </div>
      <div class="info-item">
        <p class="title">Company</p>
        <p class="link">{{ drawer.contact.company }}</p>
      </div>
      <div class="info-item">
        <p class="title">Job title</p>
        <p class="txt">{{ drawer.contact.title }}</p>
      </div>
      <div class="info-item">
        <p class="title">Seniority</p>
        <p class="txt"></p>
      </div>
      <div class="info-item">
        <p class="title">Contact location</p>
        <p class="txt">{{ drawer.contact.contacts_location_city }} {{ drawer.contact.contacts_location_state }} {{ drawer.contact.contacts_location_country }}</p>
      </div>
      <div class="info-item">
        <p class="title">Annual revenue</p>
        <p class="txt">{{ drawer.contact.annual_revenue }}</p>
      </div>
    </div>
    <div v-if="!drawer.contact">
      todo skeleton loader
    </div>
  </el-drawer>

</template>
<script>
import EditForm from "@/components/pages/form/edit/EditContact.vue";
import {mapGetters} from "vuex";
export default {
  name: "InfoSidebarContactDetails",
  components: {
    EditForm,
  },
  created() {
    this.drawer = this.drawerParent
  },
  computed:{
    ...mapGetters('people',[
      "getPeopleMaskByIdIsUnlocked",
      "getPeopleMaskByIdField",
    ]),
  },
  watch: {
    drawerParent: {
      deep: true,
      handler(newDrawer) {
        this.drawer = newDrawer
      }
    }
  },
  props: {
    contact: Object,
    drawerParent: {
      type: Object,
      default(){
        return {visible: false, contact: null}
      }
    }
  },
  data() {
    return {
      drawer: {visible: false, contact: null},
      ModalEditContactVisible: false,
      user: this.$store.getters.user,
      editmode: false,
    }
  },
  methods: {
    editModal() {
      // this.contact = contact;
      this.editmode = true;
      this.confirmedHide = false;
      this.ModalEditContactVisible = true;

    },
  }
}
</script>
