<template>
  <div>
    <el-dropdown-menu class="dropdown-add-to-list" slot="dropdown" v-model="visible">
      <div class="container">
        <div class="flex-end">
          <span class="icon icon--close" @click="visible = false"></span>
        </div>
        <div class="items">
          <div class="item">
            <el-radio v-model="radio" label="1" v-on:change="createList = false, addToList = true">Add to list
            </el-radio>

            <el-select class="" v-if="addToList" v-model="list_name_selected" filterable placeholder="Select list" size="mini">
              <el-option
                  v-for="item in listName"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
          </div>

        </div>
        <el-radio v-model="radio" label="2" v-on:change="createList = true, addToList = false">Create new list
        </el-radio>
        <form v-if="user.can['list_name.add']" style="width: 100%">
          <div class="item ">
            <el-input class="" v-if="createList" placeholder="New list" v-model="list_name_new" id="name" name="name" size="mini" ></el-input>
          </div>
<!--         ask Andrey about logic this checkbox -->
          <div class="checkbox">
            <el-checkbox v-model="checked">Automatically select new contacts after adding to the list</el-checkbox>
          </div>
          <div class="buttons">
            <el-button
                @click="visible = false"
                type="default"
                class="button button--cancel small">Close
            </el-button>
            <el-button
                v-if="createList"
                class="button button--blue small"
                type="primary"
                nativeType="submit"
                size="mini"
                @click.prevent="submitCreateListName"
                :loading="submittedCreateListName"
                :disabled="submittedCreateListName">{{ submittedCreateListName ? 'Loading' : 'Create' }}
            </el-button>
            <el-button
                @click.prevent="dropdownClickSave"
                v-if="addToList"
                class="button button--blue small"
                type="primary"
                nativeType="submit"
                size="mini"
                >Add To List
            </el-button>
          </div>
        </form>
      </div>

    </el-dropdown-menu>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import utils from "../../../../utils";
import store from "../../../../store";
import $ from 'jquery';
import Toast from '../../../../plugin/ToastPlugin'
import {Form} from 'vform';

export default {
  name: "DropdownListName",
  mounted() {
    this.$store.dispatch('listName/getListName', 'companies');
  },
  computed: {
    ...mapGetters('listName', ['listName', 'listNameIdSelected','listNameSelectedById', 'getListNameNew']),
    ...mapGetters('companyList', ['getCheckedContactsIds', 'getTypeDisplayContacts']),
    ...mapGetters('formCheckFilterMaxCompany', ['getCheckedBulk']),

    list_name_selected: {
      get() {
        return this.listNameIdSelected;
      },
      set(value) {
        this.$store.dispatch('listName/setListNameIdSelected', value);
      },
    },
    list_name_new: {
      get() {
        return this.getListNameNew;
      },
      set(value) {
        this.$store.dispatch('listName/setListNameNew', value);
      }
    },
  },
  data() {
    return {
      addToList: true,
      createList: false,
      visible: false,
      input: '',
      radio: '1',
      checked: true,
      user: this.$store.getters.user,
      submittedCreateListName: false,
      submittedCreateListContacts: false,
      form: new Form({ // for validate
        name: null,
        type: 'companies',
      }),
    }
  },
  methods: {
    dropdownCommand(command) {
      if (command === 'create') {
        this.form.name = this.getListNameNew;
        this.$store.dispatch('listName/createListName', this.form);
      }
    },
    submitCreateListName() {
      this.$Progress.start();
      this.submittedCreateListName = true;
      this.form.type = 'companies'
      this.form.name = this.getListNameNew;

      this.$store.dispatch('listName/createListName', this.form)
          .then(() => {
            $('#modalFilterMax').modal('hide');
            this.submittedCreateListName = false;
            this.$Progress.finish();
          })
          .catch(() => {
            this.submittedCreateListName = false;
            this.$Progress.fail();
          });
    },
    dropdownClickSave() {
      if(this.submittedCreateListContacts){
        return false;
      }
      if (this.listNameIdSelected) {
        if (!this.getCheckedContactsIds.length) {
          Toast.fire({
            icon: 'warning',
            title: 'No one contacts selected. Please select some one contacts.'
          });
          return false;
        }
        this.$Progress.start();
        this.submittedCreateListContacts = true;
        this.$store.dispatch('listCompanies/createList', {
          checked_company_ids: this.getCheckedContactsIds,
          list_name_id: this.listNameIdSelected,
          companies: true,
        },)
            .then((response) => {
              if(utils.isSuccessResponse(response)) {
                this.$store.dispatch('listCompanies/counterByList');
                $('#modalFilterMax').modal('hide');
                if(this.getTypeDisplayContacts === 'without_saved'){
                  this.$store.dispatch('companyList/unshiftCheckedContactsIds', []); // hide added
                  if(this.getCheckedBulk) { // FilterMax
                    this.nextCheckByFilterMax().then((response) => {
                      utils.isSuccessResponse(response)
                      this.reloadPeopleOrPush();
                    }); //select next
                  }else {
                    this.reloadPeopleOrPush();
                  }
                }else {
                  store.dispatch('companyList/setCheckedContactsIdsClear');
                  store.dispatch('companyList/setIsIndeterminate', false);
                  store.dispatch('companyList/setCheckAllOnPage', false);
                  this.reloadPeopleOrPush();
                }

              }
              this.submittedCreateListContacts = false;
              this.$Progress.finish();

            })
            .catch(() => {
              this.submittedCreateListContacts = false;
              this.$Progress.fail();
            });
      } else {
        Toast.fire({
          icon: 'warning',
          title: 'No one list selected. Please select some one list.'
        });
      }
    },
    nextCheckByFilterMax(){
      this.submitted = true;
      this.$Progress.start();
      return  this.$store.dispatch('formCheckFilterMaxCompany/submit')
          .then(() => {
            $('#modalFilterMax').modal('hide');
            this.$Progress.finish();
            this.submitted = false;
            //  Fire.$emit('AfterCreate');
          })
          .catch(() => {
            this.submitted = false;
            this.$Progress.fail();
          });
    },
    reloadPeopleOrPush(){
      if((this.$router.currentRoute.query.list_name_id !== undefined && this.$router.currentRoute.query.list_name_id === this.$store.getters['listName/listNameIdSelected'])
          || this.$router.currentRoute.query.list_name_id === undefined &&  this.$store.getters['listName/listNameIdSelected'] !== undefined){
        // if same just getPeople(update page)
        this.$store.dispatch('companyList/getList')
      }else{
        this.$store.dispatch('companyList/setHistoryForListName')
      }
    }
  }
}
</script>

<style>
</style>