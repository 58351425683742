<template>
    <div  v-if="user.can['company.view']" class="">
        <div class="">
          <section class="top-settings">
            <div class="left-buttons">
              <button data-widget="pushmenu" class="pushmenu">
                <inline-svg :src="require('@/assets/images/icons/pushmenu.svg')"/>
              </button>

              <el-dropdown trigger="click" placement="bottom-start">
                <button class="button" type="button" v-if="user.can['contacts.view']">Bulk select</button>
                <DropDownFilterMax/>
              </el-dropdown>

              <el-dropdown trigger="click" placement="bottom-start">
                <button class="button">Add to list</button>
                <DropDownAddToList/>
              </el-dropdown>
            </div>
            <switch-display-companies></switch-display-companies>
            <div class="right">

            </div>
          </section>
                  <div class="border">
                    <div class="table-responsive table-company">
                    <table>
                        <thead>
                            <tr>
                            <th class="checkbox list overflow-visible checkbox-col">
                                <div class="btn btn-checkbox">
                                    <el-checkbox :indeterminate="isIndeterminate" v-model="checkAllOnPage"
                                                 @change="handleCheckAllPageChange">
                                    </el-checkbox>
                                </div>
                                <el-dropdown @command="dropdownCommand"  placement="bottom-start" trigger="click">
                                    <span class="checkbox-col__icon-dropdown">
                                        <inline-svg :src="require('@/assets/images/icons/arr-down-thead.svg')"/>
                                    </span>
                                    <el-dropdown-menu slot="dropdown" class="checkbox-col__dropdown-list">
                                        <el-dropdown-item command="check_all_by_tariff">All by tariff {{user.tariff_row}}</el-dropdown-item>
                                        <el-dropdown-item command="order_checked_all">Sort checked</el-dropdown-item>
                                        <el-dropdown-item command="order_checked_bulk">Sort checked bulk</el-dropdown-item>
                                        <el-dropdown-item command="unsort_checked_all">Unsort checked</el-dropdown-item>
                                        <el-dropdown-item command="check_none">None</el-dropdown-item>
                                    </el-dropdown-menu>
                                </el-dropdown>
                                <el-badge v-if="getCheckedContactsIds.length" :value="getCheckedContactsIds.length" class="item" type="primary">
                                </el-badge>
                            </th>
                                <th v-if="user.can['contacts.field_full_name']">Full name</th>
                                <th v-if="user.can['contacts.field_title']">Title</th>
                                <th v-if="user.can['contacts.field_corporate_email']">Corporate email</th>
                                <th v-if="user.can['contacts.field_contacts_location_country']">Contact's location/Country</th>
                                <th v-if="user.can['contacts.field_contacts_location_state']">Contact's location/State or Region</th>
                                <th v-if="user.can['contacts.field_contacts_location_city']">Contact's location/City</th>
                                <th v-if="user.can['contacts.field_personal_email']">Personal email</th>
                                <th v-if="user.can['contacts.field_personal_phone_number']">Personal phone number</th>
                                <th v-if="user.can['contacts.field_technologies']">Technologies</th>
                                <th v-if="user.can['contacts.field_annual_revenue']">Annual Revenue</th>
                                <th v-if="user.can['contacts.field_specialties']">Specialties</th>
                                <th v-if="user.can['contacts.field_ecommerce_scheme']">B2B/B2C</th>
                                <th v-if="user.can['contacts.field_created_at']">Created</th>
                                <th v-if="user.can['contacts.field_updated_at']">Updated</th>
                                <th v-if="user.can['contacts.field_data_updated_at']">Data updated</th>
                                <th v-if="user.can['contacts.field_id']">ID Salesar</th>
                                <th v-if="user.can['company.delete'] || user.can['company.edit']" scope="col">Action</th>
                            </tr>
                        </thead>
                        <template v-if="allPeople">
                            <tbody>
                                <tr v-for="contact in allPeople.data" :key="contact.id">
                                    <td class="checkbox list checkbox-col">
                                        <el-checkbox-group v-model="checkedContactsIds" @change="handleCheckedContactChange">
                                            <el-checkbox  :label="contact.id" :key="contact.id" size="small" class="hidden"></el-checkbox>
                                        </el-checkbox-group>
                                    </td>
                                    <td v-if="user.can['contacts.field_full_name']">
                                      <a href="#" @click.prevent.stop="drawerContactDetails =  {visible: true, contact: contact}"><p class="medium-font">
                                        {{ contact.first_name }} {{ contact.last_name }}</p></a>
                                        <span class="current__position-name">
                                                   <a v-if="user.can['contacts.field_linkedin_person'] && contact.linkedin_person != null" class="social" :href="contact.linkedin_person" rel="noreferrer" target="_blank">
                                                         <inline-svg :src="require('@/assets/images/table/linkedin.svg')"/>
                                                   </a>
                                                    <a class="table-mail social">
                                                        <el-tooltip placement="top" popper-class="tooltip-copy">
                                                        <button type="button" >
                                                          <inline-svg :src="require('@/assets/images/table/mail.svg')"/>
                                                          </button>
                                                          <div slot="content" class="container-email">
                                                            {{emailCopyText}} <button @click="copyEmail(emailCopyText)">
                                                            <inline-svg :src="require('@/assets/images/copy.svg')"/>
                                                            </button>
                                                           </div>
                                                         </el-tooltip>
                                                    </a>
                                                    <a class="table-mail">
                                                      <el-tooltip placement="top" popper-class="tooltip-copy">
                                                        <button type="button" >
                                                          <inline-svg :src="require('@/assets/images/phone.svg')"/>
                                                        </button>
                                                        <div slot="content" class="container-email">
                                                          {{phoneCopyText}} <button :plain="true" @click="copyPhone(phoneCopyText)" popper-class="zzz">
                                                          <inline-svg :src="require('@/assets/images/copy.svg')"/>
                                                          </button>
                                                        </div>
                                                      </el-tooltip>
                                                    </a>
                                                  <a v-if="user.can['contacts.field_contacts_facebook'] && contact.contacts_facebook != null" :href="contact.contacts_facebook" class="social" rel="noreferrer" target="_blank">
                                                        <font-awesome-icon icon="fab fa-facebook"/>
                                                   </a>
                                                   <a v-if="user.can['contacts.field_contacts_twitter'] && contact.contacts_twitter != null" class="social" :href="contact.contacts_twitter" rel="noreferrer" target="_blank">
                                                        <font-awesome-icon icon="fab fa-twitter"/>
                                                   </a>
                                        </span>
                                    </td>
                                    <td v-if="user.can['contacts.field_title']"><span class="text-nowrap">{{contact.title}}</span></td>
                                    <td v-if="user.can['contacts.field_corporate_email']"><i v-if="user.can['contacts.field_email_status']" class="fa fa-envelope" :class="contact.email_status === 'valid'? 'text-success': contact.email_status === 'accept_all' ? 'text-warning' : 'text-danger' "/> {{contact.corporate_email}}</td>
                                    <td v-if="user.can['contacts.field_contacts_location_country']">{{contact.contacts_location_country}}</td>
                                    <td v-if="user.can['contacts.field_contacts_location_state']">{{contact.contacts_location_state}}</td>
                                    <td v-if="user.can['contacts.field_contacts_location_city']">{{contact.contacts_location_city}}</td>
                                    <td v-if="user.can['contacts.field_personal_email']">{{contact.personal_email}}</td>
                                    <td v-if="user.can['contacts.field_personal_phone_number']"><span class="text-nowrap">{{contact.personal_phone_number}}</span></td>
                                    <td v-if="user.can['contacts.field_technologies']">{{contact.technologies}}</td>
                                    <td v-if="user.can['contacts.field_annual_revenue']">{{contact.annual_revenue}}</td>
                                    <td v-if="user.can['contacts.field_specialties']"> <span class="d-inline-block text-truncate" style="max-width: 400px;">{{contact.specialties}}</span></td>
                                    <td v-if="user.can['contacts.field_ecommerce_scheme']">{{contact.ecommerce_scheme}}</td>
                                    <td v-if="user.can['contacts.field_created_at']">{{contact.created_at}}</td>
                                    <td v-if="user.can['contacts.field_updated_at']">{{contact.updated_at}}</td>
                                    <td v-if="user.can['contacts.field_data_updated_at']">{{contact.data_updated_at}}</td>
                                    <td v-if="user.can['contacts.field_id']">{{contact.id}}</td>
                                    <td>
                                      <div class="action-button">
                                        <el-tooltip effect="dark" placement="left-start">

                                          <el-button
                                              type="text"
                                              class="edit"
                                              v-if="user.can['contacts.edit']"
                                              @click="editModal(contact)">
                                            <inline-svg :src="require('@/assets/images/table/edit.svg')"/>
                                          </el-button>

                                          <div slot="content">Edit contact info</div>

                                        </el-tooltip>

<!--                                      </div>-->
<!--                                    </td>-->
<!--                                    <td v-if="user.can['contacts.delete']">-->
<!--                                        <div class="dt-buttons btn-group flex-wrap">-->
<!--                                            <button v-if="user.can['contacts.delete']" type="button"-->
<!--                                                    class="btn btn-outline-danger btn-sm btn-flat"-->
<!--                                                    @click.prevent="deleteContacts(contact.id)">-->
<!--                                        <font-awesome-icon icon="fa-trash"/>-->
<!--                                                Delete-->
<!--                                            </button>-->
<!--                                        </div>-->
<!--                                      <div class="action-button">-->
                                        <el-tooltip effect="dark" placement="left-start">
                                          <button v-if="user.can['contacts.delete']" class="delete" type="button"
                                                  @click.prevent="deleteContacts(contact.id)">
                                            <inline-svg :src="require('@/assets/images/table/Delete.svg')"/>
                                          </button>
                                          <div slot="content">Delete contact</div>
                                        </el-tooltip>
                                      </div>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </table>
                    </div>
                  </div>
          <info-sidebar-contact-details :drawerParent="drawerContactDetails" />
          <company-card-contacts-edit />
        </div>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import utils from "../../../../utils";
    import DownloadFilesService from "../../../../services/DownloadFilesService";
    import $ from "jquery";
    import Swal from 'sweetalert2';
    import Toast from '../../../../plugin/ToastPlugin'
    import InfoSidebarContactDetails from "@/components/info-sidebar/info-sidebar-contact-details";
    import CompanyCardContactsEdit from "@/components/pages/company/partials/CompanyCardContactsEdit";
    import SwitchDisplayCompanies from "@/components/pages/company/partials/SwitchDisplayCompanies";
    import DropDownFilterMax from "../dropdown/DropDownFilterMax";
    import DropDownAddToList from "../dropdown/DropDownAddToList";

export default {
    name: 'CompanyCardContactsListTab',
    components: {
      SwitchDisplayCompanies,
      CompanyCardContactsEdit, InfoSidebarContactDetails, DropDownFilterMax,DropDownAddToList},
    computed: {
        ...mapGetters('people', [
            'allPeople',
            'getFilterParams',
            'getIdsOptions',
            'getCheckedContactsIds',
            'getCheckAllOnPage',
            'getIsIndeterminate',
        ]),
        ...mapGetters('formCheckFilterMax', ['getOrderCheckedAll', 'getCheckedBulk']),
        checkedContactsIds: {
            set(value){
                this.checkAllOnPage = utils.everyIdsInArray( this.getIdsOptions, value); // checkedCount === this.getIdsOptions.length
                this.$store.dispatch('people/setCheckedContactsIds', value); // todo: manual_ids
            },
            get(){return this.getCheckedContactsIds;}
        },
        isIndeterminate: {
            set(value){
                this.$store.dispatch('people/setIsIndeterminate', value);
            },
            get(){return this.getIsIndeterminate;}
        },
        checkAllOnPage: {
            set(value){
                this.$store.dispatch('people/setCheckAllOnPage', value);
            },
            get(){return this.getCheckAllOnPage;}
        },
    },
    mounted() {
      this.$store.dispatch('company/setStatesFromParams', this.$route.params.companyId);
      this.$store.dispatch('company/getCompanyCard').then(() => {
        this.$Progress.finish();
      }).catch(() => {
        this.$Progress.fail();
      });
      this.$store.dispatch('listContacts/counter').then(() => {
        this.$Progress.finish();
      }).catch(() => {
        this.$Progress.fail();
      });
        this.$store.dispatch('formCheckFilterMax/getCheckedIds');
    },
    watch: {
      '$route': {
        handler() {
          this.$store.dispatch('company/setStatesFromParams', this.$route.params.companyId);
          this.$Progress.start();
        },
        deep: true,
        immediate: true
      },
        '$route.query': {
            handler(newVal) {
                if(this.$route.query.checked_bulk !== undefined){
                    this.$store.dispatch('formCheckFilterMax/setCheckedBulk', this.$route.query.checked_bulk);
                }
                if(this.$route.query.order_chorder_checked_all !== undefined){
                    this.$store.dispatch('formCheckFilterMax/setOrderCheckedAll', this.$route.query.order_checked_all);
                }

                this.$Progress.start();
                newVal.page = this.$route.query.page;
                this.$store.dispatch('people/setStatesFromQuery',newVal);
                if (this.getOrderCheckedAll || this.getCheckedBulk) {
                    this.$store.dispatch('listContacts/counter').then(() => {   //todo: once if only paginate
                        this.$store.dispatch('company/getContacts').then(() => {
                            this.setCorrectCheckedFlag()
                            this.$Progress.finish();
                        }).catch(() => {
                            this.$Progress.fail();
                        });
                        this.$Progress.finish();
                    }).catch(() => {
                        this.$Progress.fail();
                    });
                } else {
                    this.$store.dispatch('listContacts/counter').then(() => {   //todo: once if only paginate
                        this.$Progress.finish();
                    }).catch(() => {
                        this.$Progress.fail();
                    });
                    this.$store.dispatch('company/getContacts').then(() => {
                        this.setCorrectCheckedFlag()
                        this.$Progress.finish();
                    }).catch((

                    ) => {
                        this.$Progress.fail();
                    });
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            emailCopyText: "Rozariroza@example.com",
            phoneCopyText: "+380 98 222 22 22",
            user: this.$store.getters.user,
            drawerContactDetails: {visible: false, contact: null},
        }
    },
    methods:{
        editModal(contact) {
            if (contact){
                contact.option_edit_from_company_page = true; //clear to default
            }

            this.$store.dispatch('company/setModalContactsEdit', true);
            this.$store.dispatch('company/setModalContactsData', contact);
        },
      copyEmail(emailCopyText) {
        try {
          navigator.clipboard.writeText(emailCopyText);
          this.messageCopy();
        } catch($e) {
          this.errorMessageCopy();
        }
      },
      copyPhone(phoneCopyText) {
        try {
          navigator.clipboard.writeText(phoneCopyText);
          this.messageCopy();
        } catch($e) {
          this.errorMessageCopy();
        }
      },
        dropdownCommand(command){
            if (command === 'check_all_by_tariff') {
                this.$store.dispatch('formCheckFilterMax/setCheckedAllByTariff', true);
            }
            if (command === 'check_none') {
                this.$store.dispatch('formCheckFilterMax/setOrderUncheckAll', true);
            }
            if (command === 'order_checked_all') {
                this.$store.dispatch('formCheckFilterMax/setOrderCheckedAll', true);
            }
            if (command === 'unsort_checked_all') {
                this.$store.dispatch('formCheckFilterMax/setOrderCheckedAll', false);
            }
            if (command === 'order_checked_bulk') {
                this.$store.dispatch('formCheckFilterMax/setCheckedBulk', true);
                this.$store.dispatch('formCheckFilterMax/setOrderCheckedAll', '');
            }

            if (command) {
                this.$Progress.start();
                this.$store.dispatch('company/getContacts').then(() => {
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                });
            }
        },
        handleCheckAllPageChange(val) {
            this.checkedContactsIds = val ? this.getIdsOptions : [];
            this.isIndeterminate = false;
        },
        setCorrectCheckedFlag(){
            if(Array.isArray(this.getCheckedContactsIds) && Array.isArray(this.getIdsOptions)){
                if(this.getIdsOptions.length > 0){
                    this.checkAllOnPage = utils.everyIdsInArray( this.getIdsOptions, this.getCheckedContactsIds);
                }
                if(this.checkAllOnPage){
                    this.isIndeterminate = false;
                }else {
                    this.isIndeterminate = utils.someIdsInArray( this.getIdsOptions, this.getCheckedContactsIds);
                }
            }
        },
        handleCheckedContactChange(value) {
            let checkedCount = value.length;
            this.checkAllOnPage = utils.everyIdsInArray(this.getIdsOptions, value); //checkedCount === this.getIdsOptions.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.getIdsOptions.length || utils.someIdsInArray(value,this.getIdsOptions) && !utils.everyIdsInArray(this.getIdsOptions, value);
        },
        updateContacts() {
            this.$Progress.start();
            this.$store.dispatch('company/putPeople', this.form)
                .then((response) => {
                    if(!utils.isSuccessResponse(response)) {this.$Progress.fail();  return false;}
                    this.confirmedHide = true;
                    $('#addNew').modal('hide');
                    this.$Progress.finish();
                    //  Fire.$emit('AfterCreate');
                })
                .catch(() => {
                    this.$Progress.fail();
                });
        },
        deleteContacts(id) {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '#3085d6',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {

                // Send request to the server
                if (result.value) {
                    this.$Progress.start();
                    this.$store.dispatch('company/deletePeople', id).then(() => {
                        Swal.fire(
                            'Deleted!',
                            'Your contact has been deleted.',
                            'success'
                        );
                        this.$Progress.finish();
                    }).catch(() => {
                        this.$Progress.fail();
                    });
                }
            })
        },
        createContact() {
            this.$Progress.start();
            let promise = this.$store.dispatch('company/createPeople', this.form);
            promise.then(() => {
                this.confirmedHide = true;
                $('#addNew').modal('hide');
                this.$Progress.finish();
            }).catch(() => {
                this.$Progress.fail();
            });
        },
        editModalCompany(contact) {
            this.editmode = true;
            this.confirmedHide = false;
            this.form.reset();
            this.form.clear();
            this.form.fill(contact);
            $('#addNew').modal('show');
        },
        newModal() {
            this.editmode = false;
            this.confirmedHide = false;
            this.form.reset();
            this.form.clear();
            $('#addNew').modal('show');
        },
        askHideModal(eventModal){
            if(this.confirmedHide === false){
                eventModal.preventDefault();
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    showCancelButton: true,
                    confirmButtonColor: '#d33',
                    cancelButtonColor: '#3085d6',
                    confirmButtonText: 'Yes, close it!'
                }).then((result) => {
                    if (result.value) {
                        this.confirmedHide = true;
                        $('#addNew').modal('hide');
                    }
                })
            }
        },
        pluck(array, key) {
            return array.map(o => o[key]);
        },
        textAreaLength(string) {
            if (!string) {
                return 1;
            }
            let first = Math.round(string.length / 43) + 1;
            let second = (string.match(/\n/g) || '').length + 1;
            return first > second ? first : second;
        },
        download() {
            Toast.fire({
                icon: 'success',
                title: 'Start request for download ...'
            });

            DownloadFilesService.download(this.getFilterParams)

        }
    }
}
</script>

<style scoped>
.table-company {
  height: 100vh;
}
.hr__modified {
    margin-bottom: 0;
    margin-top: 0;
}


.hr__created {
    padding-bottom: 15px;
    padding-left: 5px ;
}
.table-mail svg path {
  fill:#1B74E4;
  color: #1B74E4;
}
.table-mail .el-tooltip{
  background: transparent;
  border:none;
}
.flex-end {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.table thead th {
    border-bottom: none;
}

.border-none {
    border-top: none;
}

.position-relative--h {
    height: 45px;
    position: relative;
}

.current__position {
    position: absolute;
    top: 25px;
    left: 52px;
}

.current__position-name {
  display: flex;
  align-items: center;
}
.current__position-name a {
  display: flex;
  margin-right: 8px;
  padding: 0 !important;
}

.social {
    /*color: #878787;*/
    transition: all 1s;
    padding-right: 3px;

}

.social:hover {
    /*color: #363636;*/
}

.founded{
    color: grey;
    padding-right: 3px;
}
.logo{
    min-width: 41px;
    max-height: 41px;
    align-items: center;
    justify-content: center;
}
.logo .el-image{
    max-width: 50px;
    max-height: 50px;
    padding: 2px;
}

</style>
