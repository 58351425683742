<template>
  <nav class="header">
    <!---LOGOTYPE-------------------------------------------------------->
    <div class="logo">
      <a href="/"><img src="../assets/images/header/Logo.svg" alt="" /></a>
    </div>
    <!---USER MENU-------------------------------------------------------->
    <div class="header-user">
      <div class="header-user_btn">
        <ul class="menu">
          <li v-if="user.can['contacts.view']">
            <router-link :to="routeAppendQuery('/contacts', ['CompanyList', 'contacts'])" class="nav-button">
              <inline-svg :src="require('../assets/images/header/people-icon.svg')"/>
              {{ $t("nav.contacts") }}
            </router-link>
          </li>

          <li v-if="user.can['company.view']">
            <router-link :to="routeAppendQuery('/company', ['CompanyList', 'contacts'])" class="nav-button">
              <inline-svg :src="require('../assets/images/header/company-icon.svg')"/>
              {{ $t("nav.company") }}
            </router-link>
          </li>

          <li v-if="user.can['list_name.view']" class="list">
            <router-link to="/list-saved-contacts" class="nav-button">
              <inline-svg :src="require('../assets/images/header/list-icon.svg')"/>
              {{ $t("nav.listContacts") }}
            </router-link>
          </li>

          <li v-if="user.can['saved_search.full']">
            <router-link to="/saved-searches" class="nav-button">
              <inline-svg :src="require('../assets/images/header/save-icon.svg')"/>
              {{ $t("nav.savedSearch") }}
            </router-link>
          </li>
          <li>


            <el-dropdown v-if="user.can['admin_contacts_filter.full']" placement="bottom">
              <span>
                 <el-button type="primary"  class="nav-button hover admin-panel-nav-item" slot="reference">
                  <inline-svg :src="require('../assets/images/header/admin.svg')"/>
                  <span class="txt">Admin panel</span>
                  <font-awesome-icon icon="fa fa-angle-down" />
                </el-button>
              </span>

              <el-dropdown-menu slot="dropdown" class="navbar-dropdown admin-menu-dropdown">
                <div class="flex">
                  <div class="item" v-if="user.can['import.add']">
                    <p>Import leads</p>

                    <el-dropdown-item v-if="user.can['import.add']">
                      <router-link to="/import">
                        <inline-svg :src="require('../assets/images/header/import.svg')"/>
                        {{ $t("nav.import") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['import.add']">
                      <router-link to="/import/saved">
                        <inline-svg :src="require('../assets/images/header/saved.svg')"/>
                        {{ $t("nav.import_saved") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['import.add']">
                      <router-link to="/import/fails">
                        <inline-svg :src="require('../assets/images/header/fails.svg')"/>
                        {{ $t("nav.import_fails") }}
                      </router-link>
                    </el-dropdown-item>
                  </div>

                  <div class="item center" v-if="user.can['users.view'] || user.can['roles.view'] || user.can['permissions.view']">
                    <p>Settings</p>
                    <el-dropdown-item v-if="user.can['users.view']">
                      <router-link to="/users">
                        <inline-svg :src="require('../assets/images/header/users.svg')"/>
                        {{ $t("nav.users") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['roles.view']">
                      <router-link to="/roles">
                        <inline-svg :src="require('../assets/images/header/role.svg')"/>
                        {{ $t("nav.role") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['permissions.view']">
                      <router-link to="/permissions">
                        <inline-svg :src="require('../assets/images/header/permission.svg')"/>
                        {{ $t("nav.permission") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/employees/list">
                        <font-awesome-icon icon="fa-solid fa-usd"/>
                        {{ $t("nav.employees") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/ecommerce-scheme/list">
                        <font-awesome-icon icon="fa-solid fa-usd"/>
                        {{ $t("nav.ecommerceScheme") }}
                      </router-link>
                    </el-dropdown-item>


                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/tariff/orders">
                        <font-awesome-icon icon="fa fa-credit-card"/>
                        {{ $t("nav.tariff_orders") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/tariff/users/panel">
                        <font-awesome-icon icon="fa fa-credit-card"/>
                        {{ $t("nav.tariff_user_panel") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/tariffs">
                        <font-awesome-icon icon="fa fa-credit-card"/>
                        {{ $t("nav.tariffs") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/credit/logs">
                        <font-awesome-icon icon="fa fa-credit-card"/>
                        {{ $t("nav.log_credits") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['admin_company_geo.full']">
                      <router-link to="/admin/company-geo/list">
                        <font-awesome-icon icon="fa fa-list-ol"/>
                        {{ $t("nav.company_list_geo") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['admin_contacts_geo.full']">
                      <router-link to="/admin/contacts-geo/list">
                        <font-awesome-icon icon="fa fa-list-ol"/>
                        {{ $t("nav.contacts_list_geo") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['admin_title.full']">
                      <router-link to="/admin/title/list">
                        <font-awesome-icon icon="fa fa-list-ol"/>
                        {{ $t("nav.title_list") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['admin_title.full']">
                      <router-link to="/admin/industry/list">
                        <font-awesome-icon icon="fa fa-list-ol"/>
                        {{ $t("nav.industry_list") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['admin_technologies.full']">
                      <router-link to="/admin/technologies/list">
                        <font-awesome-icon icon="fa fa-list-ol"/>
                        {{ $t("nav.technologies_list") }}
                      </router-link>
                    </el-dropdown-item>
                  </div>

                  <div class="item" v-if="user.can['leads.view']">
                    <p>Services</p>
                    <el-dropdown-item v-if="user.can['users.view']">
                      <router-link to="/leads">
                        <inline-svg :src="require('../assets/images/header/leads.svg')"/>
                        {{ $t("nav.leads") }}
                      </router-link>
                    </el-dropdown-item>

                    <el-dropdown-item v-if="user.can['tariff_admin.view']">
                      <router-link to="/tariff/user">
                        <font-awesome-icon icon="fa-solid fa-usd"/>
                        {{ $t("nav.tariff_users") }}
                      </router-link>
                    </el-dropdown-item>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>
      <div class="header-user_menu">

        <div class="credits">
          <el-tooltip effect="dark" placement="bottom" popper-class="tooltip-default" visible="true">
            <div slot="content">You have {{ getTariffName }} credits. It will <br/>be available until 20.06.2022</div>
            <p>{{ getTariffName }} <span>credits</span></p>
          </el-tooltip>
<!--          <button class="icon-button">Upgrade</button>-->

          <router-link to="/upgrade-plan" class="icon-button">Upgrade</router-link>
        </div>

        <div class="icons">

          <el-dropdown trigger="click">
            <span>
              <button class="question icon-button">
                <inline-svg :src="require('../assets/images/header/question.svg')"/>
              </button>
            </span>
            <el-dropdown-menu slot="dropdown" class="navbar-dropdown">
              <el-dropdown-item>
                <router-link to="#">
                  <inline-svg :src="require('../assets/images/header/question_and_answers.svg')"/>
                  Questions and answers
                </router-link>
              </el-dropdown-item>

              <el-dropdown-item>
                <router-link to="#">
                  <inline-svg :src="require('../assets/images/header/start_guide.svg')"/>
                  Get started guide
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <button class="notifications icon-button" @click="drawerNotification =  {visible: true}">
            <span class="num">9</span>
            <inline-svg :src="require('../assets/images/header/alert.svg')"/>
          </button>

          <info-sidebar-notification :drawerParent="drawerNotification"></info-sidebar-notification>

          <el-dropdown trigger="click">
            <span>
              <button class="user-button icon-button" slot="reference">{{getFirstLetters(user.name)}}</button>
            </span>
            <el-dropdown-menu slot="dropdown" class="user-dropdown navbar-dropdown">
              <div class="top-area">
                <p class="initials">{{getFirstLetters(user.name)}}</p>
                <div class="info">
                  <p class="full name">{{ user.name }}</p>
                  <p class="mail">{{ user.email }}</p>
                </div>
                <div class="credits">
                  <el-tooltip effect="dark" placement="bottom" popper-class="tooltip-default" visible="true">
                    <div slot="content">You have {{ getTariffName }} credits. It will <br/>be available until 20.06.2022</div>
                    <p>{{ getTariffName }} <span>credits</span></p>
                  </el-tooltip>
                  <button class="icon-button">Upgrade</button>
                </div>
              </div>

              <ul>
                <el-dropdown-item>
                  <router-link to="/profile">
                    <inline-svg :src="require('../assets/images/header/account-icon.svg')"/>
                    Account
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item>
                  <router-link to="/profile/team">
                    <inline-svg :src="require('../assets/images/header/team-icon.svg')"/>
                    Team
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item>
                  <router-link to="/upgrade-plan" target="_blank">
                    <inline-svg :src="require('../assets/images/header/upgrate-icon.svg')"/>
                    Upgrade plan
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item>
                  <router-link to="/profile/manage-plan">
                    <inline-svg :src="require('../assets/images/header/manage-icon.svg')"/>
                    Manage plan
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item>
                  <router-link to="/profile/billing">
                    <inline-svg :src="require('../assets/images/header/billing-icon.svg')"/>
                    Billing
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item>
                  <router-link to="/profile/privacy-policy">
                    <inline-svg :src="require('../assets/images/header/privacy-icon.svg')"/>
                    Privacy policy
                  </router-link>
                </el-dropdown-item>

                <el-dropdown-item >
                  <router-link to="#" class="logout" @click.native.prevent="logoutForm">
                    <inline-svg :src="require('../assets/images/header/logout-icon.svg')"/>
                    Log out
                  </router-link>
                </el-dropdown-item>
              </ul>

              <div class="follow-us">
                <p>Follow us</p>
                <div class="soc">
                  <a href="#"><inline-svg :src="require('../assets/images/soc-icon/linkidin.svg')"/></a>
                  <a href="#"><inline-svg :src="require('../assets/images/soc-icon/facebook.svg')"/></a>
                  <a href="#"><inline-svg :src="require('../assets/images/soc-icon/twitter.svg')"/></a>
                </div>
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

      </div>
    </div>



    <!---MOBILE------------------------------------------------------->
    <div class="right-header-area">
      <button class="user-button" slot="reference" @click="ProfileMenu = true">{{getFirstLetters(user.name)}}<span class="num">9</span></button>
      <inline-svg :src="require('../assets/images/icons/burger.svg')" @click="MobileMenu = true" class="burger"/>
    </div>

    <el-drawer
        size="80%"
        :visible.sync="MobileMenu"
        :direction="direction"
        class="mobile-menu"
        :with-header="false">

      <div class="top-area">
        <p class="initials" @click="ProfileMenu = true">{{getFirstLetters(user.name)}}</p>
        <div class="info">
          <p class="full name">{{ user.name }}</p>
          <p class="mail">{{ user.email }}</p>
        </div>
        <div class="credits">
          <el-tooltip effect="dark" placement="bottom" popper-class="tooltip-default" visible="true">
            <div slot="content">You have {{ getTariffName }} credits. It will <br/>be available until 20.06.2022</div>
            <p>{{ getTariffName }} <span>credits</span></p>
          </el-tooltip>
          <a href="/upgrade-plan" class="icon-button">Upgrade</a>
        </div>
      </div>

      <ul class="menu mobile-menu" @click="MobileMenu = false">
        <li v-if="user.can['contacts.view']">
          <router-link :to="routeAppendQuery('/contacts', ['CompanyList', 'contacts'])" class="nav-button">
            <inline-svg :src="require('../assets/images/header/people-icon.svg')"/>
            {{ $t("nav.contacts") }}
          </router-link>
        </li>

        <li v-if="user.can['company.view']">
          <router-link :to="routeAppendQuery('/company', ['CompanyList', 'contacts'])" class="nav-button">
            <inline-svg :src="require('../assets/images/header/company-icon.svg')"/>
            {{ $t("nav.company") }}
          </router-link>
        </li>

        <li v-if="user.can['list_name.view']" class="list">
          <router-link to="/list-saved-contacts" class="nav-button">
            <inline-svg :src="require('../assets/images/header/list-icon.svg')"/>
            {{ $t("nav.listContacts") }}
          </router-link>
        </li>

        <li v-if="user.can['saved_search.full']">
          <router-link to="/saved-searches" class="nav-button">
            <inline-svg :src="require('../assets/images/header/save-icon.svg')"/>
            {{ $t("nav.savedSearch") }}
          </router-link>
        </li>
      </ul>
      <div class="follow-us">
        <p>Follow us</p>
        <div class="soc">
          <a href="#" @click="MobileMenu = false"><inline-svg :src="require('../assets/images/soc-icon/linkidin.svg')"/></a>
          <a href="#" @click="MobileMenu = false"><inline-svg :src="require('../assets/images/soc-icon/facebook.svg')"/></a>
          <a href="#" @click="MobileMenu = false"><inline-svg :src="require('../assets/images/soc-icon/twitter.svg')"/></a>
        </div>
      </div>
    </el-drawer>
    <el-drawer
        size="80%"
        :visible.sync="ProfileMenu"
        :direction="direction"
        class="mobile-menu"
        :with-header="false">

      <div class="top-area">
        <p class="initials">{{getFirstLetters(user.name)}}</p>
        <div class="info">
          <p class="full name">{{ user.name }}</p>
          <p class="mail">{{ user.email }}</p>
        </div>
        <div class="credits">
          <el-tooltip effect="dark" placement="bottom" popper-class="tooltip-default" visible="true">
            <div slot="content">You have {{ getTariffName }} credits. It will <br/>be available until 20.06.2022</div>
            <p>{{ getTariffName }} <span>credits</span></p>
          </el-tooltip>
          <a href="/upgrade-plan" class="icon-button">Upgrade</a>
        </div>
      </div>
      <ul class="mobile-menu" @click="ProfileMenu = false, MobileMenu = false">
       <li>
         <router-link to="/profile">
           <inline-svg :src="require('../assets/images/header/account-icon.svg')"/>
           Account
         </router-link>
       </li>
        <li>
          <router-link to="/profile/team">
            <inline-svg :src="require('../assets/images/header/team-icon.svg')"/>
            Team
          </router-link>
        </li>
        <li>
          <router-link to="/upgrade-plan" target="_blank">
            <inline-svg :src="require('../assets/images/header/upgrate-icon.svg')"/>
            Upgrade plan
          </router-link>
        </li>
        <li>
          <router-link to="/profile/manage-plan">
            <inline-svg :src="require('../assets/images/header/manage-icon.svg')"/>
            Manage plan
          </router-link>
        </li>
        <li>
          <router-link to="/profile/billing">
            <inline-svg :src="require('../assets/images/header/billing-icon.svg')"/>
            Billing
          </router-link>
        </li>
        <li>
          <router-link to="/profile/privacy-policy">
            <inline-svg :src="require('../assets/images/header/privacy-icon.svg')"/>
            Privacy policy
          </router-link>
        </li>
        <li>
          <router-link to="#" class="logout" @click.native.prevent="logoutForm">
            <inline-svg :src="require('../assets/images/header/logout-icon.svg')"/>
            Log out
          </router-link>
        </li>
      </ul>

      <div class="follow-us">
        <p>Follow us</p>
        <div class="soc">
          <a href="#" @click="ProfileMenu = false"><inline-svg :src="require('../assets/images/soc-icon/linkidin.svg')"/></a>
          <a href="#" @click="ProfileMenu = false"><inline-svg :src="require('../assets/images/soc-icon/facebook.svg')"/></a>
          <a href="#" @click="ProfileMenu = false"><inline-svg :src="require('../assets/images/soc-icon/twitter.svg')"/></a>
        </div>
      </div>

    </el-drawer>

  </nav>
  <!-- /.navbar -->
</template>

<script>
import { mapActions } from "vuex";
import Toast from "../plugin/ToastPlugin";
import qs from 'qs'
import InfoSidebarNotification from "./info-sidebar/info-sidebar-notification";

export default {
  components: {
    InfoSidebarNotification
  },
  name: "navbar",
  computed:{
    getTariffName() {
      return this.$store.getters.getUserTariffRow;
    }
  },
  data() {
    return {
      MobileMenu: false,
      ProfileMenu: false,
      direction: 'ltr',
      drawerNotification: {visible: false},
      user: this.$store.getters.user,
      role: this.$store.getters.user.role_name,
      // csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    };
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    logoutForm() {
      // this.$refs.logoutForm.submit();
      Toast.fire({
        icon: "info",
        title: "Logout",
      });
      this.$store.dispatch('auth/logout').then(function() {
        window.location = "/login";
      });
    },
    routeAppendQuery(path, onlyFromRouteNames) {
      if(onlyFromRouteNames.includes(this.$route.name)){
        return path + '?' + qs.stringify(this.$route.query)
      }else{
        return path
      }
    },
    getFirstLetters(str) {
      if(!str){
        return '';
      }
      return str
          .split(' ')
          .map(word => word[0])
          .slice(0,2)
          .join('');
    }
  },
};
</script>

<style src="@/scss/main/header/index.scss" lang="scss"></style>