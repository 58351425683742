import axios from "axios";
import toastMessage from "../toastMessage";
import utils from "../../utils";
import store from "../index";
import router from "../../routes";
import {Form} from 'vform';
import qs from 'qs';

const actions = {
    getListTarrifOrders({commit, getters}) {
        return axios.get(process.env.VUE_APP_API_URL + 'tariff/orders?' + qs.stringify(getters.getFilterParams))
            .then((response) => {
                response.data.data.current_page = getters.getPage

                commit('updateTariffOrders', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },
    getOneOrder({commit}, data) {
        return axios.get(process.env.VUE_APP_API_URL + 'show/order/' + data)
            .then((response) => {
                commit('updateOneTariff', response.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },

    paidTariff({commit}, data) {
        let promise = axios.post(process.env.VUE_APP_API_URL + 'tariff/orders/'+ data.id, data);

        promise.then((response) => {
            if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
            commit('findReplaceTarrifs', response.data.data);
            if (response.data.data['user']['email'] === store.getters['user']['email']) {
                store.dispatch('users/getUser');
            }

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },

    createTariff({commit}, vForm = new Form()) {
        let promise = vForm.post(process.env.VUE_APP_API_URL + 'tariff/orders/created');

        promise.then((response) => {
            commit('prependTariffs', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    putTariff({commit}, vForm = new Form()) {
        let promise = vForm.put(process.env.VUE_APP_API_URL + 'tariff/orders/' + vForm.id);
        promise.then((response) => {
            if(!utils.isSuccessResponse(response)) { toastMessage.fire(response); return false; }
            commit('findReplaceTarrifs', response.data.data);

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    deleteTariff({commit}, id) {
        let promise = new Promise(function (resolve, reject) {
            return axios.delete(process.env.VUE_APP_API_URL + 'tariff/orders/' + id).then((response) => {
                if (response.data.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            });
        });
        promise.then((response) => {
            commit('findAndDeleteTariffs', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },

    clearPreview({commit}) {
        commit('updateOneTariff', {data: []});
    },

    setCurrentPage({commit, getters}, page){
        if(router.currentRoute.query.page !== undefined && Number(router.currentRoute.query.page) !== Number(page)
            || router.currentRoute.query.page === undefined && Number(page) > 1){
            commit('updatePage', page);
            //  commit('updateTotal', getters.get);
            commit('historyPush', getters.getFilterParams);
        }
    },

    setTotal({commit, getters}, total){
        commit('updateTotal', total);

        if((router.currentRoute.query.total !== undefined && Number(router.currentRoute.query.total) !== Number(total))
            || (router.currentRoute.query.total === undefined && Number(total) > 0)
        ){
            commit('historyPush', getters.getFilterParams);
        }
    },
    setEventFilter({commit, getters}){
        commit('updatePage', 1);
        //  commit('updateTotal', getters.getTotal);
        commit('historyPush', getters.getFilterParams);
    },

    setStatesFromQuery({commit}, params) {
        let  filters = params.filters;
        let  checkbox = params.checkbox;
        let  search = params.search;
        let  listNameId = params.list_name_id;
        let  typeDisplayContacts = params.type_display_contacts;
        let  page = params.page;
        let  total = params.total;

        if(page){
            commit('updatePage', page)
        }
        if(total){
            commit('updateTotal', total)
        }

        if(checkbox){
            store.dispatch('filters/setUpdateCheckboxesValues', checkbox);
        }else{
            store.dispatch('filters/setClearCheckboxesValues', checkbox);
        }

        if(filters){
            store.dispatch('filters/setUpdateFiltersValues', filters);
        }else{
            store.dispatch('filters/setClearFiltersValues');
        }

        if(search){
            store.dispatch('filters/setUpdateSearchesValues', search);
        }else{
            store.dispatch('filters/setClearSearchesValues', search);
        }

        if(listNameId){
            store.dispatch('listName/setListNameIdSelected', listNameId);
        }else{
            store.dispatch('listName/clearListNameIdSelected', listNameId)
        }

        if(typeDisplayContacts){
            commit('updateTypeDisplayContacts', typeDisplayContacts);
        }

    },

};

const state = {
    orders: {
        current_page: 0,
        data: [],
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
    },
    order: {
        data: []
    },
    searchOrders: {
        options: [],
        value: [],
        list: [],
        loading: false,
    }
};

const mutations = {
    updatePage(state, data) {
        state.orders.current_page = data;
    },
    updateTotal(state, data) {
        state.orders.total = data
    },

    historyPush(state, params) {
        clearTimeout(store.state.timers.historyPush);
        store.state.timers.historyPush = setTimeout(() => {
            router.push({query: qs.parse(params)}, () => {});
        }, 10); //ms
    },

    updateSearchUsersOptions(state, data) {
        state.searchOrders.options = data;
        state.searchOrders.list = data;
    },
    updateTariffOrders(state, data) {
        state.orders = data;
    },
    updateOneTariff(state, data) {
        state.order = data;
    },
    prependTariffs(state, data) {
        state.orders.data.unshift(data);
    },
    findReplaceTarrifs(state, data) {
        const item = state.orders.data.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    findAndDeleteTariffs(state, data) {
        const index = state.orders.data.findIndex(item => item.id === data.id);
        state.orders.data.splice(index, 1);
        state.list.idsOptions = utils.pluck(state.orders.data, 'id')
    },
};

const getters = {
    getTariffOrders(state) {
        return state.orders;
    },
    getSearchOrders(state) {
        return state.searchOrders;
    },
    getPage() {
        return state.orders.current_page;
    },
    getTotal() {
        return state.orders.total;
    },
    getOrder() {
        return state.order;
    },
    getFilterParams(state, getters){
        return {
            total: getters.getTotal,
            page: getters.getPage,
            filters: store.getters['filters/filtersValues'],
            checkbox: store.getters['filters/checkboxValues'],
            search: store.getters['filters/searchValues'],
            list_name_id: store.getters['listName/listNameIdSelected'],
            checked_bulk: store.getters['formCheckFilterMax/getCheckedBulk'],
            order_checked_all: store.getters['formCheckFilterMax/getOrderCheckedAll'],
            type_display_contacts: getters.getTypeDisplayContacts,
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
