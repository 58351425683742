import moment from "moment-timezone";
import store from '../../store/index';

const formatDateShort = (value) => {
    if(!value){
        return value;
    }
    if(store.getters.user.timezone){
        return moment(value).parseZone().tz(store.getters.user.timezone).format(`MMM DD, YYYY`);
    }else{
        return moment(value).parseZone().format(`MMM DD, YYYY`);
    }

}

export default formatDateShort;
