<template>
    <div v-if="user.can['contacts.add'] || user.can['contacts.edit']" class="modal fade " id="addNewContacts" tabindex="-1"
         role="dialog" aria-labelledby="addNewContacts"
         aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-scrollable modal-xl">
            <form @submit.prevent="editmode ? updateContacts() : createContact()" style="width: 100%" class="modal-form-company">
                <div class="modal-content edit-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-show="!editmode">Create New Contact</h5>
                        <h5 class="modal-title" v-show="editmode">Update Contact's Info</h5>
<!--                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
<!--                            <span aria-hidden="true">&times;</span>-->
<!--                        </button>-->
                      <div class="modal-content-container">
                        <el-input
                            placeholder="Search"
                            prefix-icon="icon-search"
                            class="search"
                            v-model="Searchinput">
                        </el-input>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="dialogVisible = true">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                    <div class="modal-body">
                        <div class="modal-body-top-content">
                          <h5>Public fields</h5>
                            <div v-if="user.can['contacts.field_company']" class="modal-body-container">
                              <label for="company">Company</label>
                              <el-input
                                  v-model="form.company"
                                  id="company"
                                  type="text"
                                  name="company"
                                  :class="{ 'is-invalid': form.errors.has('company') }">
                              </el-input>
                              <has-error :form="form" field="company"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_founded']" class="modal-body-container">
                              <label for="founded">Company founded</label>
                                <el-input
                                    v-model="form.founded"
                                    id="founded" type="text"
                                    name="founded"
                                    :class="{ 'is-invalid': form.errors.has('founded') }">
                                </el-input>
                                <has-error :form="form" field="founded"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_logo_company']" class="modal-body-container">
                              <label for="logo_company">Logo company</label>
                              <el-input
                                  v-model="form.logo_company"
                                  id="logo_company"
                                  type="text"
                                  name="logo_company"
                                  :class="{ 'is-invalid': form.errors.has('logo_company') }">
                              </el-input>
                              <has-error :form="form" field="logo_company"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_website']" class="modal-body-container">
                              <label for="website" class="col-sm-3 col-form-label">Website</label>
                                <el-input
                                    v-model="form.website"
                                    id="website"
                                    type="text"
                                    name="website"
                                    :class="{ 'is-invalid': form.errors.has('website') }">
                                </el-input>
                                <has-error :form="form" field="website"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_company_country']" class="modal-body-container">
                              <label for="company_country">Company country</label>
                                <el-input
                                    v-model="form.company_country" id="company_country" type="text"
                                    name="company_country"
                                    :class="{ 'is-invalid': form.errors.has('company_country') }">
                                </el-input>
                              <has-error :form="form" field="company_country"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_company_state_region']" class="modal-body-container">
                              <label for="company_state_region">Company state</label>
                                <el-input
                                    v-model="form.company_state_region" id="company_state_region" type="text"
                                    name="company_state_region"
                                    :class="{ 'is-invalid': form.errors.has('company_state_region') }">
                                </el-input>
                                <has-error :form="form" field="company_state_region"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_company_city']" class="modal-body-container">
                                        <label for="company_city">Company city</label>
                                            <el-input
                                                v-model="form.company_city" id="company_city" type="text" name="company_city"
                                                :class="{ 'is-invalid': form.errors.has('company_city') }">
                                            </el-input>
                                            <has-error :form="form" field="company_city"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_company_address']" class="modal-body-container">
                              <label for="company_address">Company address</label>
                              <el-input
                                  v-model="form.company_address" id="company_address" type="text"
                                  name="company_address"
                                  :class="{ 'is-invalid': form.errors.has('company_address') }">
                              </el-input>
                              <has-error :form="form" field="company_address"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_industry']" class="modal-body-container">
                              <label for="industry" class="col-sm-3 col-form-label">Industry</label>
                              <el-input
                                  v-model="form.industry" id="industry" type="text" name="industry"
                                  :class="{ 'is-invalid': form.errors.has('industry') }">
                              </el-input>
                              <has-error :form="form" field="industry"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_employees']" class="modal-body-container">
                              <label for="employees">Employees</label>
                              <el-input
                                  v-model="form.employees" id="employees" type="text" name="employees"
                                  :class="{ 'is-invalid': form.errors.has('employees') }">
                              </el-input>
                              <has-error :form="form" field="employees"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_first_name']" class="modal-body-container">
                              <label for="first_name">First name</label>
                              <el-input
                                  v-model="form.first_name" id="first_name" type="text"
                                  name="first_name"
                                  :class="{ 'is-invalid': form.errors.has('first name') }">
                              </el-input>
                              <has-error :form="form" field="first_name"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_last_name']" class="modal-body-container">
                              <label for="last_name">Last name</label>
                              <el-input
                                  v-model="form.last_name" id="last_name" type="text" name="last_name"
                                  :class="{ 'is-invalid': form.errors.has('last_name') }">
                              </el-input>
                              <has-error :form="form" field="last_name"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_title']"  class="modal-body-container">
                              <label for="title">Title</label>
                              <el-input
                                  v-model="form.title" id="title" type="text" name="title"
                                  :class="{ 'is-invalid': form.errors.has('title') }">
                              </el-input>
                              <has-error :form="form" field="title"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_linkedin_company']" class="modal-body-container">
                              <label for="linkedin_company">Linkedin company</label>
                              <el-input
                                  v-model="form.linkedin_company" id="linkedin_company" type="text"
                                  name="linkedin_company"
                                  :class="{ 'is-invalid': form.errors.has('linkedin_company') }">
                              </el-input>
                              <has-error :form="form" field="linkedin_company"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_contacts_location_country']" class="modal-body-container">
                              <label for="contacts_location_country">Contacts location country</label>
                              <el-input
                                  v-model="form.contacts_location_country"
                                  id="contacts_location_country" type="text"
                                  name="contacts_location_country"
                                  :class="{ 'is-invalid': form.errors.has('contacts_location_country') }">
                              </el-input>
                              <has-error :form="form" field="contacts_location_country"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_contacts_location_state']" class="modal-body-container">
                              <label for="contacts_location_state">Contacts location state</label>
                              <el-input
                                  v-model="form.contacts_location_state" id="contacts_location_state"
                                  type="text" name="contacts_location_state"
                                  :class="{ 'is-invalid': form.errors.has('contacts_location_state') }">
                              </el-input>
                              <has-error :form="form" field="contacts_location_state"></has-error>
                            </div>
                            <div v-if="user.can['contacts.field_contacts_location_city']" class="modal-body-container">
                              <label for="contacts_location_city">Contacts location city</label>
                                <el-input
                                    v-model="form.contacts_location_city" id="contacts_location_city"
                                    type="text" name="contacts_location_city"
                                    :class="{ 'is-invalid': form.errors.has('contacts_location_city') }">
                                </el-input>
                              <has-error :form="form" field="contacts_location_city"></has-error>
                            </div>
                                    <div v-if="user.can['contacts.field_ecommerce_scheme']" class="modal-body-container">
                                        <label for="ecommerce_scheme">Ecommerce scheme</label>
                                            <el-select v-model="ecommerce_value" id="ecommerce_scheme"
                                                    name="ecommerce_scheme"
                                                    :class="{ 'is-invalid': form.errors.has('ecommerce_scheme') }">
                                              <el-option
                                                v-for="item in ecommerce_options"
                                                :key="item.ecommerce_value"
                                                :label="item.ecommerce_label"
                                                id="ecommerce_scheme"
                                                :value="item.ecommerce_value"
                                              ></el-option>
                                            </el-select>
                                            <has-error :form="form" field="ecommerce_scheme"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_technologies']" class="modal-body-container">
                                        <label for="technologies">Technologies</label>
                                            <textarea v-model="form.technologies" id="technologies"
                                                      name="technologies"
                                                      :rows="textAreaLength(form.technologies)"
                                                      :class="{ 'is-invalid': form.errors.has('technologies') }"></textarea>
                                            <has-error :form="form" field="technologies"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_annual_revenue']" class="modal-body-container">
                                        <label for="annual_revenue">Annual revenue</label>
                                            <el-input
                                                v-model="form.annual_revenue" id="annual_revenue" type="text"
                                                name="annual_revenue"
                                                :class="{ 'is-invalid': form.errors.has('annual_revenue') }">
                                            </el-input>
                                            <has-error :form="form" field="annual_revenue"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_tag']"  class="modal-body-container">
                                        <label for="tag">Tag</label>
                                            <el-input
                                                v-model="form.tag" id="tag" type="text" name="tag"
                                                :class="{ 'is-invalid': form.errors.has('tag') }">
                                            </el-input>
                                            <has-error :form="form" field="tag"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_specialties']" class="modal-body-container">
                                        <label for="specialties">Specialties</label>
                                            <textarea v-model="form.specialties" id="specialties"
                                                      :rows="textAreaLength(form.specialties)"
                                                      class="input"
                                                      :class="{ 'is-invalid': form.errors.has('specialties') }"></textarea>
                                            <has-error :form="form" field="specialties"></has-error>
                                    </div>
                        </div>
                                <div class="modal-body-center-content">
                                    <!-- for sell fields -->
                                  <h5>Fields for sell</h5>
                                  <div v-if="user.can['contacts.field_corporate_email']" class="modal-body-container">
                                        <label for="corporateEmail">Corporate email</label>
                                            <el-input
                                                v-model="form.corporate_email" id="corporateEmail" type="email"
                                                name="corporate_email"
                                                :class="{ 'is-invalid': form.errors.has('corporate_email') }"
                                                required
                                            >

                                            </el-input>
                                            <has-error :form="form" field="corporate_email"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_linkedin_person']" class="modal-body-container">
                                        <label for="linkedin_person">Linkedin person</label>
                                            <el-input
                                                v-model="form.linkedin_person" id="linkedin_person" type="text"
                                                name="linkedin_person"
                                                :class="{ 'is-invalid': form.errors.has('linkedin_person') }">
                                            </el-input>
                                            <has-error :form="form" field="linkedin_person"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_personal_phone_number']" class="modal-body-container">
                                        <label for="personal_phone_number">Personal phone number</label>
                                            <el-input
                                                v-model="form.personal_phone_number" id="personal_phone_number"
                                                type="text"
                                                name="personal_phone_number"
                                                :class="{ 'is-invalid': form.errors.has('personal_phone_number') }">
                                            </el-input>
                                            <has-error :form="form" field="personal_phone_number"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_contacts_facebook']" class="modal-body-container">
                                        <label for="contacts_facebook">Contacts facebook</label>
                                            <el-input
                                                v-model="form.contacts_facebook" id="contacts_facebook" type="text"
                                                name="contacts_facebook"
                                                :class="{ 'is-invalid': form.errors.has('contacts_facebook') }">
                                            </el-input>
                                            <has-error :form="form" field="contacts_facebook"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_contacts_twitter']" class="modal-body-container">
                                        <label for="contacts_twitter">Contacts twitter</label>
                                            <el-input
                                                v-model="form.contacts_twitter" id="contacts_twitter" type="text"
                                                name="contacts_twitter"
                                                :class="{ 'is-invalid': form.errors.has('contacts_twitter') }">
                                            </el-input>
                                            <has-error :form="form" field="contacts_twitter"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_company_phone']" class="modal-body-container">
                                        <label for="company_phone" >Company phone</label>
                                            <el-input
                                                v-model="form.company_phone" id="company_phone" type="text"
                                                name="company_phone"
                                                :class="{ 'is-invalid': form.errors.has('company_phone') }">
                                            </el-input>
                                            <has-error :form="form" field="company_phone"></has-error>
                                    </div>
                                  <div v-if="user.can['contacts.field_last_funding_type']" class="modal-body-container">
                                    <label for="last_funding_type">Last funding type</label>
                                    <el-input
                                        v-model="form.last_funding_type" id="last_funding_type" type="text"
                                        name="last_funding_type"
                                        :class="{ 'is-invalid': form.errors.has('last_funding_type') }">
                                    </el-input>
                                    <has-error :form="form" field="last_funding_type"></has-error>
                                  </div>
                                  <div v-if="user.can['contacts.field_last_funding_round_value']" class="modal-body-container">
                                    <label for="last_funding_round_value">Last funding round value</label>
                                    <el-input
                                        v-model="form.last_funding_round_value" id="last_funding_round_value" type="text"
                                        name="last_funding_round_value"
                                        :class="{ 'is-invalid': form.errors.has('last_funding_round_value') }">
                                    </el-input>
                                    <has-error :form="form" field="last_funding_round_value"></has-error>
                                  </div>
                                    <div v-if="user.can['contacts.field_total_funding']" class="modal-body-container">
                                        <label for="total_funding">Total funding</label>
                                            <el-input
                                                v-model="form.total_funding" id="total_funding" type="text"
                                                name="total_funding"
                                                :class="{ 'is-invalid': form.errors.has('total_funding') }">
                                            </el-input>
                                            <has-error :form="form" field="total_funding"></has-error>
                                    </div>

                                    <div v-if="user.can['contacts.field_last_funding_round_name']" class="modal-body-container">
                                        <label for="last_funding_round_name">Last funding round name</label>
                                            <el-input
                                                v-model="form.last_funding_round_name" id="last_funding_round_name" type="text"
                                                name="last_funding_round_name"
                                                :class="{ 'is-invalid': form.errors.has('last_funding_round_name') }">
                                            </el-input>
                                            <has-error :form="form" field="last_funding_round_name"></has-error>
                                    </div>
                                    <div v-if="user.can['contacts.field_last_funding_round_date']" class="modal-body-container last-funding">
                                        <label for="last_funding_round_date">Last funding round date</label>
                                            <el-input
                                                v-model="form.last_funding_round_date" id="last_funding_round_date" type="text"
                                                name="last_funding_round_date"
                                                :class="{ 'is-invalid': form.errors.has('last_funding_round_date') }">
                                            </el-input>
                                            <has-error :form="form" field="last_funding_round_date"></has-error>
                                    </div>
                                  </div>
                                    <!--  only admin fields -->
                                    <div class="modal-body-bottom-content">
                                      <h5>Fields for admin only</h5>
                                      <div v-if="user.can['contacts.field_personal_email']" class="modal-body-container">
                                        <label for="personalEmail" class="label-hidden">Personal email </label>
                                            <el-input
                                                v-model="form.personal_email" id="personalEmail" type="email"
                                                name="personal_email"
                                                :class="{ 'is-invalid': form.errors.has('personal_email') }">
                                            </el-input>
                                            <has-error :form="form" field="personal_email"></has-error>
                                      </div>
                                    </div>
                                    <!--  only admin fields -->
                                    <div class="modal-body-date-content">
                                      <h5>System fields</h5>
                                    <div v-if="user.can['contacts.field_email_status']" class="modal-body-container">
                                        <label for="email_status" class="label-hidden">Status email </label>
                                            <el-select v-model="email_status_value" id="email_status"
                                                    name="email_status"
                                                    :class="{ 'is-invalid': form.errors.has('email_status') }">
                                              <el-option
                                                  v-for="item in email_status_options"
                                                  :key="item.email_status_value"
                                                  :label="item.email_status_label"
                                                  id="email_status"
                                                  :value="item.email_status_value"
                                              ></el-option>
                                            </el-select>
                                            <has-error :form="form" field="email_status"></has-error>
                                    </div>
                                      <div class="data_updated_company">
                                        <p>Data updated</p>
                                        <p>2022-04-12 09:09:22</p>
                                      </div>
                                    </div>
                    </div>
                    <div class="modal-footer">
                      <div class="pos-start">
                        <el-checkbox v-model="form.update_without_null">Update without null</el-checkbox>
                        <el-checkbox v-model="form.update_by_website">Update by 'website'</el-checkbox>
                        <el-checkbox v-model="form.update_all_contacts">Update all contacts</el-checkbox>
                      </div>
                        <div class="modal-footer-btn">
                          <el-button type="button" data-dismiss="modal" class="btn btn-secondary">Cancel</el-button>
                          <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import Swal from 'sweetalert2';
    import $ from 'jquery';
    import 'sweetalert2/dist/sweetalert2.min.css';
    import {Form} from 'vform'

    export default {
        name: "CompanyCardContactsEdit",
        mounted(){
            $('#addNewContacts').on("hide.bs.modal", this.askHideModal)
        },
        watch: {
            addNewContacts(isModalContactsEdit) {  // modal
                if(isModalContactsEdit){
                    this.confirmedHide = false;
                    $('#addNewContacts').modal('show');
                }

                if(this.getModalContactsData.id){
                    // edit
                    this.editmode = true;
                    this.form.reset();
                    this.form.clear();
                    this.form.fill(this.getModalContactsData);
                }else{
                    // new
                    this.editmode = false;
                }
            }
        },
        computed: {
            ...mapGetters('company',
                ['getCompany','getModalContactsData']
            ),
            ...mapGetters({
                addNewContacts: 'company/getModalContactsEdit'
            }),
        },
        data() {
            return {
                Searchinput: '',
                user: this.$store.getters.user,
                editmode: false,
                confirmedHide: false,
                form: new Form({
                    id: null,
                    company: null,
                    website: null,
                    company_country: null,
                    company_state_region: null,
                    company_city: null,
                    company_address: null,
                    industry: null,
                    employees: null,
                    first_name: null,
                    last_name: null,
                    title: null,
                    corporate_email: null,
                    linkedin_person: null,
                    linkedin_company: null,
                    personal_email: null,
                    personal_phone_number: null,
                    company_phone: null,
                    technologies: null,
                    annual_revenue: null,
                    specialties: null,
                    contacts_location_country: null,
                    contacts_location_state: null,
                    contacts_location_city: null,
                    contacts_facebook: null,
                    contacts_twitter: null,
                    ecommerce_scheme: null,
                    created_at: null,
                    updated_at: null,
                    data_updated_at: null,
                    email_status: null,
                    founded: null,
                    logo_company: null,

                    total_funding: null,
                    last_funding_type: null,
                    last_funding_round_name: null,
                    last_funding_round_date: null,
                    last_funding_round_value: null,
                    // company_id: null, // for create // need fill from current company
                    option_edit_from_company_page: null,
                }),
              ecommerce_options: [{
                ecommerce_value: 'B2B',
                ecommerce_label: 'B2B'
              }, {
                ecommerce_value: 'B2C',
                ecommerce_label: 'B2C'
              }],
              ecommerce_value: '',
              email_status_options : [{
                email_status_value: 'Choose',
                email_status_label: 'Choose'
              }, {
                email_status_value: 'valid',
                email_status_label: 'valid'
              }, {
                email_status_value: 'invalid',
                email_status_label: 'invalid'
              },{
                email_status_value: 'accept_all',
                email_status_label: 'accept_all'
              }],
              email_status_value: '',
            }
        },
        methods: {
            updateContacts() {
                this.$Progress.start();
                this.$store.dispatch('people/putPeople', this.form)
                    .then(() => {
                        this.confirmedHide = true;
                        $('#addNewContacts').modal('hide');
                        this.$Progress.finish();
                        //  Fire.$emit('AfterCreate');
                    })
                    .catch(() => {
                        this.$Progress.fail();
                    });
            },
            createContact() {
                this.$Progress.start();
                let promise = this.$store.dispatch('people/createPeople', this.form);
                promise.then(() => {
                    this.confirmedHide = true;
                    $('#addNewContacts').modal('hide');
                    this.$Progress.finish();
                }).catch(() => {
                    this.$Progress.fail();
                });
            },
            textAreaLength(string) {
                if (!string) {
                    return 1;
                }
                let first = Math.round(string.length / 43) + 1;
                let second = (string.match(/\n/g) || '').length + 1;
                return first > second ? first : second;
            },
            askHideModal(eventModal){
                if(this.confirmedHide === false){
                    eventModal.preventDefault();
                    Swal.fire({
                        title: 'Exit edit mode?',
                        text: "Сhanges made will not be saved. You can't get it back!",
                        showCancelButton: true,
                        cancelButtonColor: '#3085d6',
                        confirmButtonColor: '#d33',
                        confirmButtonText: 'Exit',
                        // customClass: 'sweetalert-lg'
                      customClass: {
                        container: 'container-class',
                        popup: 'popup-class',
                        header: 'header-class',
                        title: 'title-class',
                        closeButton: 'close-button-class',
                        content: 'content-class',
                        input: 'input-class',
                        actions: 'actions-class',
                        confirmButton: 'confirm-button-class',
                        cancelButton: 'cancel-button-class',
                        footer: 'footer-class'
                      }
                    }).then((result) => {
                        if (result.value) {
                            this.confirmedHide = true;
                            $('#addNewContacts').modal('hide');
                            this.$store.dispatch('company/setModalContactsEdit', false);
                        }
                    })
                }else {
                    this.$store.dispatch('company/setModalContactsEdit', false);
                }
            },
        },

    }
</script>

<style lang="scss">
.edit-content {
  margin: 0 auto 0;
  -webkit-box-shadow: 0px 0px 80px rgb(57 60 88 / 19%);
  box-shadow: 0px 0px 80px rgb(57 60 88 / 19%);
  border-radius: 12px;
  margin-top: 0 !important;
}

.last-funding {
  margin-top: -10px !important;
}
.header-class {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: baseline;
  padding: 0;
}
.popup-class {
  width: 384px;
  border-radius: 12px;
  padding: 32px;
}


.content-class {
  word-break: break-word;
  align-items: baseline;
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #4C5765;
  padding-bottom: 32px;
  text-align: left;
  padding-right: 0;
}
.title-class {
  font-weight: 700;
  font-size: 20px;
  line-height: 140%;
  color: #040F24;
}
.actions-class {
  padding: 10px 20px 20px;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row-reverse;
  z-index: 1;
  box-sizing: border-box;
  flex-wrap: wrap;
  align-items: center;
  justify-content: right;
  width: 100%;
  margin: 0;
  padding: 0;
}
.confirm-button-class {
  padding: 10px 20px;
  background: #FA6F5C !important;
  border: none;
  border-radius: 12px !important;
  font-weight: 800;
  font-size: 14px !important;
  line-height: 140%;
  color: #FFFFFF;
  margin-left: 0;
  border: 0;
  background: initial;

}
.cancel-button-class {
  font-weight: 600;
  font-size: 14px !important;
  line-height: 140%;
  color: #0D7CFF !important;
  background: transparent;
  background-color: white !important;
  border: none;
  margin-right: 8px;
  padding: 0;

}
.swal2-styled.swal2-cancel:hover {
  background-color: white !important;
  background-image: none !important;
}
.swal2-cancel:hover {
  background-color: white !important;
  background-image: none !important;
}
.swal2-styled:hover {
  background-image: none !important;
}
.swal2-content {
  padding-left: 0;
  //padding-right: 32px;
  word-break: break-word;
}

.sweet-alert { margin: auto; transform: translateX(-50%); }
.sweet-alert.sweetalert-lg { width: 800px; }
</style>
