<template>
  <div class="edit-contact-form">
    <div class="top edit-contact-position">
      <p v-show="!editmode" class="title">Create New Contact</p>
      <p v-show="editmode" class="title">Update Contact's Info</p>
      <div class="right">
        <el-input
            placeholder="Search"
            prefix-icon="icon-search"
            class="search"
            v-model="ModalEditContactSearchinput"
        ></el-input>
      </div>
    </div>
      <form @submit.prevent="editmode ? updateContacts() : createContact()">
        <div class="scroll-area">
          <div class="container">
            <p class="fields-title">Public fields!</p>
            <div class="flex">
              <div class="left">
                <div v-if="user.can['contacts.field_company']">
                  <label for="company">Company</label>
                  <input
                      v-model="form.company"
                      id="company"
                      type="text"
                      name="company"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('company') }"
                  />
                  <has-error :form="form" field="company"></has-error>
                </div>

                <div v-if="user.can['contacts.field_founded']">
                  <label for="founded">Company founded</label>
                  <input
                      v-model="form.founded"
                      id="founded"
                      type="text"
                      name="founded"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('founded') }"
                  />
                  <has-error :form="form" field="founded"></has-error>
                </div>

                <div v-if="user.can['contacts.field_logo_company']">
                  <label for="logo_company">Logo company</label>
                  <input
                      v-model="form.logo_company"
                      id="logo_company"
                      type="text"
                      name="logo_company"
                      class="input"
                      :class="{'is-invalid': form.errors.has('logo_company'),}"
                  />
                  <has-error :form="form" field="logo_company"></has-error>
                </div>

                <div v-if="user.can['contacts.field_website']">
                  <label for="website">Website</label>
                  <input
                      v-model="form.website"
                      id="website"
                      type="text"
                      name="website"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('website') }"
                  />
                  <has-error :form="form" field="website"></has-error>
                </div>

                <div v-if="user.can['contacts.field_company_country']">
                  <label for="company_country">Company country</label>
                  <input
                      v-model="form.company_country"
                      id="company_country"
                      type="text"
                      name="company_country"
                      class="input"
                      :class="{'is-invalid': form.errors.has('company_country'),}"
                  />
                  <has-error :form="form" field="company_country"></has-error>
                </div>

                <div v-if="user.can['contacts.field_company_state_region']">
                  <label for="company_state_region">Company state</label>
                  <input
                      v-model="form.company_state_region"
                      id="company_state_region"
                      type="text"
                      name="company_state_region"
                      class="input"
                      :class="{'is-invalid': form.errors.has('company_state_region'),}"
                  />
                  <has-error :form="form" field="company_state_region"></has-error>
                </div>

                <div v-if="user.can['contacts.field_company_city']">
                  <label for="company_city">Company city</label>
                  <input
                      v-model="form.company_city"
                      id="company_city"
                      type="text"
                      name="company_city"
                      class="input"
                      :class="{'is-invalid': form.errors.has('company_city'),}"
                  />
                  <has-error :form="form" field="company_city"></has-error>
                </div>

                <div v-if="user.can['contacts.field_company_address']">
                  <label for="company_address">Company address</label>
                  <input
                      v-model="form.company_address"
                      id="company_address"
                      type="text"
                      name="company_address"
                      class="input"
                      :class="{'is-invalid': form.errors.has('company_address'),}"
                  />
                  <has-error :form="form" field="company_address"></has-error>
                </div>

                <div v-if="user.can['contacts.field_industry']">
                  <label for="industry">Industry</label>
                  <input
                      v-model="form.industry"
                      id="industry"
                      type="text"
                      name="industry"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('industry') }"
                  />
                  <has-error :form="form" field="industry"></has-error>
                </div>
                <div v-if="user.can['contacts.field_employees']">
                  <label for="employees">Employees</label>
                  <input
                      v-model="form.employees"
                      id="employees"
                      type="text"
                      name="employees"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('employees'),}"
                  />
                  <has-error :form="form" field="employees"></has-error>
                </div>
              </div>
              <div class="right">
                <div v-if="user.can['contacts.field_first_name']">
                  <label for="first_name">First name</label>
                  <input
                      v-model="form.first_name"
                      id="first_name"
                      type="text"
                      name="first_name"
                      class="input"
                      :class="{'is-invalid': form.errors.has('first name'),}"
                  />
                  <has-error :form="form" field="first_name"></has-error>
                </div>
                <div v-if="user.can['contacts.field_last_name']">
                  <label for="last_name">Last name</label>
                  <input
                      v-model="form.last_name"
                      id="last_name"
                      type="text"
                      name="last_name"
                      class="input"
                      :class="{'is-invalid': form.errors.has('last_name'),}"
                  />
                  <has-error :form="form" field="last_name"></has-error>
                </div>
                <div v-if="user.can['contacts.field_title']">
                  <label for="title">Title</label>
                  <input
                      v-model="form.title"
                      id="title"
                      type="text"
                      name="title"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('title') }"
                  />
                  <has-error :form="form" field="title"></has-error>
                </div>

                <div v-if="user.can['contacts.field_linkedin_company']">
                  <label for="linkedin_company">Linkedin company</label>
                  <input
                      v-model="form.linkedin_company"
                      id="linkedin_company"
                      type="text"
                      name="linkedin_company"
                      class="input"
                      :class="{'is-invalid': form.errors.has('linkedin_company'),}"
                  />
                  <has-error :form="form" field="linkedin_company"></has-error>
                </div>

                <div v-if="user.can['contacts.field_contacts_location_country']">
                  <label for="contacts_location_country">Contacts location country</label>
                  <input
                      v-model="form.contacts_location_country"
                      id="contacts_location_country"
                      type="text"
                      name="contacts_location_country"
                      class="input"
                      :class="{'is-invalid': form.errors.has('contacts_location_country'),}"
                  />
                  <has-error :form="form" field="contacts_location_country"></has-error>

                </div>

                <div v-if="user.can['contacts.field_contacts_location_state']">
                  <label for="contacts_location_state">Contacts location state</label>
                  <input
                      v-model="form.contacts_location_state"
                      id="contacts_location_state"
                      type="text"
                      name="contacts_location_state"
                      class="input"
                      :class="{'is-invalid': form.errors.has('contacts_location_state'),}"
                  />
                  <has-error :form="form" field="contacts_location_state"></has-error>
                </div>

                <div v-if="user.can['contacts.field_contacts_location_city']">
                  <label for="contacts_location_city">Contacts location city</label>
                  <input
                      v-model="form.contacts_location_city"
                      id="contacts_location_city"
                      type="text"
                      name="contacts_location_city"
                      class="input"
                      :class="{'is-invalid': form.errors.has('contacts_location_city' ),}"
                  />
                  <has-error :form="form" field="contacts_location_city"></has-error>
                </div>

                <div v-if="user.can['contacts.field_ecommerce_scheme']">
                  <label for="ecommerce_scheme">Ecommerce scheme</label>
                  <select
                      v-model="form.ecommerce_scheme"
                      id="ecommerce_scheme"
                      name="ecommerce_scheme"
                      class="input"
                      :class="{'is-invalid': form.errors.has('ecommerce_scheme'),}">
                    <option value="">Choose</option>
                    <option value="B2B">B2B</option>
                    <option value="B2C">B2C</option>
                  </select>
                  <has-error :form="form" field="ecommerce_scheme"></has-error>
                </div>

                <div v-if="user.can['contacts.field_technologies']">
                  <label for="technologies">Technologies</label>
                  <textarea
                      v-model="form.technologies"
                      id="technologies"
                      name="technologies"
                      class="input textarea-style"
                      :rows="textAreaLength(form.technologies)"
                      :class="{ 'is-invalid': form.errors.has('technologies'),}"
                  ></textarea>
                  <has-error :form="form" field="technologies"></has-error>
                </div>

                <div v-if="user.can['contacts.field_annual_revenue']">
                  <label for="annual_revenue">Annual revenue</label>
                  <input
                      v-model="form.annual_revenue"
                      id="annual_revenue"
                      type="text"
                      name="annual_revenue"
                      class="input"
                      :class="{'is-invalid': form.errors.has('annual_revenue'),}"
                  />
                  <has-error :form="form" field="annual_revenue"></has-error>
                </div>

                <div v-if="user.can['contacts.field_tag']" class="row mb-3">
                  <label for="tag">Tag</label>
                  <input
                      v-model="form.tag"
                      id="tag"
                      type="text"
                      name="tag"
                      class="input"
                      :class="{ 'is-invalid': form.errors.has('tag') }"
                  />
                  <has-error :form="form" field="tag"></has-error>
                </div>

                <div v-if="user.can['contacts.field_specialties']">
                  <label for="specialties">Specialties</label>
                  <textarea
                      v-model="form.specialties"
                      id="specialties"
                      class="input textarea-style"
                      :rows="textAreaLength(form.specialties)"
                      :class="{'is-invalid': form.errors.has('specialties'),}"
                  ></textarea>
                  <has-error :form="form" field="specialties"></has-error>
                </div>
              </div>
            </div>

            <p class="fields-title">Fields for sell</p>
            <div class="flex">
              <div class="left">
                <div v-if="user.can['contacts.field_corporate_email']">
                  <label for="corporateEmail">Corporate email</label>
                  <input
                      v-model="form.corporate_email"
                      id="corporateEmail"
                      type="email"
                      name="corporate_email"
                      class="input"
                      :class="{'is-invalid': form.errors.has('corporate_email'),}"
                      required
                  />
                  <has-error :form="form" field="corporate_email"></has-error>
                </div>

                <div v-if="user.can['contacts.field_linkedin_person']">
                  <label for="linkedin_person">Linkedin person</label>
                  <input
                      v-model="form.linkedin_person"
                      id="linkedin_person"
                      type="text"
                      name="linkedin_person"
                      class="input"
                      :class="{'is-invalid': form.errors.has('linkedin_person'),}"
                  />
                  <has-error :form="form" field="linkedin_person"></has-error>
                </div>

                <div v-if="user.can['contacts.field_personal_phone_number']">
                  <label for="personal_phone_number">Personal phone number</label>
                  <input
                      v-model="form.personal_phone_number"
                      id="personal_phone_number"
                      type="text"
                      name="personal_phone_number"
                      class="input"
                      :class="{'is-invalid': form.errors.has('personal_phone_number'),}"
                  />
                  <has-error :form="form" field="personal_phone_number"></has-error>
                </div>

                <div v-if="user.can['contacts.field_contacts_facebook']">
                  <label for="contacts_facebook">Contacts facebook</label>
                  <input
                      v-model="form.contacts_facebook"
                      id="contacts_facebook"
                      type="text"
                      name="contacts_facebook"
                      class="input"
                      :class="{'is-invalid': form.errors.has('contacts_facebook'),}"
                  />
                  <has-error :form="form" field="contacts_facebook"></has-error>
                </div>

                <div v-if="user.can['contacts.field_contacts_twitter']">
                  <label for="contacts_twitter">Contacts twitter</label>
                  <input
                      v-model="form.contacts_twitter"
                      id="contacts_twitter"
                      type="text"
                      name="contacts_twitter"
                      class="input"
                      :class="{'is-invalid': form.errors.has('contacts_twitter'),}"
                  />
                  <has-error :form="form" field="contacts_twitter"></has-error>
                </div>
              </div>
              <div class="right">
                <div v-if="user.can['contacts.field_company_phone']">
                  <label for="company_phone">Company phone</label>
                  <input
                      v-model="form.company_phone"
                      id="company_phone"
                      type="text"
                      name="company_phone"
                      class="input"
                      :class="{'is-invalid': form.errors.has('company_phone'),}"
                  />
                  <has-error :form="form" field="company_phone"></has-error>
                </div>

                <div v-if="user.can['contacts.field_total_funding']">
                  <label for="total_funding">Total funding</label>
                  <input
                      v-model="form.total_funding"
                      id="total_funding"
                      type="text"
                      name="total_funding"
                      class="input"
                      :class="{'is-invalid': form.errors.has('total_funding'),}"
                  />
                  <has-error :form="form" field="total_funding"></has-error>
                </div>

                <div v-if="user.can['contacts.field_last_funding_type']">
                  <label for="last_funding_type">Last funding type</label>
                  <input
                      v-model="form.last_funding_type"
                      id="last_funding_type"
                      type="text"
                      name="last_funding_type"
                      class="input"
                      :class="{'is-invalid': form.errors.has('last_funding_type'), }"
                  />
                  <has-error :form="form" field="last_funding_type"></has-error>
                </div>

                <div v-if="user.can['contacts.field_last_funding_round_name']">
                  <label for="last_funding_round_name">Last funding round name</label>
                  <input
                      v-model="form.last_funding_round_name"
                      id="last_funding_round_name"
                      type="text"
                      name="last_funding_round_name"
                      class="input"
                      :class="{'is-invalid': form.errors.has('last_funding_round_name'),}"
                  />
                  <has-error :form="form" field="last_funding_round_name"></has-error>
                </div>

                <div v-if="user.can['contacts.field_last_funding_round_date']">
                  <label for="last_funding_round_date">Last funding round date</label>
                  <input
                      v-model="form.last_funding_round_date"
                      id="last_funding_round_date"
                      type="text"
                      name="last_funding_round_date"
                      class="input"
                      :class="{'is-invalid': form.errors.has('last_funding_round_date'),}"
                  />
                  <has-error :form="form" field="last_funding_round_date"></has-error>
                </div>

                <div v-if="user.can['contacts.field_last_funding_round_value']">
                  <label for="last_funding_round_value">Last funding round value</label>
                  <input
                      v-model="form.last_funding_round_value"
                      id="last_funding_round_value"
                      type="text"
                      name="last_funding_round_value"
                      class="input"
                      :class="{'is-invalid': form.errors.has( 'last_funding_round_value' ),}"
                  />
                  <has-error :form="form" field="last_funding_round_value"></has-error>
                </div>
              </div>
            </div>

            <div class="flex">
              <div class="left">
                <p class="fields-title">Fields for admin only</p>
                <div v-if="user.can['contacts.field_personal_email']">
                  <label for="personalEmail">Personal email</label>
                  <input
                      v-model="form.personal_email"
                      id="personalEmail"
                      type="email"
                      name="personal_email"
                      class="input"
                      :class="{'is-invalid': form.errors.has('personal_email'),}"
                  />
                  <has-error :form="form" field="personal_email"></has-error>
                </div>
              </div>
              <div class="right">
                <p class="fields-title">System fields</p>
                <div v-if="user.can['contacts.field_email_status']">
                  <label for="email_status">Status email</label>
                  <select
                      v-model="form.email_status"
                      id="email_status"
                      name="email_status"
                      class="input"
                      :class="{'is-invalid': form.errors.has('email_status'),}">
                    <option value="">Choose</option>
                    <option value="valid">valid</option>
                    <option value="invalid">invalid</option>
                    <option value="accept_all">accept_all</option>
                  </select>
                  <has-error :form="form" field="email_status"></has-error>
                </div>
              </div>
            </div>

            <div v-if="user.can['contacts.field_data_updated_at']">
              <label>Data updated at</label>
              <div class="col-sm-9">

                <has-error :form="form" field="data_updated_at"></has-error>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="left">
            <div class="pos-start">
              <el-checkbox v-model="form.update_without_null">Update without null</el-checkbox>
              <el-checkbox v-model="form.update_by_website">Update by 'website'</el-checkbox>
              <el-checkbox v-model="form.update_all_contacts">Update all contacts</el-checkbox>
            </div>
          </div>
          <div class="right">
            <button
                @click="ModalEditContactVisible = false"
                type="button"
                class="button button--cancel small"
            >Close</button>
            <button
                v-show="editmode"
                type="submit"
                @click="ModalEditContactVisible = false"
                class="button button--blue small"
            >Update</button>
            <button
                v-show="!editmode"
                type="submit"
                @click="ModalEditContactVisible = false"
                class="button button--blue small"
            >Create</button>
          </div>
        </div>
      </form>
  </div>
</template>
<script>
import {Form} from "vform";
import utils from "../../../../utils";

export default {
  mounted() {
    this.$store.dispatch("formCheckFilterMax/getCheckedIds");
  },
  created() {
    this.form.reset();
    this.form.clear();
    this.form.fill(this.contact);
  },
  props: {
    editmode: Boolean,
    contact: Object,
  },
  data() {
    return {
      user: this.$store.getters.user,
      confirmedHide: false,
      form: new Form({
        id: null,
        company: null,
        website: null,
        company_country: null,
        company_state_region: null,
        company_city: null,
        company_address: null,
        industry: null,
        employees: null,
        first_name: null,
        last_name: null,
        title: null,
        corporate_email: null,
        linkedin_person: null,
        linkedin_company: null,
        personal_email: null,
        personal_phone_number: null,
        company_phone: null,
        technologies: null,
        annual_revenue: null,
        specialties: null,
        contacts_location_country: null,
        contacts_location_state: null,
        contacts_location_city: null,
        contacts_facebook: null,
        contacts_twitter: null,
        ecommerce_scheme: null,
        created_at: null,
        updated_at: null,
        data_updated_at: null,
        email_status: null,
        founded: null,
        logo_company: null,

        total_funding: null,
        last_funding_type: null,
        last_funding_round_name: null,
        last_funding_round_date: null,
        last_funding_round_value: null,
      }),
      drawerContactDetails: {visible: false, contact: null},
      drawerCompanyDetails: {visible: false, company_id: null},
      ModalEditContactVisible: false,
      ModalEditContactSearchinput: '',
      seen: true,
    };
  },
  methods: {
    createContact() {
      this.$Progress.start();
      let promise = this.$store.dispatch("people/createPeople", this.form);
      promise
          .then(() => {
            this.confirmedHide = true;
            this.$Progress.finish();
          })
          .catch(() => {
            this.$Progress.fail();
          });
    },
    editModal(contact) {
      this.ModalEditContactVisible = false;
      // this.editmode = true;
      this.confirmedHide = false;
      this.form.reset();
      this.form.clear();
      this.form.fill(contact)
    },
    textAreaLength(string) {
      if (!string) {
        return 1;
      }
      let first = Math.round(string.length / 43) + 1;
      let second = (string.match(/\n/g) || "").length + 1;
      return first > second ? first : second;
    },
    updateContacts() {
      this.$Progress.start();
      this.$store
          .dispatch("people/putPeople", this.form)
          .then((response) => {
            if (!utils.isSuccessResponse(response)) {
              this.$Progress.fail();
              return false;
            }
            this.confirmedHide = true;
            this.$Progress.finish();
            //  Fire.$emit('AfterCreate');
          })
          .catch(() => {
            this.$Progress.fail();
          });
    },
    activeColumn(field, perm) {
      if (!this.user.can[perm]) {
        console.log("cant perm:" + perm);
        return false;
      }

      if (!this.getActiveColumn[field]) {
        console.log("not active:" + field);

        return false;
      }
      return true;
    },
  },
};

</script>
<style>
.input {
  height: auto;
  padding: 10px 12px;
  margin-bottom: 24px;
}
textarea {
  min-height: 80px;
}
textarea::-webkit-resizer {
   background: url(~@/assets/images/resize.svg);
   width: 10px;
   height: 5px;
   background-repeat: no-repeat;
 }
.edit-contact-form {
  background: #FFFFFF;
}
</style>