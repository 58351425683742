import Vue from 'vue';
import VueRouter from 'vue-router';
import qs from 'qs';
import store from './store';
import CompanyCardContactsListTab from "./components/pages/company/partials/CompanyCardContactsListTab";
// import CompanyCardEditTab from "./components/pages/company/partials/CompanyCardEditTab";
import DefaultLayout from './components/layouts/DefaultLayout.vue'
import AuthLayout from './components/layouts/AuthLayout.vue'
import UpgradePlanLayout from './components/layouts/UpgradePlanLayout.vue'
import SavedSearchesLayout from './components/layouts/SavedSearchesLayout.vue'
import SavedListLayout from './components/layouts/SavedListLayout.vue'
import CompanyCardInfo from "@/components/pages/company/partials/CompanyCardInfo";
import SavedListCompanyLayout from './components/layouts/SavedListCompanyLayout.vue'
import SavedListContactsLayout from './components/layouts/SavedListContactsLayout.vue'

Vue.use(VueRouter);

Vue.component('default-layout', DefaultLayout)
Vue.component('auth-layout', AuthLayout)
Vue.component('upgrade-plan-layout', UpgradePlanLayout)
Vue.component('saved-searches-layout', SavedSearchesLayout)
Vue.component('saved-list-layout', SavedListLayout)
// Vue.component('saved-list-page-company-layout', SavedListCompanyLayout)
Vue.component('saved-list-company-layout', SavedListCompanyLayout)
Vue.component('saved-list-contacts-layout', SavedListContactsLayout)


Vue.component('Navbar', require('./components/navbar.vue').default);
Vue.component('SidebarMenu', require('./components/sidebar-menu.vue').default);
Vue.component('FooterTemplate', require('./components/footer-template.vue').default);
Vue.component('ContentHeader', require('./components/content-header.vue').default);

// -- Main pages
// const StartPage = () => import(
//     /* webpackChunkName: "group-start" */
//     /* webpackPrefetch: true */
//     './components/pages/index/StartPage.vue');
const Register = () => import(
    /* webpackChunkName: "group-auth" */
    /* webpackPrefetch: true */
    './components/pages/auth/Register.vue');
const EmailVerify = () => import(
    /* webpackChunkName: "group-auth" */
    /* webpackPrefetch: true */
    './components/pages/auth/EmailVerify.vue');
const Login = () => import(
    /* webpackChunkName: "group-auth" */
    /* webpackPrefetch: true */
    './components/pages/auth/Login.vue')
const PasswordReset = () => import(
    /* webpackChunkName: "group-auth" */
    /* webpackPrefetch: true */
    './components/pages/auth/PasswordReset.vue');
const ChangePassword = () => import(
    /* webpackChunkName: "group-auth" */
    /* webpackPrefetch: true */
    './components/pages/auth/ChangePassword.vue');
const Home = () => import(
    /* webpackChunkName: "group-home" */
    /* webpackPrefetch: true */
    './components/pages/Home.vue');
const Dashboard = () => import(
    /* webpackChunkName: "group-dashboard" */
    /* webpackPrefetch: true */
    './components/pages/Dashboard.vue');
const CommonProfile = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/pages/profile/Common.vue');
const Profile = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/pages/profile/account/Profile.vue');
const Team = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/pages/profile/team/Team.vue');
const ManagePlan = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/pages/profile/manage-plan/ManagePlan.vue');
const Billing = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/pages/profile/billing/Billing.vue');
const PrivacyPolicy = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/pages/profile/privacy-policy/privacy-policy.vue');
const Contacts = () => import(
    /* webpackChunkName: "group-contacts" */
    /* webpackPrefetch: true */
    './components/pages/contacts/Contacts.vue');
const EcommerceSchemeList = () => import(
    /* webpackChunkName: "group-ecommerce-scheme" */
    /* webpackPrefetch: true */
    './components/pages/ecommerce_scheme/EcommerceScheme.vue');
const EmployeesList = () => import(
    /* webpackChunkName: "group-employees" */
    /* webpackPrefetch: true */
    './components/pages/employees/Employees.vue');
const CompanyList = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    './components/pages/company/CompanyList.vue');
const CompanyGeoList = () => import(
    /* webpackChunkName: "group-company-geo" */
    /* webpackPrefetch: true */
    './components/pages/company/CompanyGeoList.vue');
const ContactsGeoList = () => import(
    /* webpackChunkName: "group-contacts-geo" */
    /* webpackPrefetch: true */
    './components/pages/contacts/ContactsGeoList.vue');
const TitleList = () => import(
    /* webpackChunkName: "group-title" */
    /* webpackPrefetch: true */
    './components/pages/title/Title.vue');
const IndustryList = () => import(
    /* webpackChunkName: "group-industry" */
    /* webpackPrefetch: true */
    './components/pages/industry/Industry.vue');
const TechnologiesList = () => import(
    /* webpackChunkName: "group-technologies" */
    /* webpackPrefetch: true */
    './components/pages/technologies/Technologies.vue');
const ListNameContacts = () => import(
    /* webpackChunkName: "group-list-name-contacts" */
    /* webpackPrefetch: true */
    './components/pages/list-name-contacts/ListNameContacts.vue');
const ListNameCompanies = () => import(
    /* webpackChunkName: "group-list-name-companies" */
    /* webpackPrefetch: true */
    './components/pages/list-name-companies/ListNameCompanies.vue');
const PaginationListNameContacts = () => import(
    /* webpackChunkName: "group-list-name-contacts" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-list-name-contact.vue"
    );
const PaginationUserTariffControlPanel = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-user-tariff-panel.vue"
    );
const PaginationCreditLogs = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-credit-logs.vue"
    );
const PaginationSavedSearch = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    "./components/pagination/paginate"
    );
const PaginationSavedList = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    "./components/pagination/paginate"
    );
const PaginationTariffAdmin = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-tariff-admin.vue"
    );
const PaginationUserTariffOrders = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-user-tariff-orders.vue"
    );
const PaginationEcommerceScheme = () => import(
    /* webpackChunkName: "group-ecommerce-scheme" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-ecommerce-scheme.vue"
    );
const PaginationEmployees = () => import(
    /* webpackChunkName: "group-employees" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-employees.vue"
    );
const FilterSidebarEmployees = () => import(
    /* webpackChunkName: "group-employees" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-employees-list.vue');
const PaginationCompanyList = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-company.vue"
    );
const PaginationCompanyGeo = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-company-geo.vue"
    );
const PaginationContactsGeo = () => import(
    /* webpackChunkName: "group-contacts-geo" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-contacts-geo.vue"
    );
const PaginationTitle = () => import(
    /* webpackChunkName: "group-contacts-geo" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-title.vue"
    );
const PaginationIndustry = () => import(
    /* webpackChunkName: "group-contacts-geo" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-industry.vue"
    );
const PaginationTechnologies = () => import(
    /* webpackChunkName: "group-contacts-geo" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-technologies.vue"
    );
const PaginationCompanyCompaniesList = () => import(
    /* webpackChunkName: "group-list-name-companies" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-company-companies-list.vue");
const PaginationCompanyContactsList = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    "./components/pagination/pagination-company-contacts-list.vue"
    );
const Pagination = () => import(
    /* webpackPrefetch: true */
    "./components/pagination/paginate.vue"
    );
// const CompanyCard = () => import(
//     /* webpackChunkName: "group-company" */
//     /* webpackPrefetch: true */
//     './components/pages/company/CompanyCard.vue');
const Users = () => import(/* webpackChunkName: "group-users" */ './components/pages/Users.vue');
const Roles = () => import(/* webpackChunkName: "group-roles" */ './components/pages/acl/Roles.vue');
const Permissions = () => import(/* webpackChunkName: "group-permissions" */ './components/pages/acl/Permissions.vue');
const ImportSaved = () => import(
    /* webpackChunkName: "group-import" */
    /* webpackPrefetch: true */
    './components/pages/import/partials/ImportSaved.vue');
const ImportPage = () => import(
    /* webpackChunkName: "group-import" */
    /* webpackPrefetch: true */
    './components/pages/import/ImportPage.vue');
const Import = () => import(
    /* webpackChunkName: "group-import" */
    /* webpackPrefetch: true */
    './components/pages/import/partials/Import.vue');
const Tariffs = () => import(
    /* webpackChunkName: "group-tariffs" */
    /* webpackPrefetch: true */
    './components/pages/tariffs/Tariffs.vue');
const TariffUser = () => import(
    /* webpackChunkName: "group-tariffs" */
    /* webpackPrefetch: true */
    './components/pages/tariffs/TariffUser.vue');
const TariffUserControlPanel = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/pages/tariffs/TariffUsersControlPanel.vue');
const TariffOrders = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/pages/tariffs/TariffOrders.vue');
const ShowOrder = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/pages/tariffs/ShowOrder.vue');
// Log Credits
const LogCredits = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/pages/tariffs/CreditLogs.vue');

// -- sub dependent components
const FilterSidebarEcommerceScheme = () => import(
    /* webpackChunkName: "group-ecommerce-scheme" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-ecommerce-scheme-list.vue');
const FilterSidebarContacts = () => import(
    /* webpackChunkName: "group-contacts" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-contacts.vue');
const FilterSidebarImportFails = () => import(
    /* webpackChunkName: "group-import" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-import-fails.vue');
const FilterSidebarLeads = () => import(
    /* webpackChunkName: "group-leads" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-leads.vue');
const FilterSidebarCompanyList = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-company-list.vue');
const FilterSidebarCompanyGeo = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-company-geo.vue');
const FilterSidebarContactsGeo = () => import(
    /* webpackChunkName: "group-company" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-contacts-geo.vue');
const FilterSidebarTitle = () => import(
    /* webpackChunkName: "group-title" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-title-list.vue');
const FilterSidebarIndustry = () => import(
    /* webpackChunkName: "group-industry" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-industry-list.vue');
const FilterSidebarTechnologies = () => import(
    /* webpackChunkName: "group-technologies" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-technologies-list.vue');
const FilterSidebarUserControlPanel = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-user-tariff-panel.vue');
const FilterSidebarTariffOrders = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-user-tariff-orders.vue');
const FilterSidebarCreditLogs = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-credit-logs.vue');
const FilterSidebarTariffAdmin = () => import(
    /* webpackChunkName: "group-admin-tariffs" */
    /* webpackPrefetch: true */
    './components/filter-sidebar/filter-sidebar-tariff-admin.vue');

//simple-sidebar
const ProfileSidebar = () => import(
    /* webpackChunkName: "group-profile" */
    /* webpackPrefetch: true */
    './components/sidebar/sidebar-profile');
const PaginationContact = () => import(
    /* webpackChunkName: "group-contacts"*/
    /* webpackPrefetch: true */
    "./components/pagination/pagination-contact.vue"
    );
const PaginationLeads = () => import(
    /* webpackChunkName: "group-leads"*/
    /* webpackPrefetch: true */
    "./components/pagination/pagination-leads.vue"
    );
const ImportPreview = () => import(
    /* webpackChunkName: "group-import" */
    /* webpackPrefetch: true */
    "./components/pages/import/partials/ImportPreview"
    );
const ImportFails = () => import(
    /* webpackChunkName: "group-import" */
    /* webpackPrefetch: true */
    "./components/pages/import/partials/ImportFails"
    );

const Leads = () => import(
    /* webpackChunkName: "group-leads" */
    /* webpackPrefetch: true */
    './components/pages/leads/Leads.vue');
const UpgradePlan = () => import(
    /* webpackChunkName: "group-upgrade-plan" */
    /* webpackPrefetch: true */
    './components/pages/upgrade_plan/UpgradePlan.vue');

const ListSaved = () => import(
    /* webpackChunkName: "group-list-saved" */
    /* webpackPrefetch: true */
    './components/pages/list-saved/ListSaved.vue');

const SavedSearches = () => import(
    /* webpackChunkName: "group-saved-searches" */
    /* webpackPrefetch: true */
    './components/pages/saved_searches/SavedSearches.vue');

const ListSavedCompanyPage = () => import(
    /* webpackChunkName: "group-list-saved" */
    /* webpackPrefetch: true */
    './components/pages/list-saved-page/ListSavedCompanyPage.vue');

const ListSavedContactsPage = () => import(
    /* webpackChunkName: "group-list-saved" */
    /* webpackPrefetch: true */
    './components/pages/list-saved-page/ListSavedContactsPage.vue');

const router = new VueRouter({
    mode: 'history',
    linkActiveClass: "active",
    routes: [
        // {
        //     path: '/',
        //     component: StartPage,
        //     name: 'start',
        //     meta:{
        //         required_permission: 'no_auth',
        //         title: 'COMAPANY NAME', //TODO from config
        //
        //     }
        // },
        {
            path: '/',//todo start page
            component: Login,
            name: 'start',
            meta:{
                required_permission: 'no_auth',
                title: 'Login',
                layout: 'auth-layout',
            }
        },
        {
            path: '/register',
            component: Register,
            name: 'register',
            meta:{
                required_permission: 'no_auth',
                title: 'Register',
                layout: 'auth-layout',
            }
        },
        {
            path: '/email/verify',
            component: EmailVerify,
            name: 'EmailVerifyBackendUrl',
            meta:{
                required_permission: 'no_auth',
                title: 'Email verify',
                layout: 'auth-layout',
            }
        },
        {
            path: '/email/verify/:id/:hash',
            component: EmailVerify,
            name: 'EmailVerify',
            meta:{
                required_permission: 'no_auth',
                title: 'Email verify',
                layout: 'auth-layout',
            }
        },
        {
            path: '/login',
            component: Login,
            name: 'login',
            meta:{
                required_permission: 'no_auth',
                title: 'Login',
                layout: 'auth-layout',
            }
        },
        {
            path: '/password/reset',
            component: PasswordReset,
            name: 'PasswordReset',
            meta:{
                required_permission: 'no_auth',
                title: 'Forgot password',
                layout: 'auth-layout',
            }
        },
        {
            path: '/password/reset/:token',
            component: ChangePassword,
            name: 'ChangePassword',
            meta:{
                required_permission: 'no_auth',
                title: 'ChangePassword',
                layout: 'auth-layout',
            },
            props: true
        },
        {
            path: '/home',
            component: Home,
            name: 'home',
            meta:{
                required_permission: 'home.view',
                title: 'Home',
            }
        },
        {
            path: '/dashboard',
            component: Dashboard,
            name: 'dashboard',
            meta:{
                required_permission: 'dashboard.view',
                title: 'Dashboard',
            }
        },
        {
            path: '/profile',
            components: {
                default: CommonProfile,
                sidebar: ProfileSidebar,
            },
            // name: 'profile',
            meta:{
                required_permission: 'profile.view',
                title: 'Profile',
            },
            children:[
                {
                    name: 'profile',
                    path: '',
                    components: {
                        default: Profile
                    },
                    meta:{
                        required_permission: 'profile.view',
                        title: 'Profile',
                    },
                },
                {
                    path: 'team',
                    components: {
                        default: Team,
                    },
                    name: 'team',
                    meta:{
                        required_permission: 'profile.view',
                        title: 'Profile - Team',
                    }
                },
                {
                    path: 'manage-plan',
                    components: {
                        default: ManagePlan,
                    },
                    name: 'manage-plan',
                    meta:{
                        required_permission: 'profile.view',
                        title: 'Profile - Manage plan',
                    }
                },
                {
                    path: 'billing',
                    components: {
                        default: Billing,
                    },
                    name: 'billing',
                    meta:{
                        required_permission: 'profile.view',
                        title: 'Profile - Billing',
                    }
                },
                {
                    path: 'privacy-policy',
                    components: {
                        default: PrivacyPolicy,
                    },
                    name: 'PrivacyPolicy',
                    meta:{
                        required_permission: 'profile.view',
                        title: 'Profile - Privacy policy',
                    }
                },
            ]
        },

        {
            path: '/contacts',
            name: 'contacts',
            components: {
                default: Contacts,
                sidebar: FilterSidebarContacts,
                pagination: PaginationContact,
            },
            meta:{
                required_permission: 'contacts.view',
                title: 'People',
                filter_data: {
                    filter_params_from_module: 'people' // for getters like 'people/getFilterParams' or 'people/setEventFilter'
                },
            },

        },
        {
            path: '/leads',
            name: 'leads',
            components: {
                default: Leads,
                sidebar: FilterSidebarLeads,
                pagination: PaginationLeads,
            },
            meta:{
                required_permission: 'leads.view',
                title: 'Leads',
                filter_data: {
                    filter_params_from_module: 'leads' // for getters like 'people/getFilterParams' or 'people/setEventFilter'
                },
            },

        },
        {
            path: '/tariff/users/panel',
            components: {
                default: TariffUserControlPanel,
                pagination: PaginationUserTariffControlPanel,
                sidebar: FilterSidebarUserControlPanel,
            },
            name: 'TariffUsersControlPanel',
            meta:{
                required_permission: 'tariff_admin.view',
                title: 'Tariffs User Control Panel',
                filter_data: {
                    filter_params_from_module: 'userTariffsControl' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/tariff/user',
            name: 'tariff users',
            components: {
                default: TariffUser,
            },
            meta:{
                required_permission: 'tariff_users.view',
                title: 'Tariff User',
                filter_data: {
                    filter_params_from_module: 'tariff' // for getters like 'people/getFilterParams' or 'people/setEventFilter'
                },
            },

        },
        {
            path: '/tariff/orders',
            name: 'TariffOrders',
            components: {
                default: TariffOrders,
                pagination: PaginationUserTariffOrders,
                sidebar: FilterSidebarTariffOrders,
            },
            meta:{
                required_permission: 'tariff_admin.view',
                title: 'Tariff Orders',
                filter_data: {
                    filter_params_from_module: 'tariffOrders' // for getters like 'people/getFilterParams' or 'people/setEventFilter'
                },
            },

        },
        {
            path: '/credit/logs',
            name: 'CreditLogs',
            components: {
                default: LogCredits,
                general_pagination: PaginationCreditLogs,
                sidebar: FilterSidebarCreditLogs,
            },
            meta:{
                required_permission: 'tariff_admin.view',
                title: 'Credit Logs',
                filter_data: {
                    filter_params_from_module: 'creditLogs' // for getters like 'people/getFilterParams' or 'people/setEventFilter'
                },
            },

        },
        {
            path: '/show/order/:id',
            name: 'ShowOrder',
            components: {
                default: ShowOrder,
            },
            meta:{
                required_permission: 'tariff_admin.view',
                title: 'Order',
            },

        },
        {
            path: '/employees/list',
            components: {
                default: EmployeesList,
                pagination: PaginationEmployees,
                sidebar: FilterSidebarEmployees,
            },
            name: 'EmployeesList',
            meta:{
                required_permission: 'admin_employees.full',
                title: 'Employees List ',
                filter_data: {
                    filter_params_from_module: 'employeesList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/ecommerce-scheme/list',
            components: {
                default: EcommerceSchemeList,
                pagination: PaginationEcommerceScheme,
                sidebar: FilterSidebarEcommerceScheme,
            },
            name: 'EcommerceSchemeList',
            meta:{
                required_permission: 'admin_title.full',
                title: 'EcommerceScheme List ',
                filter_data: {
                    filter_params_from_module: 'ecommerceSchemeList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/list-name-companies',
            name: 'ListNameCompanies',
            components: {
                default: ListNameCompanies,
                pagination: PaginationCompanyCompaniesList,
                sidebar: FilterSidebarCompanyList,
            },
            meta:{
                required_permission: 'list_name.view',
                title: 'List companies',
                filter_data: {
                    filter_params_from_module: 'listNameCompanies' // for getters like 'ListNameCompanies/getFilterParams' or 'ListNameCompanies/setEventFilter'
                }
            }
        },
        {
            path: '/list-name-contacts',
            name: 'ListNameContacts',
            components: {
                default: ListNameContacts,
                sidebar: FilterSidebarContacts,
                pagination: PaginationListNameContacts
            },
            meta:{
                required_permission: 'list_name.view',
                title: 'List contacts',
                filter_data: {
                    filter_params_from_module: 'listNameContacts' // for getters like 'listNameContacts/getFilterParams' or 'listNameContacts/setEventFilter'
                }
            }
        },
        {
            path: '/company/:companyId',
            name: 'CompanyCardContactsListTab', // exist default children
            components: {
                default: CompanyCardContactsListTab,
                pagination: PaginationCompanyContactsList,
                sidebar: CompanyCardInfo
            },
            props: true,
            meta: {
                required_permission: 'company.view',
                title: 'Company card',
                layout: 'default-layout',
            }
            // children:[{
            //     name: 'CompanyCardContactsListTab',
            //     path: '', //selected tab by default
            //     components: {
            //         company_card_tabs: CompanyCardContactsListTab
            //     },
            //     meta: {
            //         required_permission: 'company.view',
            //         title: 'Company card - contacts',
            //     },
            // },
            //     {
            //     name: 'CompanyCardEditTab',
            //     path: 'company-edit',
            //     components: {
            //         company_card_tabs:CompanyCardEditTab
            //     },
            //     meta: {
            //         required_permission: 'company.view',
            //         title: 'Company card - edit',
            //     },
            // },
            // ]
        },
        {
            path: '/users',
            component: Users,
            name: 'users',
            meta:{
                required_permission: 'users.view',
                title: 'Users',
            }
        },
        {
            path: '/roles',
            component: Roles,
            name: 'roles',
            meta:{
                required_permission: 'roles.view',
                title: 'Roles',
            }
        },
        {
            path: '/permissions',
            component: Permissions,
            name: 'permissions',
            meta:{
                required_permission: 'permissions.view',
                title: 'Permissions',
            }
        },
        {
            path: '/admin/company-geo/list',
            components: {
                default: CompanyGeoList,
                pagination: PaginationCompanyGeo,
                sidebar: FilterSidebarCompanyGeo,
            },
            name: 'CompanyGeoList',
            meta:{
                required_permission: 'admin_company_geo.full',
                title: 'Company Geo List ',
                filter_data: {
                    filter_params_from_module: 'companyGeoList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/admin/contacts-geo/list',
            components: {
                default: ContactsGeoList,
                pagination: PaginationContactsGeo,
                sidebar: FilterSidebarContactsGeo,
            },
            name: 'ContactsGeoList',
            meta:{
                required_permission: 'admin_contacts_geo.full',
                title: 'Contacts Geo List ',
                filter_data: {
                    filter_params_from_module: 'contactsGeoList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/admin/title/list',
            components: {
                default: TitleList,
                pagination: PaginationTitle,
                sidebar: FilterSidebarTitle,
            },
            name: 'TitleList',
            meta:{
                required_permission: 'admin_title.full',
                title: 'Title List ',
                filter_data: {
                    filter_params_from_module: 'titleList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/admin/industry/list',
            components: {
                default: IndustryList,
                pagination: PaginationIndustry,
                sidebar: FilterSidebarIndustry,
            },
            name: 'IndustryList',
            meta:{
                required_permission: 'admin_industry.full',
                title: 'Industry List ',
                filter_data: {
                    filter_params_from_module: 'industryList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/admin/technologies/list',
            components: {
                default: TechnologiesList,
                pagination: PaginationTechnologies,
                sidebar: FilterSidebarTechnologies,
            },
            name: 'TechnologiesList',
            meta:{
                required_permission: 'admin_technologies.full',
                title: 'Technologies List ',
                filter_data: {
                    filter_params_from_module: 'technologiesList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/company',
            components: {
                default: CompanyList,
                pagination: PaginationCompanyList,
                sidebar: FilterSidebarCompanyList,
            },
            name: 'CompanyList',
            meta:{
                required_permission: 'company.view',
                title: 'Company List',
                filter_data: {
                    filter_params_from_module: 'companyList' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/tariffs',
            components: {
                default: Tariffs,
                pagination: PaginationTariffAdmin,
                sidebar: FilterSidebarTariffAdmin,
            },
            name: 'Tariffs',
            meta:{
                required_permission: 'tariff_admin.view',
                title: 'Tariffs List',
                filter_data: {
                    filter_params_from_module: 'tariffs' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                },
            }
        },
        {
            path: '/import',
            // name: 'import',  // exist default children
            components: {
                default: ImportPage,
                general_pagination: Pagination,
                sidebar: FilterSidebarImportFails,
            },
            meta:{
                required_permission: 'import.view',
                title: 'Import',
            },
            children:[
                {
                    path: '',
                    name: 'Import',
                    components: {
                        import: Import,
                    },
                    meta: {
                        required_permission: 'company.view',
                        title: 'Import',
                        filter_data: {
                            filter_params_from_module: 'importFails' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                        },
                    },
                },
                {
                    path: 'preview/:import_file_id',
                    name: 'ImportPreview',
                    components: {
                        import_preview: ImportPreview,
                    },
                    meta: {
                        required_permission: 'company.view',
                        title: 'Import - preview',
                        filter_data: {
                            filter_params_from_module: 'importFails' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                        },
                    },
                },
                {
                    path: 'fails',
                    name: 'ImportFails',
                    components: {
                        import_fails: ImportFails,
                    },
                    meta: {
                        required_permission: 'company.view',
                        title: 'Import - fails',
                        filter_data: {
                            filter_params_from_module: 'importFails' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                        },
                    },
                },
                {
                    path: 'saved',
                    name: 'ImportSaved',
                    components: {
                        import_saved: ImportSaved,
                    },
                    meta:{
                        required_permission: 'import.view',
                        title: 'Import Files',
                        filter_data: {
                            filter_params_from_module: 'importFails' // for getters like 'importFails/getFilterParams' or 'importFails/setEventFilter'
                        },
                    }
                },
            ]
        },
        {
            path: '/upgrade-plan',
            name: 'UpgradePlan',
            components: {
                default: UpgradePlan,
            },
            meta:{
                required_permission: 'contacts.view',
                title: 'Upgrade Plan',
                layout: 'upgrade-plan-layout',
            }
        },
        {
            path: '/list-saved-contacts',
            name: 'ListSavedContacts',
            components: {
                default: ListSaved,
                general_pagination: PaginationSavedList,
            },
            meta: {
                required_permission: 'list_name.view',
                title: 'Saved list contacts',
                layout: 'saved-list-layout',
            }
        },
        {
            path: '/list-saved-companies',
            name: 'ListSavedCompanies',
            components: {
                default: ListSaved,
                general_pagination: PaginationSavedList,
            },
            meta: {
                required_permission: 'list_name.view',
                title: 'Saved list companies',
                layout: 'saved-list-layout',
            }
        },
        {
            path: '/saved-searches',
            name: 'SavedSearches',
            components: {
                default: SavedSearches,
                general_pagination: PaginationSavedSearch,
            },
            meta: {
                required_permission: 'saved_search.full',
                title: 'Saved Searches',
                layout: 'saved-searches-layout',
            }
        },

        {
            path: '/list-saved-company/:id/:list_name',
            name: 'ListSavedCompanyPage',
            components: {
                default: ListSavedCompanyPage,
                sidebar: FilterSidebarCompanyList,
            },
            meta: {
                required_permission: 'list_name.view',
                title: 'List saved company page',
                layout: 'saved-list-company-layout',
                filter_data: {
                    filter_params_from_module: 'listNameCompanies' // for getters like 'ListNameCompanies/getFilterParams' or 'ListNameCompanies/setEventFilter'
                }
            }
        },
        {
            path: '/list-saved-contacts/:id/:list_name',
            name: 'ListSavedContactsPage',
            components: {
                default: ListSavedContactsPage,
                sidebar: FilterSidebarContacts,
            },
            meta: {
                required_permission: 'list_name.view',
                title: 'List saved contacts page',
                layout: 'saved-list-contacts-layout',
                filter_data: {
                    filter_params_from_module: 'listNameContacts' // for getters like 'listNameContacts/getFilterParams' or 'listNameContacts/setEventFilter'
                }
            },
        },

        {
            path: '/list-saved-company/all',
            name: 'ListSavedCompanyPageAll',
            components: {
                default: ListSavedCompanyPage,
                sidebar: FilterSidebarCompanyList,
            },
            meta: {
                required_permission: 'list_name.view',
                title: 'List saved company page',
                layout: 'saved-list-company-layout',
                filter_data: {
                    filter_params_from_module: 'listNameCompanies' // for getters like 'ListNameCompanies/getFilterParams' or 'ListNameCompanies/setEventFilter'
                },
                list_name_title: 'All Companies'
            },
        },
        {
            path: '/list-saved-contacts/all',
            name: 'ListSavedContactsPageAll',
            components: {
                default: ListSavedContactsPage,
                sidebar: FilterSidebarContacts,
            },
            meta: {
                required_permission: 'list_name.view',
                title: 'List saved contacts page',
                layout: 'saved-list-contacts-layout',
                filter_data: {
                    filter_params_from_module: 'listNameContacts' // for getters like 'listNameContacts/getFilterParams' or 'listNameContacts/setEventFilter'
                },
                list_name_title: 'All Contacts'
            },
        },

        {
            path: '*',
            component: require('./components/pages/NotFound.vue').default,
            meta:{
                title: 'Not found',
            }
        }
    ],
    parseQuery(query) {
        return qs.parse(query, {arrayLimit: 700});
    },
    stringifyQuery(query) {
        let result = qs.stringify(query, { interpretNumericEntities: true, format : 'RFC3986', encodeValuesOnly: true });
        return result ? ('?' + result) : '';
    },
});

const guest = (to, from, next) => {
    if (!localStorage.getItem('authToken')) {
        return next()
    } else {
        return tryLoadUser(to, from, next)
    }
}
const auth = (to, from, next) => {
    // Solve a bug where user isn't yet loaded but the app runs
    if (localStorage.getItem('authToken')) {
        return next()
    } else {
        return next('/login')
    }
}

const tryLoadUser = (to, from, next) => {
    let promis = store.dispatch('auth/loadUser');
    promis.then(function () {
        // if (noAuthPaths.indexOf(from.path) === -1) {
        //     console.log('from to !!!! '+from.path)
        //     return next(from.path)
        // }else
        if(noAuthPaths.indexOf(to.path) !== -1 || noAuthNames.indexOf(to.name) !== -1 ){
            return next('/contacts')
        } else {
            next();
        }

    }).catch(function () {
        return next('/login')
    });
}

const noAuthPaths = [ '/', '/login', '/register', '/password/reset']; // todo: get from routes
const noAuthNames = [ 'ChangePassword']; // todo: get from routes


router.beforeEach((to, from, next) => {
    // eslint-disable-next-line
    if (!store || !store.hasOwnProperty('getters') || !store.getters.hasOwnProperty('can')) {
        if (router.currentRoute.path !== '/') {
            next('/');
        } else {
            next(false);
        }
    }

    if(to.meta.required_permission === 'no_auth'){
        return guest(to, from, next);
    }

    let allowed;
    if(noAuthPaths.indexOf(to.path) !== -1 || noAuthNames.indexOf(to.name) !== -1 ){
        allowed = true;
    }else {
        allowed = store.getters.can[to.meta.required_permission] ?? false;
    }

    if (allowed) {
        // console.log('Allowed');
        return auth(to, from, next);
    } else {
        if (localStorage.getItem('authToken')) {
            return tryLoadUser(to, from, next)
        }else {
            if(noAuthPaths.indexOf(to.path) !== -1 || noAuthNames.indexOf(to.name) !== -1 ){
                next('/');
            } else {
                next();
            }
        }
    }
});

const DEFAULT_TITLE = '';
router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
        document.title = (to.meta.title ? to.meta.title : DEFAULT_TITLE) + ' - ' + store.getters.appName;
    });
});


export default router;
