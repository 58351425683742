const actions = {
    setStoreAndCollection({commit}, store) {
        commit('updateStoreAndCollection', store);
    }
};

const state = {
    store: null,
    collection: null,
    route: null,
};

const mutations = {
    updateStoreAndCollection(state, data) {
        state.store = data.store;
        state.collection = data.collection;
        state.route = data.route;
    }
};

const getters = {
    getStore(state) {
        return state.store
    },
    getCollection(state) {
        return state.collection
    },
    getRoute() {
        return state.route
    },
    getTotal() {
        return state.total
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
