import axios from "axios";
import toastMessage from "../toastMessage";
import store from "../index";

const actions = {
    allCurrentTariff({commit}){
        let promise = axios.get(process.env.VUE_APP_API_URL + 'tariffs/user/current');
        promise.then((response) => {

            commit('updateAllCurrentTariff', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    getListTarrifs({commit}) {
        return axios.get(process.env.VUE_APP_API_URL + 'tariffs/user')
            .then((response) => {
                commit('updateTariffs', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
    },
    buyTariffConfirm(_, data) {
        let promise = axios.post(process.env.VUE_APP_API_URL + 'tariff/user/basket/' + data.id + '/confirm' , data);

        promise.then((response) => {
            store.dispatch('users/getUser');

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    buyTariffMultipleConfirm() {
        let promise = axios.post(process.env.VUE_APP_API_URL + 'tariff/user/basket/multiple/confirm');
        promise.then((response) => {
            store.dispatch('users/getUser');

            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    buyTariff(_, data) {
        let promise = axios.post(process.env.VUE_APP_API_URL + 'tariff/user/basket/' + data.id + '/buy' , data);

        promise.then((response) => {
            toastMessage.fire(response);
        }).catch((error) => {
            toastMessage.fire(error);
        });
        return promise;
    },

    getBuyTariff({commit}, data) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'tariff/user/basket', data);

        promise.then((response) => {
            commit('updateTariffBasket', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    deleteBasket({commit}, data) {
        let promise = axios.delete(process.env.VUE_APP_API_URL + 'tariff/user/basket', data);

        promise.then((response) => {
            commit('updateTariffBasket', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
};

const state = {
    basket: {
        data: []
    },
    tariffs: {
        data: []
    },
    all_current: null // taru
};

const mutations = {
    updateTariffBasket(state, data) {
        state.basket.data = data;
    },
    prependUserTariffs(state, data) {
        const item = state.basket.data.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    updateTariffs(state, data) {
        state.tariffs = data;
    },
    updateAllCurrentTariff(state, data) {
        state.all_current = data;
    },
};

const getters = {
    getTariffs(state) {
        return state.tariffs;
    },
    getBasket() {
        return state.basket;
    },
    getAllCurrent() {
        return state.all_current;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
