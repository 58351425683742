<template>
  <!-- Site wrapper -->
  <div id="app">
    <component :is="layout">
      <router-view/>
    </component>

  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters("auth", ["getUser"]),
    layout() {
      return this.$route.meta.layout || 'default-layout'
    },
  },
  methods: {
    ...mapActions("auth", ["logout", "loadUser"]),
    logoutUser() {
      this.logout();
      this.$router.push("/");
    },
  },
  mounted() {

  },
};
</script>

<style src="./assets/css/global.scss" lang="scss"></style>
<style src="./scss/main/index.scss" lang="scss"></style>
