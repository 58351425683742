import enLocale from 'element-ui/lib/locale/lang/en'

const en ={
    ...enLocale,
    simple:{
        test: 'Hello world'
    },
    checkbox: {
        default: 'default',
    },
    filters:{
        label:{
            company_country: 'Company country',
            company_state_region: 'Company state/region',
            company_city: 'Company city',
            company_geo: 'Company location',
            company_geo_label: 'Label',
            company_geo_name: 'Name',
            company_geo_city: 'City',
            company_geo_alias: 'Alias',
            company_geo_country: 'Country',
            company_geo_state_region: 'State region',
            company_geo_type: 'Type',
            company_geo_id: 'Id',
            company_geo_count: 'Count',
            control_panel_user: 'User',
            control_panel_tariff: 'Tariff',
            control_panel_tariff_price: 'Price',
            control_panel_tariff_visible: 'Visible',
            control_panel_tariff_active: 'Active',
            control_panel_tariff_started_at: 'Started At',
            control_panel_date: 'Date',
            control_panel_updated_date: 'Updated Date',
            control_panel_expired_at: 'Expired At',
            control_panel_started_at: 'Started At',
            control_panel_status: 'Status',
            control_panel_amount: 'Amount',
            credit_logs_user_tariff: 'User Tariff Id',
            credit_logs_list_name_id: 'List Name Id',
            industry: 'Industry',
            employees: 'Employees',
            title: 'Job Titles',
            contacts_geo_state_region_id_search: 'Geo Company State Region Id',
            contacts_geo_company_country_id_search: 'Geo Company Country Id',
            contacts_geo_company_company_company_city_id: 'Geo Company City Id',
            contacts_geo_contacts_state_region_id_search: 'Geo Contacts State Region Id',
            contacts_geo_contacts_country_id_search: 'Geo Contacts Country Id',
            contacts_geo_contacts_city_id: 'Geo Contacts City Id',
            contacts_location_country: 'Contacts location country',
            contacts_location_state: 'Contacts location state/region',
            contacts_location_city: 'Contacts location city',
            contacts_geo: 'Contacts location',
            ecommerce_scheme: 'Ecommerce scheme',
            technologies: 'Technologies',
            annual_revenue: 'Annual revenue',
            year_founded: 'Year founded',
            annual_revenue_value: 'Value',
            email_status: 'Email status',
            min_value: 'Min Value',
            max_value: 'Max Value',
            reason: 'Reason',
            catch_all: 'Catch all',
            created_at: 'Created',
            validation_filter: 'Rule',
            fail_attributes: 'Fail attribute',
            alias: 'Alias',
            company: 'Company'
        },
        checkbox: {
            options: {
                by_first: 'by first',
                strict: 'strict',
                any: 'any',
                Null: 'Null',
                NotNull: 'NotNull',
                less: 'less',
                greater: 'greater',
                equal: 'equal',
                valid: 'valid',
                in_valid: 'in_valid',
                name_by_id: 'name_by_id',
                search_by_id: 'search_by_id',
                invalid_name_by_id: 'invalid_name_by_id',
                not_empty_filter_id: 'not_empty_filter_id',
                empty_filter_id : 'empty_filter_id',
                default: 'default',
            }
        }
    },
    search:{
        label:{
            search: 'Search',
            id: 'id',
            company: 'company',
            website: 'website',
            corporate_email: 'Corporate email',
            first_name: 'First name',
            last_name: 'Last name',
            personal_phone_number: 'Personal phone number',
            company_phone: 'Company phone',
        },
    },
    page: {
        contacts:{
            title: 'Contacts List'
        },
        company: {
            title: 'Company Information',
            card: {
                title: 'About Company',

            }
        }
    },
    nav:{
        savedSearch: 'Saved search',
        contacts: 'People',
        list: 'List',
        listCompanies: 'List Companies',
        listContacts: 'Lists',
        company: 'Company',
        company_list_geo: 'Company Geo',
        contacts_list_geo: 'Contacts Geo',
        title_list: 'Title',
        industry_list: 'Industry',
        technologies_list: 'Technologies',
        annual_revenue_list: 'Annual Revenue',
        employees_list: 'Employees List',
        ecommerce_scheme_list: 'EcommerceScheme List',
        setting: 'Setting',
        services: 'Services',
        import: 'Import',
        import_saved: 'Saved',
        import_fails: 'Fails',
        users: 'Users',
        leads: 'Leads',
        role: 'Role',
        tariffs: 'Tariff',
        tariff_users: 'User Tariffs',
        employees: 'Employees',
        ecommerceScheme: 'Ecommerce Scheme',
        tariff_orders: 'Tariff Orders',
        tariff_user_panel: 'Tariff Users Panel',
        log_credits: 'Log Credits',
        permission: 'Permission',
        order: 'order',
        sign_out: 'Sign out',
        profile: 'Profile',
    }
};

export default en;
