import router from "../../routes";
import utils from "../../utils";
import store from "../index";

const actions =  {
    setFilterValues({commit} , params = {key_filter:'', data: {}}){
        if (params.data === null) {
            params.data = [];
        }
        commit('updateFilterValues', {key_filter: params.key_filter, data: params.data});

        //historyPush if changed
        clearTimeout(store.state.timers.filterValues);
        store.state.timers.filterValues = setTimeout(() => {
            // eslint-disable-next-line
            if (router.currentRoute.query.filters !== undefined && router.currentRoute.query.filters.hasOwnProperty(params.key_filter)) {
                // exist params
                if (utils.notEquals(router.currentRoute.query.filters[params.key_filter], params.data)) {
                    commit('updateEventFilterChanged', (new Date()).getTime())
                }
            } else {
                // new params
                if (params.data !== undefined && params.data.length) {
                    commit('updateEventFilterChanged', (new Date()).getTime())
                }
            }
        }, 800); //ms
    },
    setCheckboxValues({commit} , params = {key_filter:'', data: {}}){
        commit('updateCheckboxValues', {key_filter: params.key_filter, data: params.data});

        //historyPush if changed
        clearTimeout(store.state.timers.checkbox);
        store.state.timers.checkbox = setTimeout(() => {
            // eslint-disable-next-line
            if (router.currentRoute.query.checkbox !== undefined && router.currentRoute.query.checkbox.hasOwnProperty(params.key_filter)) {
                // exist params
                if (utils.notEquals(router.currentRoute.query.checkbox[params.key_filter], params.data)) {
                    commit('updateEventCheckboxChanged', (new Date()).getTime())
                }
            } else {
                // new params
                if (params.data !== undefined && params.data.length) {
                    commit('updateEventCheckboxChanged', (new Date()).getTime())
                }
            }
        }, 800); //ms
    },
    setCheckbox2Values({commit} , params = {key_filter:'', data: {}}){
        commit('updateCheckbox2Values', {key_filter: params.key_filter, data: params.data});

        //historyPush if changed
        clearTimeout(store.state.timers.checkbox);
        store.state.timers.checkbox = setTimeout(() => {
            // eslint-disable-next-line
            if (router.currentRoute.query.checkbox2 !== undefined && router.currentRoute.query.checkbox2.hasOwnProperty(params.key_filter)) {
                // exist params
                if (utils.notEquals(router.currentRoute.query.checkbox2[params.key_filter], params.data)) {
                    commit('updateEventCheckbox2Changed', (new Date()).getTime())
                }
            } else {
                // new params
                if (params.data !== undefined && params.data.length) {
                    commit('updateEventCheckbox2Changed', (new Date()).getTime())
                }
            }
        }, 800); //ms
    },
    setCheckbox3Values({commit} , params = {key_filter:'', data: {}}){
        commit('updateCheckbox3Values', {key_filter: params.key_filter, data: params.data});

        //historyPush if changed
        clearTimeout(store.state.timers.checkbox);
        store.state.timers.checkbox = setTimeout(() => {
            // eslint-disable-next-line
            if (router.currentRoute.query.checkbox3 !== undefined && router.currentRoute.query.checkbox3.hasOwnProperty(params.key_filter)) {
                // exist params
                if (utils.notEquals(router.currentRoute.query.checkbox3[params.key_filter], params.data)) {
                    commit('updateEventCheckbox3Changed', (new Date()).getTime())
                }
            } else {
                // new params
                if (params.data !== undefined && params.data.length) {
                    commit('updateEventCheckbox3Changed', (new Date()).getTime())
                }
            }
        }, 800); //ms
    },
    setSearchValues({commit} , params = {key_search:'', data: {}}){
        commit('updateSearchValues', {key_search: params.key_search, data: params.data});

        //historyPush if changed
        clearTimeout(store.state.timers.search);
        store.state.timers.search = setTimeout(() => {
            // eslint-disable-next-line
            if (router.currentRoute.query.search !== undefined && router.currentRoute.query.search.hasOwnProperty(params.key_search)) {
                // exist params
                if (utils.notEquals(router.currentRoute.query.search[params.key_search], params.data)) {
                    commit('updateEventSearchChanged', (new Date()).getTime())
                }
            } else {
                // new params
                if (params.data !== undefined && params.data.length) {
                    commit('updateEventSearchChanged', (new Date()).getTime())
                }
            }
        }, 800); //ms
    },
    setSearchClearValues({commit}){
        commit('updateClearSearchesValues');

        //historyPush if changed
        clearTimeout(store.state.timers.search);
        store.state.timers.search = setTimeout(() => {
            // eslint-disable-next-line
            commit('updateEventSearchChanged', (new Date()).getTime())
        }, 800); //ms
    },
    setUpdateFiltersValues({commit} , filters){
        commit('updateFiltersValues', filters);
    },
    setClearFiltersValues({commit}){
        commit('clearFiltersValues');
    },

    setUpdateCheckboxesValues({commit} , checkbox){
        commit('updateCheckboxesValues', checkbox);
    },
    setUpdateCheckboxes2Values({commit} , checkbox){
        commit('updateCheckboxes2Values', checkbox);
    },
    setClearCheckboxesValues({commit}){
        commit('clearCheckboxesValues');
    },
    setClearCheckboxes2Values({commit}){
        commit('clearCheckboxes2Values');
    },
    setUpdateCheckboxes3Values({commit} , checkbox){
        commit('updateCheckboxes3Values', checkbox);
    },
    setClearCheckboxes3Values({commit}){
        commit('clearCheckboxes3Values');
    },

    setUpdateSearchesValues({commit} , search){
        commit('updateSearchesValues', search);
    },
    setClearSearchesValues({commit}){
        commit('clearSearchesValues');
    },
};

const mutations =  {

// - filters
    clearFiltersValues(state) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.filters)) {
            state.filters[key] = [];
        }
    },
    updateFilterValues(state, params = {key_filter:'', data: {}}) {
        state.filters[params.key_filter] = params.data;
    },
    updateFiltersValues(state, data) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.filters)) {

            // keyValue
            // eslint-disable-next-line
            if(state.filters_setting.byValueKey.hasOwnProperty(key)){
                let byValueKey = state.filters_setting.byValueKey[key];
                // replace type keyValue to int
                if(byValueKey){
                    data[key].map((rawItem, index) => {
                        // eslint-disable-next-line
                        if(data[key][index].hasOwnProperty(byValueKey)){
                            data[key][index][byValueKey] = parseInt(rawItem[byValueKey])
                        }
                    });
                }
            }
            // byId
            let byId = state.filters_setting.byId[key];
            if (byId) {
                if (data[key]) {
                    data[key].map((rawValue, index) => {
                        if (parseInt(rawValue)) {
                            let strRaw = rawValue.toString()
                            if(strRaw.length > 1 && strRaw.charAt(strRaw.length - 1) === 'e') { // allow last symbol 'e' - its negative flag for filter
                                data[key][index] = rawValue;
                            }else{
                                data[key][index] = parseInt(rawValue);
                            }
                        }
                    });
                }
            }
            if (data[key] === undefined) {
                state.filters[key] = [];
            } else {
                state.filters[key] = data[key];
            }
        }
    },

// - checkbox filters
    clearCheckboxesValues(state) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.checkbox)) {
            state.checkbox[key] = [];
        }
    },
    clearCheckboxes2Values(state) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.checkbox2)) {
            state.checkbox2[key] = [];
        }
    },
    clearCheckboxes3Values(state) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.checkbox3)) {
            state.checkbox3[key] = [];
        }
    },
    updateCheckboxValues(state, params = {key_filter:'', data: {}}) {
        let currentVal = state.checkbox[params.key_filter];
        state.checkbox[params.key_filter] = utils.equals(currentVal, params.data) ? [] : params.data
    },
    updateCheckboxesValues(state, data) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.checkbox)) {
            state.checkbox[key] = data[key] ?? [];
        }
    },
    updateCheckbox2Values(state, params = {key_filter:'', data: {}}) {
        let currentVal = state.checkbox2[params.key_filter];
        state.checkbox2[params.key_filter] = utils.equals(currentVal, params.data) ? [] : params.data
    },
    updateCheckboxes2Values(state, data) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.checkbox2)) {
            state.checkbox2[key] = data[key] ?? [];
        }
    },
    updateCheckbox3Values(state, params = {key_filter:'', data: {}}) {
        let currentVal = state.checkbox3[params.key_filter];
        state.checkbox3[params.key_filter] = utils.equals(currentVal, params.data) ? [] : params.data
    },
    updateCheckboxes3Values(state, data) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.checkbox3)) {
            state.checkbox3[key] = data[key] ?? [];
        }
    },

    // - search
    clearSearchesValues(state) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.search)) {
            state.search[key] = [];
        }
    },
    updateSearchValues(state, params = {key_search:'', data: {}}) {
        state.search[params.key_search] = params.data;
    },

    updateSearchesValues(state, data) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.search)) {
            state.search[key] = data[key];
        }
    },
    updateClearSearchesValues(state) {
        // eslint-disable-next-line
        for (const [key, value] of Object.entries(state.search)) {
            state.search[key] = [];
        }
    },
    updateEventFilterChanged(state, data){
        state.eventFilterChanged = data;
    },
    updateEventCheckboxChanged(state, data){
        state.eventCheckboxChanged = data;
    },
    updateEventCheckbox2Changed(state, data){
        state.eventCheckbox2Changed = data;
    },
    updateEventCheckbox3Changed(state, data){
        state.eventCheckbox3Changed = data;
    },
    updateEventSearchChanged(state, data){
        state.eventSearchChanged = data;
    },
};

const state =  {
    eventFilterChanged: false,
    eventCheckboxChanged: false,
    eventCheckbox2Changed: false,
    eventCheckbox3Changed: false,
    eventSearchChanged: false,
    filters_setting: {
        byValueKey:{
            // company_state_region: 'geonameid'
        },
        byId: {
            user: true,
            tariff: true,
            user_tariffs: true,
            company_geo: true,
            company: true,
            company_state_region_id: true,
            company_country_id: true,
            company_city_id: true,
            contacts_location_state_id: true,
            contacts_location_country_id: true,
            contacts_location_city_id: true,
            title: true,
            contacts_geo: true,
            industry: true,
            employees: true,
            ecommerce_scheme: true,
            technologies: true,
            annual_revenue: true,
            email_status: true,
            fail_attributes: true,
        }
    },
    filters: {
        title: [],
        // company_state_region: [], // models
        user: [],
        tariff: [],
        status: [],
        amount: [],
        expired_at: [],
        user_tariffs: [],
        // Company geo
        label: [],
        name: [],
        city: [],
        alias: [],
        country: [],
        state_region: [],
        type: [],
        id: [],
        count: [],
        value: [],
        min_value: [],
        max_value: [],
        //
        updated_at: [],
        price:[],
        started_at:[],
        visible:[],
        active:[],
        list_name: [],
        company_geo: [],
        industry: [],
        employees: [],
        contacts_geo: [],
        ecommerce_scheme: [],
        technologies: [],
        annual_revenue: [],
        email_status: [],
        corporate_email: [],
        website: [],
        company: [],
        first_name: [],
        last_name: [],
        social_link: [],
        reason: [],
        catch_all: [],
        created_at: [],
        company_state_region_id: [],
        company_city_id: [],
        company_country_id: [],
        rule: [],
        contacts_location_state_id: [],
        contacts_location_country_id: [],
        contacts_location_city_id: [],
        fail_attributes: [],
    },
    checkbox: {
        title: ['any'],
        industry: [],
        employees: [],
        ecommerce_scheme: [],
        company_geo: [],
        contacts_geo: [],
        email_status:[],
        website:[],
        corporate_email:[],
        company:[],
        first_name:[],
        last_name:[],
        reason:[],
        catch_all:[],
        created_at:[],
        alias: ['any'],
        name: ['any'],
        label: ['any'],
        count: [],
        type: [],
        id: [],
        city: [],
        country: [],
        state_region: [],
        company_city_id: [],
        company_state_region_id: [],
        company_country_id: [],
        fail_attributes: [],
    },
    checkbox2: {
        title: [],
    },
    checkbox3: {
        count: [],
        value: [],
        min_value: [],
        max_value: [],
        rule: [],
        company_state_region_id: [],
        company_country_id: [],
        company_city_id: [],
        contacts_location_state_id: [],
        contacts_location_country_id: [],
        contacts_location_city_id: [],
    },
    search: {
        id: [],
        company: [],
        website: [],
        corporate_email: [],
        first_name: [],
        last_name: [],
        personal_phone_number: [],
        company_phone: [],
    },
};

const getters =  {
    filtersValues(state) {
        return state.filters;
    },
    checkboxValues(state) {
        return state.checkbox;
    },
    checkbox2Values(state) {
        return state.checkbox2;
    },
    checkboxValues3(state) {
        return state.checkbox3;
    },
    searchValues(state) {
        return state.search;
    },
    eventFilterChanged(state){
        return state.eventFilterChanged;
    },
    eventCheckboxChanged(state){
        return state.eventCheckboxChanged;
    },
    eventCheckbox2Changed(state){
        return state.eventCheckbox2Changed;
    },
    eventCheckbox3Changed(state){
        return state.eventCheckbox3Changed;
    },
    eventSearchChanged(state){
        return state.eventSearchChanged;
    },
};


export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}



