import axios from "axios";
import toastMessage from "../toastMessage";
import store from "../index";
import router from "../../routes";
import qs from "qs";


const actions = {
     getFilters({commit}) {

         let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
         let promise = axios.get(process.env.VUE_APP_API_URL + 'filters/people?'+ qs.stringify(store.getters[getterFilterParams]));

         promise.then(res => {
            commit('updateFilter', res.data.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
         return promise;
     },
    setFiltersLoadSearch({commit} ,data) {
        let preparedData = {};
        for (const [key, value] of Object.entries(data)) {
            preparedData[key] = value.options;
        }
        commit('updateFilter', preparedData);
    },
    filtersTariffUserPanel({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'filters/tariff?' + qs.stringify(store.getters['userTariffsControl/getFilterParams']));

        promise.then(res => {
            commit('updateFilterTariffUserPanel', res.data.data);
        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },
    filtersEmployeesList({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/employees/all?' + qs.stringify(store.getters['employeesList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterEmployeesList', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },
    filtersEcommerceSchemeList({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/ecommerce-scheme/all?' + qs.stringify(store.getters['ecommerceSchemeList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterEcommerceSchemeList', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },
    filtersTariffOrders({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'filters/tariff/orders?' + qs.stringify(store.getters['tariffOrders/getFilterParams']));

        promise.then(res => {
            commit('updateFilterTariff', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersTariffAdmin({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'filters/tariff/admin/all?' + qs.stringify(store.getters['tariffs/getFilterParams']));

        promise.then(res => {
            commit('updateFilterAdminTariff', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersContactsGeo({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/all?' + qs.stringify(store.getters['contactsGeoList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterContactsGeo', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersTitleList({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/title/all?' + qs.stringify(store.getters['titleList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterTitleList', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersIndustryList({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/industry/all?' + qs.stringify(store.getters['industryList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterIndustryList', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersTechnologiesList({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/technologies/all?' + qs.stringify(store.getters['technologiesList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterTechnologiesList', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersCompanyGeo({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/all?' + qs.stringify(store.getters['companyGeoList/getFilterParams']));

        promise.then(res => {
            commit('updateFilterCompanyGeo', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },

    filtersCreditLogs({commit}) {
        let promise = axios.get(process.env.VUE_APP_API_URL + 'filters/credit/logs?' + qs.stringify(store.getters['creditLogs/getFilterParams']));

        promise.then(res => {
            commit('updateCreditsLog', res.data.data);

        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },
    async getCompanyStateRegionId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.company_state_region_id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/company-state-region-id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCompanyStateRegionId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyCityId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.company_city_id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/company-city-id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCompanyCityId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyCountryId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.company_country_id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/company-country-id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCompanyCountryId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsStateRegionId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.contacts_location_state_id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/contacts-state-region-id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateContactsStateRegionId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsCityId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.contacts_location_city_id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/contacts-city-id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateContactsCityId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsCountryId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.contacts_location_country_id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/contacts-country-id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateContactsCountryId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getTitle({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.title = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/title?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateTitle', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getTechnologies({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.technologies = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/technologies?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateTechnologies', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCreditUser({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module +  '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.user = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/credit/logs/email?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateUser', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getUser({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.user = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/user/email?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateUser', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getUserOrders({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.user = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/orders/email?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateUser', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getAmountOrders({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.amount = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/orders/amount?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateAmount', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getPriceAdminTariff({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.price = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/tariff/admin/price?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updatePrice', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getUserId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.user = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/user/id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateUser', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeo({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.company_geo = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/company-city-state-region?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCompanyGeo', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompany({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.company = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/company?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCompany', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getTariff({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.tariff = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/user/tariff?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateTariff', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getTariffCredits({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.tariff = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/credit/logs/tariff?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateTariff', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getListNameIdCredits({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.list_name = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/credit/logs/list?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateListName', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getTariffOrders({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.tariff = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/orders/tariff?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateTariff', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getTariffAdmin({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.tariff = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/tariff/admin?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateTariff', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    // Company Geo
    async getCompanyGeoId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.state_region = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoType({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.state_region = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/type?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateType', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoStateRegion({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.state_region = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/state/region?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateStateRegion', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoCountry({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/country?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCountry', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoAlias({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/alias?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateAlias', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoLabel({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.label = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/label?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateLabel', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoName({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.name = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/name?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateName', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoCity({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.name = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/city?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCity', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getCompanyGeoCount({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.count = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/company-geo/count?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCount', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    // Title
    async getTitleId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/title/id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    async getTitleAlias({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/title/alias?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateAlias', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    // Industry
    async getIndustryId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/industry/id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    async getIndustryAlias({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/industry/alias?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateAlias', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    async getIndustryName({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.name = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/industry/name?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateName', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    //

    // Techonologies
    async getTechonologiesId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/techonologies/id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    async getTechonologiesAlias({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/techonologies/alias?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateAlias', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },

    async getTechonologiesName({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.name = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/techonologies/name?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateName', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    //

    // Contacts Geo
    async getContactsGeoId({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.id = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/id?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateId', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsType({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.type = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/type?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateType', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsStateRegion({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.state_region = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/state/region?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateStateRegion', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeoCountry({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/country?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCountry', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeoAlias({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.alias = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/alias?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateAlias', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeoLabel({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.label = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/label?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateLabel', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeoName({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.name = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/name?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateName', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeoCity({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.city = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/city?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCity', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeoCount({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.count = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'admin/filters/contacts-geo/count?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateCount', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    // end contacts geo
    async getUserTariff({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + qs.stringify(store.getters['creditLogs/getFilterParams']); //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.user_tariffs = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/credit/logs/user/tariff?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateUserTariff', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getStatusOrder({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.status = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/orders/status?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateStatus', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getDate({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.date = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/user/date?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateDate', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    async getContactsGeo({commit}, textSearch) {
        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let dataFilter = JSON.parse(JSON.stringify(store.getters[getterFilterParams]));
        dataFilter.filters.contacts_geo = [textSearch];
        await axios.get(process.env.VUE_APP_API_URL + 'filters/contacts-location-city-state?'+ qs.stringify(dataFilter))
            .then(res => {
                commit('updateContactsGeo', res.data);
            })
            .catch((response) => {
                toastMessage.fire(response);
            });
    },
    setFilter(commit, data) {
        commit('setFilter', data);
    },
    getFiltersImportFails({commit}) {

        let getterFilterParams = router.currentRoute.meta.filter_data.filter_params_from_module + '/getFilterParams'; //  as 'listNameContacts/getFilterParams'
        let promise = axios.get(process.env.VUE_APP_API_URL + 'filters/import-fails?'+ qs.stringify(store.getters[getterFilterParams]));

        promise.then(res => {
            commit('updateFiltersImportFails', res.data.data);
        })
            .catch((response) => {
                toastMessage.fire(response);
            });
        return promise;
    },
};

const mutations = {
    updateFilter(state, data) {

        state.filter.title.list = data.title;
        state.filter.title.options = data.title;

        state.filter.company.list = data.company;
        state.filter.company.options = data.company;

        state.filter.company_geo.list = data.company_geo;
        state.filter.company_geo.options = data.company_geo;

        state.filter.industry.list = data.industry;
        state.filter.industry.options = data.industry;

        state.filter.employees.list = data.employees; // not remote
        state.filter.employees.options = data.employees; // not remote

        state.filter.contacts_geo.list = data.contacts_geo;
        state.filter.contacts_geo.options = data.contacts_geo;

        state.filter.ecommerce_scheme.list = data.ecommerce_scheme;// not remote
        state.filter.ecommerce_scheme.options = data.ecommerce_scheme; // not remote

        state.filter.email_status.list = data.email_status; // not remote
        state.filter.email_status.options = data.email_status; // not remote

        state.filter.technologies.list = data.technologies;
        state.filter.technologies.options = data.technologies.slice(0, 20);

        state.filter.annual_revenue.list = data.annual_revenue; // not remote
        state.filter.annual_revenue.options = data.annual_revenue; // not remote

        state.filter.email_status.list = data.email_status; // not remote
        state.filter.email_status.options = data.email_status; // not remote

        state.filter.company_city_id.list = data.company_city_id; // not remote
        state.filter.company_city_id.options = data.company_city_id; // not remote

        state.filter.company_country_id.list = data.company_country_id; // not remote
        state.filter.company_country_id.options = data.company_country_id; // not remote

        state.filter.company_state_region_id.list = data.company_state_region_id; // not remote
        state.filter.company_state_region_id.options = data.company_state_region_id; // not remote

        state.filter.contacts_location_state_id.list = data.contacts_location_state_id; // not remote
        state.filter.contacts_location_state_id.options = data.contacts_location_state_id; // not remote

        state.filter.contacts_location_country_id.list = data.contacts_location_country_id; // not remote
        state.filter.contacts_location_country_id.options = data.contacts_location_country_id; // not remote

        state.filter.contacts_location_city_id.list = data.contacts_location_city_id; // not remote
        state.filter.contacts_location_city_id.options = data.contacts_location_city_id; // not remote

        // state.filter.corporate_email.list = data.corporate_email; // not remote todo removed from filter to search
        // state.filter.corporate_email.options = data.corporate_email; // not remote

        // state.filter.website.list = data.website; // not remote todo removed from filter to search
        // state.filter.website.options = data.website; // not remote

        // state.filter.company.list = data.company; // not remote
        // state.filter.company.options = data.company; // not remote todo removed from filter to search



    },
    updateFilterTariffUserPanel(state, data) {
        state.filter.email.list = data.email;
        state.filter.email.options = data.email.slice(0, 300);

        state.filter.user.list = data.user;
        state.filter.user.options = data.user.slice(0, 300);

        state.filter.tariff.list = data.tariff;
        state.filter.tariff.options = data.tariff.slice(0, 300);
    },
    updateFilterTariff(state, data) {
        state.filter.email.list = data.email;
        state.filter.email.options = data.email.slice(0, 300);

        state.filter.user.list = data.user;
        state.filter.user.options = data.user.slice(0, 300);

        state.filter.status.list = data.status;
        state.filter.status.options = data.status.slice(0, 300);

        state.filter.tariff.list = data.tariff;
        state.filter.tariff.options = data.tariff.slice(0, 300);

        state.filter.amount.list = data.amount;
        state.filter.amount.options = data.amount.slice(0, 300);
    },
    updateFilterEmployeesList(state, data) {
        state.filter.value.list = data.value;
        state.filter.value.options = data.value.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);

        state.filter.min_value.list = data.min_value;
        state.filter.min_value.options = data.min_value.slice(0, 300);

        state.filter.max_value.list = data.max_value;
        state.filter.max_value.options = data.max_value.slice(0, 300);
    },
    updateFilterEcommerceSchemeList(state, data) {
        state.filter.name.list = data.name;
        state.filter.name.options = data.name.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);
    },
    updateFilterAdminTariff(state, data) {
        state.filter.tariff.list = data.tariff;
        state.filter.tariff.options = data.tariff.slice(0, 300);

        state.filter.price.list = data.price;
        state.filter.price.options = data.price.slice(0, 300);
    },
    updateFilterCompanyGeo(state, data) {
        state.filter.label.list = data.label;
        state.filter.label.options = data.label.slice(0, 300);

        state.filter.name.list = data.name;
        state.filter.name.options = data.name.slice(0, 300);

        state.filter.city.list = data.city;
        state.filter.city.options = data.city.slice(0, 300);

        state.filter.country.list = data.country;
        state.filter.country.options = data.country.slice(0, 300);

        state.filter.state_region.list = data.state_region;
        state.filter.state_region.options = data.state_region.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.rule.list = data.rule;
        state.filter.rule.options = data.rule.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);

        state.filter.type.list = data.type;
        state.filter.type.options = data.type.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);
    },
    updateFilterContactsGeo(state, data) {
        state.filter.label.list = data.label;
        state.filter.label.options = data.label.slice(0, 300);

        state.filter.name.list = data.name;
        state.filter.name.options = data.name.slice(0, 300);

        state.filter.city.list = data.city;
        state.filter.city.options = data.city.slice(0, 300);

        state.filter.country.list = data.country;
        state.filter.country.options = data.country.slice(0, 300);

        state.filter.state_region.list = data.state_region;
        state.filter.state_region.options = data.state_region.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);

        state.filter.rule.list = data.rule;
        state.filter.rule.options = data.rule.slice(0, 300);

        state.filter.type.list = data.type;
        state.filter.type.options = data.type.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);
    },
    updateFilterTitleList(state, data) {
        state.filter.name.list = data.name;
        state.filter.name.options = data.name.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);
    },
    updateFilterIndustryList(state, data) {
        state.filter.name.list = data.name;
        state.filter.name.options = data.name.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);
    },
    updateFilterTechnologiesList(state, data) {
        state.filter.name.list = data.name;
        state.filter.name.options = data.name.slice(0, 300);

        state.filter.alias.list = data.alias;
        state.filter.alias.options = data.alias.slice(0, 300);

        state.filter.count.list = data.count;
        state.filter.count.options = data.count.slice(0, 300);

        state.filter.id.list = data.id;
        state.filter.id.options = data.id.slice(0, 300);
    },
    updateCreditsLog(state, data) {
        state.filter.email.list = data.email;
        state.filter.email.options = data.email.slice(0, 300);

        state.filter.user.list = data.user;
        state.filter.user.options = data.user.slice(0, 300);

        state.filter.tariff.list = data.tariff;
        state.filter.tariff.options = data.tariff.slice(0, 300);

        state.filter.user_tariffs.list = data.user_tariffs;
        state.filter.user_tariffs.options = data.user_tariffs.slice(0, 300);

        state.filter.list_name.list = data.list_name;
        state.filter.list_name.options = data.list_name.slice(0, 300);
    },
    updateTitle(state,data){
        state.filter.title.list = data;
        state.filter.title.options = data;
    },
    updateTechnologies(state,data){
        state.filter.technologies.list = data;
        state.filter.technologies.options = data;
    },
    updateUser(state,data){
        state.filter.user.list = data;
        state.filter.user.options = data;
    },
    updateListName(state,data){
        state.filter.list_name.list = data;
        state.filter.list_name.options = data;
    },
    updateUserTariff(state,data){
        state.filter.user_tariffs.list = data;
        state.filter.user_tariffs.options = data;
    },
    updateAmount(state,data){
        state.filter.amount.list = data;
        state.filter.amount.options = data;
    },
    updatePrice(state,data){
        state.filter.price.list = data;
        state.filter.price.options = data;
    },
    updateVisible(state,data){
        state.filter.visible.list = data;
        state.filter.visible.options = data;
    },
    updateActive(state,data){
        state.filter.active.list = data;
        state.filter.active.options = data;
    },
    updateTariff(state,data){
        state.filter.tariff.list = data;
        state.filter.tariff.options = data;
    },
    updateId(state,data){
        state.filter.id.list = data;
        state.filter.id.options = data;
    },
    updateCount(state,data){
        state.filter.count.list = data;
        state.filter.count.options = data;
    },
    updateType(state,data){
        state.filter.type.list = data;
        state.filter.type.options = data;
    },
    updateStateRegion(state,data){
        state.filter.state_region.list = data;
        state.filter.state_region.options = data;
    },
    updateLabel(state,data){
        state.filter.label.list = data;
        state.filter.label.options = data;
    },
    updateCountry(state,data){
        state.filter.country.list = data;
        state.filter.country.options = data;
    },
    updateName(state,data){
        state.filter.name.list = data;
        state.filter.name.options = data;
    },
    updateAlias(state,data){
        state.filter.alias.list = data;
        state.filter.alias.options = data;
    },
    updateCity(state,data){
        state.filter.city.list = data;
        state.filter.city.options = data;
    },
    updateStatus(state,data){
        state.filter.status.list = data;
        state.filter.status.options = data;
    },
    updateCompanyGeo(state,data){
        state.filter.company_geo.list = data;
        state.filter.company_geo.options = data;
    },
    updateCompany(state,data){
        state.filter.company.list = data;
        state.filter.company.options = data;
    },
    updateContactsGeo(state,data){
        state.filter.contacts_geo.list = data;
        state.filter.contacts_geo.options = data;
    },
    updateCompanyStateRegionId(state,data){
        state.filter.company_state_region_id.list = data;
        state.filter.company_state_region_id.options = data;
    },
    updateCompanyCityId(state,data){
        state.filter.company_city_id.list = data;
        state.filter.company_city_id.options = data;
    },
    updateCompanyCountryId(state,data){
        state.filter.company_country_id.list = data;
        state.filter.company_country_id.options = data;
    },
    updateContactsStateRegionId(state,data){
        state.filter.contacts_location_state_id.list = data;
        state.filter.contacts_location_state_id.options = data;
    },
    updateContactsCityId(state,data){
        state.filter.contacts_location_city_id.list = data;
        state.filter.contacts_location_city_id.options = data;
    },
    updateContactsCountryId(state,data){
        state.filter.contacts_location_country_id.list = data;
        state.filter.contacts_location_country_id.options = data;
    },
    updateFiltersImportFails(state,data){

        state.filter.title.list = data.title;
        state.filter.title.options = data.title;

        state.filter.company_geo.list = data.company_geo;
        state.filter.company_geo.options = data.company_geo;

        state.filter.industry.list = data.industry;
        state.filter.industry.options = data.industry;

        state.filter.employees.list = data.employees; // not remote
        state.filter.employees.options = data.employees; // not remote

        state.filter.contacts_geo.list = data.contacts_geo;
        state.filter.contacts_geo.options = data.contacts_geo;

        state.filter.ecommerce_scheme.list = data.ecommerce_scheme;// not remote
        state.filter.ecommerce_scheme.options = data.ecommerce_scheme; // not remote

        state.filter.technologies.list = data.technologies;
        state.filter.technologies.options = data.technologies.slice(0, 20);

        state.filter.annual_revenue.list = data.annual_revenue; // not remote
        state.filter.annual_revenue.options = data.annual_revenue; // not remote

        state.filter.email_status.list = data.email_status; // not remote
        state.filter.email_status.options = data.email_status; // not remote

        state.filter.fail_attributes.list = data.fail_attributes;
        state.filter.fail_attributes.options = data.fail_attributes;
    },
    updateFailAttributes(state,data){
        state.filter.fail_attributes.list = data;
        state.filter.fail_attributes.options = data;
    }
};

const state = {
    filter: {
        status:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        tariff:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        list_name:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        user_tariffs:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        amount:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        price:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        visible:{
            options: ['true', 'false'],
            value: ['true', 'false'],
            list: ['true', 'false'],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        active:{
            options: ['true', 'false'],
            value: ['true', 'false'],
            list: ['true', 'false'],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        started_at:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        user:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        email:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        title:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options: ['default','strict', 'any']
            },
            checkbox2: {
                options: ['Null','NotNull']
            }
        },
        count:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['less','greater', 'equal']
            }
        },
        company_geo:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options:['Null','NotNull']
            }
        },
        company_state_region_id: {
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['name_by_id', 'search_by_id', 'invalid_name_by_id', 'empty_filter_id', 'not_empty_filter_id', 'Null', 'NotNull'],
                default: 'name_by_id'
            }
        },
        company_country_id: {
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['name_by_id', 'search_by_id', 'invalid_name_by_id', 'empty_filter_id', 'not_empty_filter_id', 'Null', 'NotNull'],
                default: 'name_by_id'
            }
        },
        company_city_id: {
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['name_by_id', 'search_by_id', 'invalid_name_by_id','empty_filter_id', 'not_empty_filter_id', 'Null', 'NotNull'],
                default: 'name_by_id'
            }
        },
        contacts_location_state_id: {
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['name_by_id', 'search_by_id', 'invalid_name_by_id', 'empty_filter_id', 'not_empty_filter_id', 'Null', 'NotNull'],
                default: 'name_by_id'
            }
        },
        contacts_location_country_id: {
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['name_by_id', 'search_by_id', 'invalid_name_by_id', 'empty_filter_id', 'not_empty_filter_id', 'Null', 'NotNull'],
                default: 'name_by_id'
            }
        },
        contacts_location_city_id: {
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['name_by_id', 'search_by_id', 'invalid_name_by_id','empty_filter_id', 'not_empty_filter_id', 'Null', 'NotNull'],
                default: 'name_by_id'
            }
        },
        rule:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['valid','in_valid']
            }
        },
        industry:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options:['Null','NotNull']
            }
        },
        employees:{
            options: [], //todo:get from filter
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options:['Null','NotNull']
            }
        },
        contacts_geo:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options:['Null','NotNull']
            }
        },
        ecommerce_scheme:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        technologies:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        annual_revenue:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },
        email_status:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        social_link:{
            options: [],
            value: [],
            list: [],
            loading: false,
        },

        reason:{
            options: ['accepted_email', 'rejected_email', 'no_mx_record','invalid_domain'],
            value: ['accepted_email', 'rejected_email', 'no_mx_record','invalid_domain'],
            list: ['accepted_email', 'rejected_email', 'no_mx_record','invalid_domain'],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        catch_all:{
            options: ['true', 'false'],
            value: ['true', 'false'],
            list: ['true', 'false'],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        corporate_email:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        website:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        label:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options: ['by_first','strict','any','Null','NotNull']
            },
        },
        type:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        name:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options: ['by_first','strict', 'any','Null','NotNull']
            },
        },
        state_region:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        alias:{
            options: [],
            value: [],
            list: [],
            checkbox: {
                options: ['by_first','strict', 'any','Null','NotNull']
            },
            loading: false,
        },
        id:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        country:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        city:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        company:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        first_name:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        last_name:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        value:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['less','greater', 'equal']
            }
        },
        min_value:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['less','greater', 'equal']
            }
        },
        max_value:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox3: {
                options: ['less','greater', 'equal']
            }
        },
        fail_attributes:{
            options: [],
            value: [],
            list: [],
            loading: false,
            checkbox:{
                options:['Null','NotNull']
            }
        },
        expired_at: {
            options: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options: ['Null', 'NotNull']
            }
        },
        created_at: {
            options: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options: ['Null', 'NotNull']
            }
        },
        updated_at: {
            options: {
                shortcuts: [{
                    text: 'Last week',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last month',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: 'Last 3 months',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value: [],
            list: [],
            loading: false,
            checkbox: {
                options: ['Null', 'NotNull']
            }
        },
    },
};

const getters = {
    filter(state) {
        return state.filter;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
