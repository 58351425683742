import store from "../index";
import toastMessage from "../toastMessage";
import axios from "axios";
import qs from "qs";
import {Form} from "vform";


const actions = {
    submit({commit, getters}){

        const vForm = getters.getForm;

        vForm.check_all = store.getters['companyList/getCheckQuery']['all'].length > 0;
        vForm.check_max = store.getters['companyList/getCheckQuery']['max'].length > 0;
        vForm.check_per_company = store.getters['companyList/getCheckQuery']['per_company'].length > 0;
        vForm.list_max_row = store.getters['companyList/getListMaxRow'];
        vForm.list_per_company = store.getters['companyList/getListPerCompanyRow'];
        vForm.type_display_contacts = store.getters['companyList/getTypeDisplayContacts'];
        vForm.query = store.getters['companyList/getFilterParamsString'];

        //fill vForm for filters, checkbox, search and other
        let query = qs.parse(qs.stringify(store.getters['companyList/getFilterParams'])); // parse and stringify replace empty array
        Object.keys(query).forEach(key => {
            vForm[key] = query[key];
        });

        commit('updateForm', vForm);

        let promise = vForm.post(process.env.VUE_APP_API_URL + 'company/check-by-filter-max');
        promise.then((response) => {
            //commit
            store.dispatch('companyList/setUpdateCountTempList', response.data.data.count_temp_list);
            store.dispatch('companyList/setUpdateIdTempList', response.data.data.id_temp_list);
            store.dispatch('companyList/setCheckedContactsIds', response.data.data.ids);
            commit('updateCheckedBulk', true);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    getCheckedIds(){
            let promise = axios.get(process.env.VUE_APP_API_URL + "company/checked");
            promise.then((response) => {
                store.dispatch('companyList/setCheckedContactsIdsOnce', response.data.data);
            }).catch((response) => {
                toastMessage.fire(response);
            });
            return promise;
    },
    setCheckedAllByTariff({getters}){

        const vForm = getters.getForm;

        vForm.check_all = true;
        vForm.check_max = false;
        vForm.check_per_company = false;
        vForm.list_max_row = store.getters['getUserTariffRow']//store.getters['people/getListMaxRow'];
        vForm.list_per_company = 1;
        vForm.type_display_contacts = store.getters['companyList/getTypeDisplayContacts'];
        vForm.query = store.getters['companyList/getFilterParamsString'];

        //fill vForm for filters, checkbox, search and other
        let query = qs.parse(qs.stringify(store.getters['companyList/getFilterParams'])); // parse and stringify replace empty array
        Object.keys(query).forEach(key => {
            vForm[key] = query[key];
        });

        // commit('updateForm', vForm); // not need its link

        let promise = vForm.post(process.env.VUE_APP_API_URL + 'company/check-by-filter-max');
        promise.then((response) => {
            //commit
            store.dispatch('companyList/setUpdateCountTempList', response.data.data.count_temp_list);
            store.dispatch('companyList/setUpdateIdTempList', response.data.data.id_temp_list);
            store.dispatch('companyList/setCheckedContactsIds', response.data.data.ids);
            // commit('updateCheckedBulk', true); // not need its link
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    setCheckedBulk({commit}, data){
        commit('updateCheckedBulk', data);
    },
    setOrderCheckedAll({commit}, data){
        commit('updateOrderCheckedAll', data);
    },
    setOrderUncheckAll(){
        let promise = axios.get(process.env.VUE_APP_API_URL + "company/checked/clear");
        promise.then(() => {

            store.dispatch('companyList/setCheckedContactsIdsClear');
            store.dispatch('companyList/setIsIndeterminate', false);
            store.dispatch('companyList/setCheckAllOnPage', false);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
};

const mutations = {
    updateForm(state, data) {
        state.form = data;
    },
    updateCheckedBulk(state, data) {
        state.checked_bulk = data;
    },
    updateOrderCheckedAll(state, data) {
        state.order_checked_all = data;
    },
};

const state = {
    form: new Form({ // for validate
        check_all: false,
        check_per_company: false,
        check_max: false,

        list_max_row: 0,
        list_per_company: 0,

        type_display_contacts: '',

        query: '',
    }),
    checked_bulk: null,
    order_checked_all: null,
};

const getters = {
    getForm(state){
        return state.form;
    },
    getCheckedBulk(state){
        return state.checked_bulk;
    },
    getOrderCheckedAll(state){
        return state.order_checked_all;
    },
};

export default  {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
