<template>
  <el-drawer
      :visible.sync="drawer.visible"
      custom-class="info-sidebar-notification"
      :with-header="false"
      size="480px">
    <div class="top">
      <p class="title">Inbox</p>
      <div class="right">
        <button class="button button--ghost small">Mark all as read</button>
        <span class="icon icon--close" @click="drawer.visible = false"></span>
      </div>
    </div>
    <div class="filter">
      <div class="filter-btn active">All <span class="count">10</span></div>
      <div class="filter-btn">System <span class="count">3</span></div>
      <div class="filter-btn">Operations</div>
      <div class="filter-btn">News <span class="count">8</span></div>
      <div class="filter-btn">Other <span class="count">118</span></div>
    </div>
    <div class="drawer-container">

<!-- !!!!!     if (notification empty) { !!!!!-->

  <!-- !!!!!     <div class="no-notification"> !!!!!-->
  <!-- !!!!!      <inline-svg :src="require('../../assets/images/illustration-no-notification.svg')"/> !!!!!-->
  <!-- !!!!!      <p>No notification yet </p> !!!!!-->
  <!-- !!!!!       <span>You don't have new notifications at the moment</span> !!!!!-->
  <!-- !!!!!     </div> !!!!!-->

<!-- !!!!!     } !!!!!-->

      <div class="notifications-by-date">
        <p class="date">Today</p>

        <div class="notification-item">
          <i class="icon-notification success"></i>
          <div class="info">
            <p class="title">Lorem ipsum dolor sit amet <span class="new">New</span></p>
            <p class="message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed aliquam placerat convallis tempor urna,
              integer sagittis arcu.</p>
            <div class="bottom">
              <div class="time">2h ago</div>
              <button class="button button--ghost small">Mark as read</button>
            </div>
          </div>
        </div>

        <div class="notification-item">
          <i class="icon-notification upload"></i>
          <div class="info">
            <p class="title">Upload successfull<span class="new">New</span></p>
            <p class="message">Upload 100 leads, validated 86 leads. Validated leads deducted from your account</p>
            <div class="bottom">
              <div class="time">2h ago</div>
              <button class="button button--ghost small">Mark as read</button>
            </div>
          </div>
        </div>

        <div class="notification-item">
          <i class="icon-notification cancel"></i>
          <div class="info">
            <p class="title">No credits<span class="new">New</span></p>
            <p class="message">You don`t have credits for this operation</p>
            <div class="bottom">
              <div class="time">2h ago</div>
              <button class="button button--ghost small">Mark as read</button>
            </div>
          </div>
        </div>

        <div class="notification-item">
          <i class="icon-notification search"></i>
          <div class="info">
            <p class="title">Search criteria saved</p>
            <p class="message">Lorem ipsum dolor sit amet, consectetur adipiscing elit. A viverra nunc, quis ac et enim, massa, sapien. Placerat.</p>
            <div class="bottom">
              <div class="time">2h ago</div>
            </div>
          </div>
        </div>

      </div>
      <div class="notifications-by-date">
        <p class="date">25 May, 2022</p>
        <div class="notification-item">
          <i class="icon-notification system"></i>
          <div class="info">
            <p class="title">System update</p>
            <p class="message">Upload 100 leads, validated 86 leads. Validated leads deducted from your account</p>
            <div class="bottom">
              <div class="time">2h ago</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>
<script>
export default {
  name: "InfoSidebarNotification",
  created() {
    this.drawer = this.drawerParent
  },
  watch: {
    drawerParent: {
      deep: true,
      handler(newDrawer) {
        this.drawer = newDrawer
      }
    }
  },
  props: {
    drawerParent: {
      type: Object
    }
  },
  data() {
    return {
      drawer: {visible: false}
    }
  },
}
</script>