import equals from "./pack/equals";
import notEquals from "./pack/notEquals";
import difference from "./pack/difference";
import pluck from "./pack/pluck";
import isSuccessResponse from "./pack/isSuccessResponse";
import formatDate from "./pack/formatDate";
import formatDateAgo from "./pack/formatDateAgo";
import formatDateShort from "./pack/formatDateShort";
import mergeAndUniq from "./pack/mergeAndUniq";
import everyIdsInArray from "./pack/everyIdsInArray";
import someIdsInArray from "./pack/someIdsInArray";

const utils = {
    equals,
    notEquals,
    difference,
    pluck,
    isSuccessResponse,
    formatDate,
    formatDateAgo,
    formatDateShort,
    mergeAndUniq,
    everyIdsInArray,
    someIdsInArray,
};

export default utils
