import axios from "axios";
import toastMessage from "../toastMessage";
import utils from "../../utils";
import {Form} from 'vform';


const actions = {
    getListName({commit}, listNameType) {
        // contacts or companies
        // ( list-name/contacts | list-name/companies)
        return axios.get(process.env.VUE_APP_API_URL + "list-name/" + listNameType ).then((response) => {
            commit('updateListName', response.data.data);
        }).catch((response) => {
            toastMessage.fire(response);
        });
    },
    createListName({commit}, vForm = new Form()) {
        let promise = vForm.post(process.env.VUE_APP_API_URL + 'list-name');
        promise.then((response) => {
            commit('prependListName', response.data.data);
            commit('updateListNameIdSelected', response.data.data.id);
            commit('updateListNameNew', '');
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    putListName({commit}, vForm = new Form()) {
        let promise = vForm.put(process.env.VUE_APP_API_URL + 'list-name/' + vForm.id);
        promise.then((response) => {
            commit('findReplaceListName', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    deleteListName({commit}, id) {
        let promise = new Promise(function (resolve, reject) {
            return axios.delete(process.env.VUE_APP_API_URL + 'list-name/' + id).then((response) => {
                if (response.data.success === true) {
                    resolve(response);
                } else {
                    reject(response);
                }
            }).catch((error) => {
                reject(error);
            });
        });
        promise.then((response) => {
            commit('findAndDeleteListName', response.data.data);
            toastMessage.fire(response);
        }).catch((response) => {
            toastMessage.fire(response);
        });
        return promise;
    },
    setListNameIdSelected({commit},data){
     commit('updateListNameIdSelected',data)
    },
    setListNameNew({commit},data){
     commit('updateListNameNew',data)
    },
    clearListNameIdSelected({commit}){
        commit('updateListNameIdSelected',null)
    },
    setControlTabsSelected({commit},data){
        commit('updateControlTabsSelected',data)
    },
    setModalModalListEdit({commit}, val) {
        commit('updateModalListNameEdit', val);
    },
    setModalModalListData({commit}, data) {
        commit('updateModalListNameData', data);
    },

};

const mutations = {
    updateListName(state, data) {
        state.listName = data;
    },
    prependListName(state, data) {
        state.listName.unshift(data);
    },
    updateListNameIdSelected(state, data) {
        state.listNameIdSelected = data;
    },
    updateListNameNew(state, data) {
        state.listNameNew = data;
    },
    updateControlTabsSelected(state, data) {
        state.controlTabsSelected = data;
    },
    updateModalListNameEdit(state, data){
        state.modalListNameEdit = data;
    },
    updateModalListNameData(state, data){
        state.modalListNameData = data;
    },
    findReplaceListName(state, data) {
        const item = state.listName.find(item => item.id === data.id);
        Object.assign(item, data);
    },
    findAndDeleteListName(state, data) {
        const index = state.listName.findIndex(item => item.id === data.id);
        state.listName.splice(index, 1);
        state.list.idsOptions = utils.pluck(state.listName, 'id')
    },
};

const state = {
    listName:[],
    listNameIdSelected: null,
    listNameNew: '',
    controlTabsSelected: 'tabs_list_name',// default
    modalListNameEdit: false,
    modalListNameData: null,
};

const getters = {
    listName(state) {
        return state.listName;
    },
    listNameIdSelected(state) {
        return state.listNameIdSelected;
    },
    listNameSelectedById(state) {

        if(state.listNameIdSelected && state.listName.length){
            let found = state.listName.find(item =>
                item.id === state.listNameIdSelected
            );
                return found ? found.name : ''
        }

        return  '';

    },
    getListNameNew(state) {
        return state.listNameNew;
    },
    getControlTabsSelected(state){
        return state.controlTabsSelected;
    },
    getModalListNameEdit(state) {
        return state.modalListNameEdit;
    },
    getModalListNameData(state) {
        return state.modalListNameData;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
}
