<template>
  <span>
    <slot/>
  </span>
</template>

<script>

export default {

};
</script>

<style src="@/scss/auth/index.scss" lang="scss"></style>
<style src="@/assets/css/global.scss" lang="scss"></style>